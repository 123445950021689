import { ListigList } from "@/types/listig"
import { hasOrder, isActive } from "./listig"
import { ExpiredListError } from "./errors"

type List = {
  id?: string
  listQuery: {
    list?: ListigList
    error: ExpiredListError | Error | null
    isLoading: boolean
    refetch: () => void
  }
}

export const getActiveList = (
  listFromUrl: List,
  listFromLocalStorage: List,
) => {
  /**
   * If any of the lists are loading we return no list.
   */
  if (
    (listFromUrl.id && listFromUrl.listQuery.isLoading) ||
    (listFromLocalStorage.id && listFromLocalStorage.listQuery.isLoading)
  ) {
    return {
      error: null,
      isLoading: true,
      list: undefined,
      refetch: () => {
        listFromUrl.listQuery.refetch()
        listFromLocalStorage.listQuery.refetch()
      },
    }
  }

  /**
   * If the list from the url has an order, we return that list.
   */
  if (hasOrder(listFromUrl.listQuery.list)) {
    return listFromUrl.listQuery
  }

  /**
   * If there is an active list in local storage in the same store
   * as the list from the url we return the list from local storage.
   */
  if (
    isActive(listFromLocalStorage.listQuery.list) &&
    (!isActive(listFromUrl.listQuery.list) ||
      listFromLocalStorage.listQuery.list.businessUnit.code ===
        listFromUrl.listQuery.list.businessUnit.code)
  ) {
    return listFromLocalStorage.listQuery
  }

  /**
   * If there is a list id in the url we return the list from the url.
   */
  if (listFromUrl.id) {
    return listFromUrl.listQuery
  }

  /**
   * As a fallback we return no list, this will happen for example
   * when we come from a url that will create a new list.
   */
  return {
    error: null,
    isLoading: false,
    list: undefined,
    refetch: () => {
      listFromUrl.listQuery.refetch()
      listFromLocalStorage.listQuery.refetch()
    },
  }
}
