import { useProduct } from "@/hooks/useProducts"
import { PipProduct } from "@/types/product/pip"
import { VariantCategory } from "@/types/responses/find"
import { getSortedVariants } from "@/utils/sortVariants"
import classNames from "classnames"

interface VisualVariantsProps {
  variantCategory: VariantCategory | undefined
  currentProduct: PipProduct
  handleVariantOnClick: (productNo: string) => void
  onMoreClick: () => void
}

export const VisualVariants = ({
  variantCategory,
  currentProduct,
  handleVariantOnClick,
  onMoreClick,
}: VisualVariantsProps) => {
  const nbrVariantsToShow = 3

  const sortedVariants = getSortedVariants(
    variantCategory,
    currentProduct.info.no,
  )

  const productImages: Record<string, string> = {
    [sortedVariants?.[0]?.productNo || ""]:
      useProduct(sortedVariants?.[0]?.productNo, currentProduct.info.type)
        .productInfo?.media.mainImage?.href || "",
    [sortedVariants?.[1]?.productNo || ""]:
      useProduct(sortedVariants?.[1]?.productNo, currentProduct.info.type)
        .productInfo?.media.mainImage?.href || "",
    [sortedVariants?.[2]?.productNo || ""]:
      useProduct(sortedVariants?.[2]?.productNo, currentProduct.info.type)
        .productInfo?.media.mainImage?.href || "",
  }

  return (
    variantCategory && (
      <div className="flex flex-col justify-between py-2 px-4 mx-4">
        <p className="capitalize text-xs">{variantCategory.typeName}</p>

        <div className="flex w-full flex-row pt-3">
          {sortedVariants &&
            sortedVariants.slice(0, nbrVariantsToShow).map((product) => (
              <div
                key={product?.productNo}
                onClick={() => handleVariantOnClick(product?.productNo ?? "")}
                className={classNames(
                  "mr-6 flex  h-[72px] w-[72px] items-center justify-center border  p-3",
                  {
                    "border-black border-2":
                      currentProduct.info.no === product?.productNo,
                  },
                  {
                    "border-gray-300":
                      currentProduct.info.no !== product?.productNo,
                  },
                )}
              >
                {product?.productNo && productImages[product.productNo] && (
                  <img
                    className="object-contain h-11 w-11"
                    src={productImages[product.productNo]}
                  />
                )}
              </div>
            ))}

          {variantCategory.variants.length > nbrVariantsToShow && (
            <div
              onClick={onMoreClick}
              className="flex h-[72px] w-[72px] items-center justify-center border border-gray-300 p-5 pt-6"
            >
              +{variantCategory.variants.length - nbrVariantsToShow}
            </div>
          )}
        </div>
      </div>
    )
  )
}
