/* eslint-disable filename-rules/match */
import { OPTIMIZELY_DISABLED, RUN_OPTIMIZELY } from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import type { ReactSDKClient } from "@optimizely/react-sdk"
import { createInstance, setLogLevel } from "@optimizely/react-sdk"
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react"

const TEN_MINUTES = 600000

interface OptimizelyContextType {
  optimizely: ReactSDKClient | undefined
}

const OptimizelyContext = createContext<OptimizelyContextType>({
  optimizely: undefined,
})

/**
 * Initialize optimizely context
 */
export const OptimizelyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { market } = useLocale()
  const optimizely = useMemo(() => initOptimizely(market), [market])

  useEffect(() => {
    if (optimizely) {
      setLogLevel("error")
    }
  }, [optimizely])

  return (
    <OptimizelyContext.Provider value={{ optimizely }}>
      {children}
    </OptimizelyContext.Provider>
  )
}

export const useOptimizely = () => useContext(OptimizelyContext)

function initOptimizely(
  market: string | undefined,
): ReactSDKClient | undefined {
  if (!RUN_OPTIMIZELY) return

  if (OPTIMIZELY_DISABLED.includes(market?.toUpperCase() || "")) return

  const optimizelyClient = createInstance({
    sdkKey: process.env.OPTIMIZELY_SDK_KEY,
    datafileOptions: {
      autoUpdate: true,
      updateInterval: TEN_MINUTES,
    },
    eventBatchSize: 10,
    eventFlushInterval: 1000,
  })
  optimizelyClient.setUser({
    id: generateOptimizelyUserID(),
    attributes: {},
  })

  return optimizelyClient
}

function generateOptimizelyUserID() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0]
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return uint32!.toString(16)
}
