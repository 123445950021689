export const isToday = (dateString?: string): boolean => {
  if (!dateString) return false

  const date = dateWithoutTime(dateString)
  const today = dateWithoutTime()

  return date === today
}

export const dateWithoutTime = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date()

  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
}
