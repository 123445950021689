import { Fee } from "@/types/responses/find"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

type Props = {
  fee: Fee
  locale: string
  onClick?(): void
  styling?: string
}

export function FeeText({ fee, locale, onClick, styling }: Props) {
  const { t } = useTranslation()
  const feeMarkup = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: fee.currencyCode,
  }).format(fee.amount)

  return (
    <p
      data-component="product-fee-text"
      className={classNames(styling, "text-sm", {
        underline: !!onClick,
      })}
      onClick={onClick}
    >
      {fee.type.toLowerCase() === "eco"
        ? t("ProductFeeModal-ECO-title") + ` ${feeMarkup}`
        : t("ProductFeeModal-WEEE-title") + ` ${feeMarkup}`}
    </p>
  )
}
