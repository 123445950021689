import { useLocale } from "@/hooks/useLocale"
import { default as PriceModule, ListPriceModule } from "@ingka/price-module"
import { Price } from "../common/Price"
import {
  getPriceProps,
  getRegularHigherPrice,
  getLowestPreviousPrice,
  getUnitPrice,
  isTimeDiscountedPrice,
} from "@/utils/price"
import { BaseImage, Offers, Prices, UnitPrice } from "@/types/responses/find"
import { useTranslation } from "react-i18next"
import { getLocale, getSuffix } from "@/utils/locale"
import { PriceValidUntil } from "../PriceValidUntil"

interface ProductPriceProps {
  productName: string
  productDescription?: string
  prices: Prices
  offers?: Partial<Offers>
  size?: "small" | "medium" | "large"
  childItem?: boolean
  className?: string
  inlinePrice?: boolean
  /** multiplier of the provided unit prices */
  quantity?: number
  list?: boolean
  productEnergy?: { energyLabel: BaseImage; onEnergyLabelClick: () => void }
  waterSense?: { waterSense: BaseImage; onWaterSenseLabelClick: () => void }
  unifiedWater?: { unifiedWater: BaseImage; onWaterSenseLabelClick: () => void }
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
  productName,
  productDescription,
  prices,
  offers,
  size = "medium",
  childItem,
  className,
  inlinePrice = false,
  quantity = 1,
  list = false,
  productEnergy,
  waterSense,
  unifiedWater,
}) => {
  const { market, language } = useLocale()
  const { t } = useTranslation()
  const locale = getLocale(market, language)
  const unitPrice = getUnitPrice(market, prices.family ?? prices.regular)
  const suffix = list ? undefined : getSuffix(market, prices.suffix)

  const currentPriceProps = getPriceProps(
    locale,
    prices.family?.inclTax ?? prices.regular.inclTax,
    prices.currency,
    quantity,
    suffix,
    size,
    className,
  )
  const secondaryCurrencyPriceProps =
    prices.secondRegular?.inclTax && prices.secondCurrency
      ? getPriceProps(
          locale,
          prices.secondFamily?.inclTax ?? prices.secondRegular?.inclTax,
          prices.secondCurrency,
          quantity,
          suffix,
          size,
          className,
        )
      : undefined

  const commercialMessage: string = (() => {
    switch (offers?.offerType) {
      case "family":
        return t("ProductIkeaFamilyPriceBadge-badge")
      case "nlp":
        return t("ProductLowerPriceBadge-badge")
      case "new":
        return t("ProductNewBadge-badge")
      default:
        return ""
    }
  })()

  /** Regular price if current price is discounted */
  const regularHigherPrice = getRegularHigherPrice(
    offers?.offerType === "family",
    prices.regular,
  )
  const regularHigherSecondPrice =
    prices.secondRegular &&
    getRegularHigherPrice(offers?.offerType === "family", prices.secondRegular)

  const comparisonPriceProps = regularHigherPrice
    ? getPriceProps(
        locale,
        regularHigherPrice,
        prices.currency,
        quantity,
        suffix,
        size,
        className,
      )
    : undefined
  const secondaryCurrencyComparisonPriceProps =
    regularHigherSecondPrice && prices.secondCurrency
      ? getPriceProps(
          locale,
          regularHigherSecondPrice,
          prices.secondCurrency,
          quantity,
          suffix,
          size,
        )
      : undefined

  const isFamilyRomania =
    offers?.offerType === "family" && market.toUpperCase() === "RO"

  const priceAddons = usePriceAddons(
    prices,
    quantity,
    list,
    unitPrice,
    isFamilyRomania,
    regularHigherPrice,
    regularHigherSecondPrice,
    offers,
  ).filter((priceAddon) => priceAddon)

  const IngkaPrice = list ? ListPriceModule : PriceModule

  return (
    currentPriceProps && (
      <IngkaPrice
        productNameHeadingTag="h6"
        priceOfferType={isFamilyRomania ? "family-romania" : offers?.offerType}
        comparisonPriceLabel={t("ShoppingListTotals-regular-price")}
        commercialMessageProps={{
          message: commercialMessage,
        }}
        productName={productName}
        data-cy="product-description"
        productDescription={productDescription}
        currentPriceProps={currentPriceProps}
        secondaryCurrencyPriceProps={secondaryCurrencyPriceProps}
        comparisonPriceProps={comparisonPriceProps}
        secondaryCurrencyComparisonPriceProps={
          secondaryCurrencyComparisonPriceProps
        }
        className={`${offers?.isBreathTakingItem && !list ? "bti" : ""} ${inlinePrice ? "inline-price" : ""}`}
        style={{
          overflowWrap: "anywhere",
          wordBreak: "normal",
          hyphens: "auto",
        }}
        size={childItem ? "small" : "medium"}
        priceAddons={
          childItem || priceAddons.length === 0 ? undefined : priceAddons
        }
        energyClass={
          list ? undefined : (
            <>
              {waterSense && (
                <img
                  data-cy="water-label-pip"
                  src={waterSense.waterSense.href}
                  onClick={() => waterSense.onWaterSenseLabelClick()}
                />
              )}
              {productEnergy && (
                <img
                  data-cy="energy-label-pip"
                  src={productEnergy.energyLabel.href}
                  onClick={() => productEnergy.onEnergyLabelClick()}
                />
              )}
              {unifiedWater && (
                <img
                  data-cy="unified-water-label-pip"
                  src={unifiedWater.unifiedWater.href}
                  onClick={() => unifiedWater.onWaterSenseLabelClick()}
                />
              )}
            </>
          )
        }
      />
    )
  )
}

const usePriceAddons = (
  prices: Prices,
  quantity: number,
  list: boolean,
  unitPrice: UnitPrice[keyof UnitPrice],
  isFamilyRomania: boolean,
  regularHigherPrice?: number,
  regularHigherSecondPrice?: number,
  offers?: Partial<Offers>,
) => {
  const { market } = useLocale()
  const { t } = useTranslation()

  const secondaryUnitPrice = getUnitPrice(
    market,
    prices.secondFamily ?? prices.secondRegular,
  )

  /** Lowest price for last 30 days if current price is discounted */
  const lowestPreviousPrice = getLowestPreviousPrice(
    prices.regular,
    prices.family,
  )
  const lowestPreviousSecondPrice =
    prices.secondRegular &&
    getLowestPreviousPrice(prices.secondRegular, prices.secondFamily)

  const priceWithSuffix =
    unitPrice?.unit ?? list ? getSuffix(market, prices.suffix) : undefined

  const price = prices.family ?? prices.regular

  return [
    !list && lowestPreviousPrice && (
      <div key="lowest-price" className="text-xs">
        {`${t("price.lowest-30-day-price")} `}
        <Price
          currency={prices.currency}
          price={lowestPreviousPrice}
          size="small"
          className="text-xs text-gray-700"
          secondPrice={lowestPreviousSecondPrice}
          secondCurrency={prices.secondCurrency}
          quantity={quantity}
          inlinePrice={true}
        />
      </div>
    ),
    !isFamilyRomania && offers?.offerType !== "tro" && regularHigherPrice && (
      <div key="regular-price" className="text-xs mb-2">
        {`${
          offers?.offerType === "family"
            ? t("ShoppingListTotals-regular-price")
            : t("ShoppingListTotals-previous-price")
        }: `}
        <Price
          currency={prices.currency}
          price={regularHigherPrice}
          size="small"
          className="text-xs text-gray-700"
          secondPrice={regularHigherSecondPrice}
          secondCurrency={prices.secondCurrency}
          quantity={quantity}
          inlinePrice={true}
        />
      </div>
    ),
    regularHigherPrice && isTimeDiscountedPrice(price) && (
      <PriceValidUntil
        key="valid-until"
        price={price}
        showToDate={!offers?.isDiscontinued}
      />
    ),
    priceWithSuffix && (
      <Price
        key="unit-price"
        currency={prices.currency}
        price={unitPrice?.price ?? prices.regular.inclTax}
        size="small"
        className="text-xs text-gray-700"
        secondPrice={secondaryUnitPrice?.price}
        secondCurrency={prices.secondCurrency}
        inlinePrice={true}
        suffix={priceWithSuffix}
      />
    ),
    offers?.isLastChance && (
      <span key="last-chance" className="text-gray-700 font-bold text-xs">
        {t("common.last-chance")}
      </span>
    ),
  ]
}
