import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

interface OrderCompletedProps {
  orderNumber?: string
  orderCreationDate?: string
  className?: string
}

const OrderCompleted: React.FC<OrderCompletedProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        "flex w-full flex-col justify-between text-gray-900",
        {
          [props.className || ""]: props.className,
        },
      )}
    >
      {props.orderNumber && (
        <p className="text-sm">
          {t("Interstitial-BarcodeModal-code-label") + " " + props.orderNumber}
        </p>
      )}
      {props.orderCreationDate && (
        <p className="text-sm">
          {t("common.collected") + ", " + props.orderCreationDate.split("T")[0]}
        </p>
      )}
    </div>
  )
}

export default OrderCompleted
