import { PipProduct } from "@/types/product/pip"
import { isUArticle } from "@/types/product/products"
import { UArticle, UChildProduct } from "@/types/responses/find"

export function isFullServeArticle(product: UArticle | UChildProduct) {
  return (
    "locations" in product &&
    product.locations?.keys() &&
    product.locations[0]?.salesMethod === "FULL_SERVE"
  )
}

export function isFullServeProduct(product: PipProduct) {
  const isArt = isUArticle(product)
  const isFullServeArt = isArt && isFullServeArticle(product)

  if (isArt) return isFullServeArt

  return product.info.childItems?.some((item) =>
    isFullServeArticle(item as UArticle),
  )
}
