import { sendKepsEvent } from "../keps"
import { getSalesMethod, typedLowerCaseAvailability } from "../eventUtils"
import { PipProduct } from "@/types/product/pip"

export function sendPipAddItemEvent(product: PipProduct) {
  sendKepsEvent({
    event_location: "pip",
    event_name: "add_item",
    event_items: [
      {
        item_no: product.info.no,
        item_type: product.info.type,
        item_stock: typedLowerCaseAvailability(
          product.stock.cashCarry.probability?.thisDay,
        ),
        item_price:
          product.price.family?.inclTax ?? product.price.regular.inclTax,
        item_currency: product.price.currency,
        item_sales_method: getSalesMethod(product),
      },
    ],
  })
}
