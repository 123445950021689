import {
  WAITING_TIME_ENABLED,
  WAITING_TIME_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useSession } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"
// import { useAdminSettings } from "./useAdminSettings"

/**
 * @returns true if waiting time has been enabled for market/store/kiosk
 */
export function useWaitingTime() {
  const { market } = useLocale()
  const { session } = useSession()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  // const { settings } = useAdminSettings()

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: WAITING_TIME_ENABLED,
    ENABLED_LOCATIONS: WAITING_TIME_LOCATIONS,
  })
  //|| settings?.fullServe.showEstimatedWaitingTime
}
