type Thumbnails = {
  images: string[]
  hiddenCount: number
}

export function getThumbnails(
  input: string[],
  thumbnailCount: number,
): Thumbnails {
  const images =
    input.length > thumbnailCount ? input.slice(0, thumbnailCount - 1) : input
  const hiddenCount = input.length - images.length

  return { images, hiddenCount }
}
