import { NoServiceError, UError } from "@/utils/errors"
import { captureException } from "@/utils/sentry"
import { ErrorView } from "@/views/ErrorView"
import React, { ReactElement, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

/**
 * Parse route parameters.
 */
export const RouterParamsWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  return (
    <Routes>
      <Route path="/:market/:language/*" element={children} />
      <Route path="*" element={<NoLocaleView />} />
    </Routes>
  )
}

/**
 * Show error view and report to Sentry
 */
const NoLocaleView: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    // report error if we have path or search params
    if (location.pathname !== "/" || !["", "?"].includes(location.search)) {
      captureException(
        new UError("INVALID_HANDOVER", JSON.stringify(location)),
        "warning",
      )
    }
  }, [location])

  return <ErrorView error={new NoServiceError("INCOMPLETE_HANDOVER")} />
}
