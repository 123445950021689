import { GetSettingsResponse } from "@/types/responses/admin"
import { fetchWithErrorHandling } from "@/utils/fetch"

export const getSettings = (
  market: string,
  store: string,
  oAuthToken: string
): Promise<GetSettingsResponse> =>
  fetchWithErrorHandling(
    "ADMIN_SETTINGS",
    `${process.env.ADMIN_API_URL}/settings/${market}/${store}`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
      },
    }
  )
