import { BYPASS_ONETRUST_MARKETS } from "@/config/constants"

export function getOneTrustConsents(market: string): CookieGroups {
  if (BYPASS_ONETRUST_MARKETS.includes(market))
    return {
      1: { hasConsent: true },
      2: { hasConsent: true },
      3: { hasConsent: true },
      4: { hasConsent: true },
    }

  const activeGroups = window.OptanonActiveGroups?.split(",")
    .map((v) => parseInt(v))
    .filter((v) => !!v)

  return {
    1: { hasConsent: !!activeGroups?.includes(1) },
    2: { hasConsent: !!activeGroups?.includes(2) },
    3: { hasConsent: !!activeGroups?.includes(3) },
    4: { hasConsent: !!activeGroups?.includes(4) },
  }
}

export type CookieGroups = {
  1: { hasConsent: boolean }
  2: { hasConsent: boolean }
  3: { hasConsent: boolean }
  4: { hasConsent: boolean }
}
