import { dateWithoutTime } from "./isToday"

type ActiveList = {
  listId: string
  time: number
}

const activeListKey = "active_list"

const mergedListsKey = "merged_lists"

/**
 * Save the current active list in local storage.
 */
export function setActiveList(listId: string, createdAt?: string) {
  const activeList = getLocalStorageActiveList()
  if (activeList.listId === listId) return

  localStorage.setItem(mergedListsKey, JSON.stringify([]))

  localStorage.setItem(
    activeListKey,
    JSON.stringify({ listId: listId, time: dateWithoutTime(createdAt) }),
  )
}

/**
 * Fetch the current active list from local storage if the list is created today.
 */
export function getActiveList(): string | undefined {
  const activeList = getLocalStorageActiveList()
  const createdToday = activeList.time === dateWithoutTime()

  return createdToday ? activeList.listId : undefined
}

/**
 * Save the merged list in local storage.
 */
export function setMergedList(listId: string) {
  const mergedLists = getLocalStorageMergedLists()

  if (mergedLists.includes(listId)) return

  mergedLists.push(listId)

  localStorage.setItem(mergedListsKey, JSON.stringify(mergedLists))
}

/**
 * Checks if the list has already been merged.
 */
export function isListMerged(listId: string): boolean {
  const mergedLists = getLocalStorageMergedLists()

  return mergedLists ? !!mergedLists.includes(listId) : false
}

/**
 * Check if list with listId exists in local storage and is NOT created today.
 */
export function isExpired(listId: string): boolean {
  const activeList = getLocalStorageActiveList()
  const notCreatedToday = activeList.time !== dateWithoutTime()

  return activeList.listId === listId && notCreatedToday
}

const getLocalStorageActiveList = (): ActiveList => {
  const stringData = localStorage.getItem(activeListKey)
  return (stringData && JSON.parse(stringData)) || {}
}

const getLocalStorageMergedLists = (): string[] => {
  const mergedLists = JSON.parse(localStorage.getItem(mergedListsKey) || "[]")

  return mergedLists ?? []
}
