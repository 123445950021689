import SSRIcon from "@ingka/ssr-icon"
import { useTranslation } from "react-i18next"
import documentPencil from "@ingka/ssr-icon/paths/document-pencil"
import { SCANNER_ENABLED } from "@/config/constants"

export const EmptyListSection: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-4 px-10 w-full h-full border-t border-gray-200 bg-white text-center items-center justify-center ">
      <div>
        <SSRIcon paths={documentPencil} />
      </div>
      <div data-cy="empty-shopping-list" className="font-bold">
        {t("ShoppingList-empty-shopping-list")}
      </div>
      {SCANNER_ENABLED && (
        <div>{t("ShoppingList-empty-shopping-list-add-instructions")}</div>
      )}
    </div>
  )
}
