import { PipProduct } from "@/types/product/pip"

interface Props {
  product: PipProduct
}

export function WaterLabel({ product }: Props) {
  return (
    <div className="flex justify-center overflow-y-scroll pb-4">
      <img
        data-cy="water-label-modal"
        className="water-image my-6 w-80 rounded border border-solid border-gray-300 p-4"
        alt="Water label"
        src={
          product.media.images.find((i) => i.type === "WATER_SENSE_LABEL_IMAGE")
            ?.href ??
          product.media.images.find(
            (i) => i.type === "UNIFIED_WATER_LABEL_IMAGE",
          )?.href
        }
      />
    </div>
  )
}
