import { isEnvironment } from "./constants"

const ONETRUST_MARKET_IDS: Record<string, string> = {
  at: "7672e310-b3e7-4e2d-ae52-003d557e0cd2",
  au: "08ee39b9-0609-43eb-a958-fdf7314eb14f",
  be: "c2bf8d18-d775-4682-9eb9-44e5341b2d97",
  ca: "c52e9961-1de3-4708-8517-eb785a4e1b27",
  ch: "ed551f8a-434f-4745-bb77-16e19681169a",
  cz: "abf1ff0b-dc49-425e-af76-2a29b77914f3",
  de: "4731431f-ad11-400e-83cb-f75a8d8a8c9a",
  dk: "1926f1b6-845d-42b0-a96d-96bdf8e9b13a",
  es: "99576f59-2ee5-49af-80db-5b6feacdbdb9",
  fi: "a0fd889b-6a3e-4806-ac17-87f6b7d0ac7f",
  fr: "02db3a68-43b6-4444-8b13-736d13835f7b",
  gb: "c8330046-67da-4a38-915a-4cb1278988fc",
  hr: "7b2418df-27f3-44d6-9427-60a86fbf8e14",
  hu: "92949074-ad03-44db-a986-3ae62db9bce4",
  ie: "ead5a915-6c43-4ba7-8a8e-87c371c83061",
  in: "7f08cb3d-ab03-48c2-b4e9-5547de58517e",
  it: "9ecabbda-1e77-45cf-a836-fb72744da6c7",
  jp: "854dcfd3-c7e0-4de1-aa47-c72d7b3c8c59",
  kr: "ae3de9c0-7282-4801-a375-54c7ed68a59e",
  nl: "f903cec0-39e1-42c3-a0f1-8e0d6670f77a",
  no: "33db76fd-87d3-46d4-ba66-9163e8c63284",
  pl: "81d7fa53-126c-4150-9762-1868a885e1bc",
  pt: "a8ed894a-46ae-400a-8650-4eb198c8239a",
  ro: "c83020c1-7013-45c8-bcce-3ae8fda2f90d",
  rs: "de257346-50eb-4a50-ab87-eb262471f35c",
  si: "36a6ae10-bc6f-4f41-a46a-dfbe9f87bb3c",
  sk: "a6915520-ba84-4d9a-afd7-634a4ebc4d29",
  se: "c8dbc7c9-ff64-4cc6-bce7-dd9cecc3deed",
  us: "be51ffee-3efa-4f82-bb5b-2778c7846776",
}

export function getOneTrustMarketId(market: string) {
  const oneTrustMarketId = ONETRUST_MARKET_IDS[market.toLowerCase()]
  if (!oneTrustMarketId) return

  return `${oneTrustMarketId}${isEnvironment("PROD") ? "" : "-test"}`
}
