import { useLocale } from "@/hooks/useLocale"
import { getLocale } from "@/utils/locale"
import { getPriceProps } from "@/utils/price"
import IngkaPrice from "@ingka/price"

interface PriceProps {
  price: number
  currency: string
  size?: "small" | "medium" | "large"
  className?: string
  secondPrice?: number
  secondCurrency?: string
  secondClassName?: string
  inlinePrice?: boolean
  /** multiplier of the provided unit prices */
  quantity?: number
  suffix?: string
}

export const Price: React.FC<PriceProps> = ({
  price,
  currency,
  size = "medium",
  className,
  secondPrice,
  secondCurrency,
  secondClassName,
  inlinePrice = false,
  quantity = 1,
  suffix,
}) => {
  const { market, language } = useLocale()

  const locale = getLocale(market, language)

  const priceProps = getPriceProps(locale, price, currency, quantity, suffix)
  const secondaryPriceProps =
    secondCurrency && secondPrice
      ? getPriceProps(locale, secondPrice, secondCurrency, quantity, suffix)
      : undefined

  return (
    <span className={inlinePrice ? "" : "flex flex-col items-end"}>
      {priceProps && (
        <IngkaPrice {...priceProps} className={className} size={size} />
      )}
      {secondaryPriceProps && (
        <>
          {inlinePrice && " "}
          <IngkaPrice
            {...secondaryPriceProps}
            className={secondClassName ?? className}
            size={size}
          />
        </>
      )}
    </span>
  )
}
