import {
  Price,
  FamilyPrice,
  UnitPrice,
  TimeDiscountedPrice,
} from "@/types/responses/find"
import { isDiscountedPrice } from "@/types/product/listProduct"
import { getCurrencyFormat, measureMap } from "@/config/locales"
import { UniqueKey } from "@/types/uniqueKey"

export const getPriceProps = (
  locale: string,
  price: number,
  currency: string,
  quantity: number,
  suffix?: string,
  size?: "small" | "medium" | "large",
  className?: string,
) => {
  const subscriptLabel = suffix ? " / " + suffix : ""

  if (!locale || !currency) {
    return undefined
  }

  const currencyFormat = getCurrencyFormat({
    locale,
    currency,
    price: price * quantity,
  })

  return currencyFormat
    ? {
        ...currencyFormat,
        caption: "price",
        className,
        size,
        subscriptLabel,
      }
    : undefined
}

/** Returns the regular price if the current price is discounted */
export const getRegularHigherPrice = (
  isFamilyPrice: boolean,
  regularPrice: Price,
) => {
  if (isFamilyPrice) {
    return regularPrice.inclTax
  } else if (isDiscountedPrice(regularPrice)) {
    return regularPrice.previousPriceInclTax
  }
}

/** Returns the lowest price for the last 30 days if the current price is discounted */
export const getLowestPreviousPrice = (
  regularPrice: Price,
  familyPrice?: FamilyPrice,
) => {
  if (familyPrice) {
    return familyPrice.lowestPreviousSalesPrice?.priceInclTax
  } else if (isDiscountedPrice(regularPrice)) {
    return regularPrice.lowestPreviousSalesPrice?.priceInclTax
  }
}

/**
 * Returns the unit price (e.g. €/kg) of the main price.
 * If the product has a discount, the unit price is based on
 * the discounted price.
 */
export const getUnitPrice = (
  market: string,
  price?: Price,
): UnitPrice[keyof UnitPrice] | undefined => {
  if (!price?.unitPrice) return

  const unit = measureMap[market.toLowerCase()]?.unit ?? "metric"
  const unitPrice = price.unitPrice[unit]

  return unitPrice
}

export const isTimeDiscountedPrice = (
  price?: Price,
): price is TimeDiscountedPrice => {
  if (!price) return false

  const validFrom: UniqueKey<TimeDiscountedPrice, Price> = "validFrom"
  const validTo: UniqueKey<TimeDiscountedPrice, Price> = "validTo"

  return validFrom in price && validTo in price
}
