/* eslint filename-rules/match: 0 */
import {
  AnalyticsWrapper,
  AppRouter,
  NoServiceErrorWrapper,
  LoadingWrapper,
  LocalizationWrapper,
  OneTrustWrapper,
  RouterParamsWrapper,
  SentryWrapper,
  SessionRouter,
} from "@/components/wrappers"
import { OptimizelyProvider } from "@/hooks/useOptimizely"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./styles/index.scss"
import { NotificationContextProvider } from "./contexts/NotificationContext"

const queryClient = new QueryClient()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <SentryWrapper>
      <BrowserRouter>
        <RouterParamsWrapper>
          <OneTrustWrapper>
            <QueryClientProvider client={queryClient}>
              <LocalizationWrapper>
                <SessionRouter>
                  <NoServiceErrorWrapper>
                    <OptimizelyProvider>
                      <AnalyticsWrapper>
                        <LoadingWrapper>
                          <NotificationContextProvider>
                            <AppRouter />
                          </NotificationContextProvider>
                        </LoadingWrapper>
                      </AnalyticsWrapper>
                    </OptimizelyProvider>
                  </NoServiceErrorWrapper>
                </SessionRouter>
              </LocalizationWrapper>
            </QueryClientProvider>
          </OneTrustWrapper>
        </RouterParamsWrapper>
      </BrowserRouter>
    </SentryWrapper>
  </React.StrictMode>,
)
