import { ONLINE_STORE } from "@/config/constants"
import { useStoreInformation } from "./useStoreInformation"
import { AddonStore, Store } from "@/types/responses/explore"

const ONLINE_STORE_TYPES = [
  "ikea-plan-and-order-point",
  "ikea-pick-up-point",
  "ikea-home-service-point",
  "ikea-pop-up",
]

const treatAsStore = (storeInfo: Store | AddonStore): boolean =>
  !!("treatAsStore" in storeInfo && storeInfo.treatAsStore)

/**
 * @returns true if online mode
 */
export function useIsOnline(buCode: string | null): {
  isOnline?: boolean
  isLoading: boolean
} {
  const { storeInfo } = useStoreInformation(buCode)

  if (ONLINE_STORE) return { isOnline: true, isLoading: false }
  if (!storeInfo) return { isLoading: true }

  return {
    isOnline:
      ("parentStore" in storeInfo && !!storeInfo.parentStore) ||
      (ONLINE_STORE_TYPES.includes(storeInfo.type) && !treatAsStore(storeInfo)),
    isLoading: false,
  }
}
