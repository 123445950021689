import classNames from "classnames"
import { FullServeStatus } from "./FullServeStatusText"

interface FullServeStatusTextProps {
  status?: FullServeStatus
}

const ProgressBar: React.FC<FullServeStatusTextProps> = ({ status }) => {
  return (
    <div className="flex gap-3 my-4">
      <div
        className={classNames(`h-1.5 w-1/3 rounded-md`, {
          "bg-gray-300": status === undefined,
          "bg-blue-brand":
            status === "WAITING_FOR_PAYMENT" || status === "PREPARING_ORDER",
          "bg-semantic-positive": status === "READY_FOR_PICKUP",
        })}
      />
      <div
        className={classNames(`h-1.5 w-1/3 rounded-md`, {
          "bg-gray-300":
            status === "WAITING_FOR_PAYMENT" || status === undefined,
          "bg-blue-brand": status === "PREPARING_ORDER",
          "bg-semantic-positive": status === "READY_FOR_PICKUP",
        })}
      />
      <div
        className={classNames(`h-1.5 w-1/3 rounded-md bg-gray-300`, {
          "bg-gray-300": status === undefined,
          "bg-semantic-positive": status === "READY_FOR_PICKUP",
        })}
      />
    </div>
  )
}

export default ProgressBar
