import { sendKepsEvent } from "../keps"

export function sendPipExploreImagesEvent(productId: string | undefined) {
  sendKepsEvent({
    event_location: "pip",
    event_name: "explore_images",
    event_items: [{ item_no: productId }],
    event_payload: JSON.stringify({ event_trigger_type: "swipe" }),
  })
}
