import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import useVisit from "@/hooks/useVisit"
import SSRIcon from "@ingka/ssr-icon"
import cross from "@ingka/ssr-icon/paths/cross"
import scanQrCode from "@ingka/ssr-icon/paths/scan-qr-code"
import ProductIdentifier from "@ingka/product-identifier"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { sendQuestionmarkCloseEvent } from "@/analytics/events/sendQuestionmarkCloseEvent"

export const ScannerInstructions: React.FC = () => {
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { setVisitedPage } = useVisit()

  const onClose = () => {
    sendQuestionmarkCloseEvent()
    setVisitedPage("add")
    navigate({ path: "add-product" })
  }

  return (
    <div
      className="relative flex flex-col py-8 px-10 bg-white overflow-hidden justify-between"
      style={{ minHeight: "100dvh", width: "100dvw" }}
    >
      <div>
        <div className="flex w-full justify-end">
          <SSRIcon paths={cross} onClick={onClose} />
        </div>

        <h3 className="pt-4 pb-2 text-2xl">{t("mobile.add-products")}</h3>
        <p className="text-sm">{t("mobile.onboarding-scanner-body")}</p>

        <div className="bg-gray-100 my-6 rounded-lg">
          <div className="py-8 text-center">
            <SSRIcon paths={scanQrCode} className=" h-12 w-12" />
          </div>
          <div className="p-4 pt-0">
            <h5 className="text-base">
              {t("mobile.onboarding-scanner-qr-codes")}
            </h5>
            <p className=" text-sm pt-1">
              {t("mobile.onboarding-scanner-qr-codes-body")}
            </p>
          </div>
        </div>

        <div className=" bg-gray-100 my-6 rounded-lg">
          <div className="pt-8 text-center">
            <ProductIdentifier value="123.456.78" className=" text-xl" />
          </div>
          <div className="p-4">
            <h5 className="text-base">
              {t("mobile.onboarding-scanner-article-numbers")}
            </h5>
            <p className=" text-sm pt-1">
              {t("mobile.onboarding-scanner-article-numbers-body")}
            </p>
          </div>
        </div>
      </div>

      <Button
        className="w-full mt-5"
        type="primary"
        text={t("mobile.understood")}
        onClick={onClose}
      />
    </div>
  )
}
