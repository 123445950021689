import { isDiscountedPrice } from "@/types/product/listProduct"
import { PipProduct } from "@/types/product/pip"
import { Quantified } from "@/types/product/products"
import { Price } from "@/types/responses/find"

export interface BasePrice {
  inclTax: number
  exclTax: number
  tax: number
}

export interface PriceTotals {
  family?: Price
  regular: Price
  totalDiscountInclTax?: number
  currency: string
  secondaryPrice?: Price
  secondCurrency?: string
  secondFamily?: Price
  totalPriceWithDiscount?: number
}

export function calculatePriceTotals(products: Quantified<PipProduct>[]) {
  const priceTotals: PriceTotals = {
    regular: { inclTax: 0, exclTax: 0, tax: 0 },
    secondaryPrice: { inclTax: 0, exclTax: 0, tax: 0 },
    currency: "",
    secondCurrency: "",
  }

  const family: Price = { inclTax: 0, exclTax: 0, tax: 0 }
  let secondFamily: Price = { inclTax: 0, exclTax: 0, tax: 0 }

  products.forEach((product) => {
    if (!priceTotals.currency) {
      priceTotals.currency = product.price.currency
    }

    priceTotals.regular.inclTax +=
      product.price.regular.inclTax * product.quantity
    priceTotals.regular.exclTax +=
      product.price.regular.exclTax * product.quantity
    priceTotals.regular.tax += product.price.regular.tax * product.quantity

    if (product.price.family) {
      family.inclTax += product.price.family.inclTax * product.quantity
      family.exclTax += product.price.family.exclTax * product.quantity
      family.tax += product.price.family.tax * product.quantity
    } else {
      family.inclTax += product.price.regular.inclTax * product.quantity
      family.exclTax += product.price.regular.exclTax * product.quantity
      family.tax += product.price.regular.tax * product.quantity
    }

    if (family.inclTax < priceTotals.regular.inclTax) {
      priceTotals.family = family
    }

    if (isDiscountedPrice(product.price.regular)) {
      if (!priceTotals.totalDiscountInclTax) {
        priceTotals.totalDiscountInclTax =
          product.price.regular.previousPriceInclTax
      } else {
        priceTotals.totalDiscountInclTax +=
          product.price.regular.previousPriceInclTax || 0
      }
    }

    //If market has two currencies active
    if (product.price.secondRegular && priceTotals.secondaryPrice) {
      //Second regular
      priceTotals.secondCurrency = product.price.secondCurrency
      priceTotals.secondaryPrice.inclTax +=
        product.price.secondRegular.inclTax * product.quantity
      priceTotals.secondaryPrice.exclTax +=
        product.price.secondRegular.exclTax * product.quantity
      priceTotals.secondaryPrice.tax +=
        product.price.secondRegular.tax * product.quantity

      //Second family
      const secondPrices =
        product.price.secondFamily || product.price.secondRegular

      secondFamily = {
        ...secondFamily,
        inclTax: (secondFamily.inclTax +=
          secondPrices.inclTax * product.quantity),
        exclTax: (secondFamily.exclTax +=
          secondPrices.exclTax * product.quantity),
        tax: (secondFamily.tax += secondPrices.tax * product.quantity),
      }

      if (secondFamily.inclTax < priceTotals.secondaryPrice.inclTax) {
        priceTotals.secondFamily = secondFamily
      }
    }
  })

  return priceTotals
}
