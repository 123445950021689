import Modal, { Sheets } from "@ingka/modal"

type FullScreenModalProps = {
  children?: React.ReactNode
  onClose: () => void
  showing: boolean
}

export const FullScreenModal: React.FC<FullScreenModalProps> = ({
  children,
  onClose,
  showing,
}) => {
  return (
    <Modal visible={showing} handleCloseBtn={onClose}>
      <Sheets
        size="large"
        alignment="right"
        preserveAlignment
        header={null}
        footer={null}
        className="rounded-none top-0 max-h-full w-full"
      >
        {children}
      </Sheets>
    </Modal>
  )
}
