import { HIDE_HANDOVER_TO_ONLINE } from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { OnlineProduct } from "@/types/product/categorizedProduct"
import { isArticle } from "@/types/product/listProduct"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import ListSection from "../list/ListSection"
import ProductCard from "../list/product-card/ProductCard"
import { useIsOnline } from "@/hooks/useIsOnline"
import { onlineHandover } from "@/utils/webHandover"
import { useListig } from "@/hooks/useListig"
import PartialChildItem from "../list/product-card/PartialChildItem"

interface OnlineSectionProps {
  products: OnlineProduct[]
}

const OnlineSection: React.FC<OnlineSectionProps> = (props) => {
  const { t } = useTranslation()
  const { market, language } = useLocale()
  const { session } = useListig()
  const { isOnline } = useIsOnline(session.businessUnitCode ?? null)

  const onlineProductsCount = getOnlineProductsCount(props.products)

  return (
    <ListSection
      title={t("Interstitial-pickup-online")}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      <div className="flex justify-between py-8 px-6 bg-white rounded-t-md">
        <p className="text-base">{t("instore.order-ikea-com-info")}</p>
      </div>
      {props.products.map((product) => (
        <div key={product.info.no + product.isOrdered} className="mt-0.5">
          {isArticle(product) || !product.info.isSplit ? (
            <ProductCard
              isAvailableForDelivery={product.stock.homeDelivery.inRange}
              type="ONLINE"
              product={product}
            />
          ) : (
            product.info.childItems.map((item) => (
              <PartialChildItem key={item.info.no} product={item} />
            ))
          )}
        </div>
      ))}
      {market &&
        language &&
        !isOnline &&
        !HIDE_HANDOVER_TO_ONLINE.includes(market) && (
          <div className="flex py-8 px-6 bg-white mt-0.5 rounded-b-md">
            <Button
              data-cy="order-online-button"
              className="flex-1"
              type="secondary"
              text={t("common.order-x-items-online", {
                count: onlineProductsCount,
              })}
              onClick={() =>
                onlineHandover(
                  market,
                  language,
                  session.businessUnitCode,
                  session.source?.type === "kiosk"
                    ? session.source.ukid
                    : undefined,
                  session.listId,
                  props.products,
                )
              }
            />
          </div>
        )}
    </ListSection>
  )
}

export default OnlineSection

function getOnlineProductsCount(products: OnlineProduct[]): number {
  return products.reduce((acc, curr) => {
    if (isArticle(curr) || !curr.info.isSplit) {
      return acc + 1
    } else {
      return acc + curr.info.childItems.length
    }
  }, 0)
}
