import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { TFunction } from "i18next"
import checkmark from "@ingka/ssr-icon/paths/checkmark"
import SSRIcon from "@ingka/ssr-icon"

export type FullServeStatus =
  | "WAITING_FOR_PAYMENT"
  | "PREPARING_ORDER"
  | "READY_FOR_PICKUP"
  | "COLLECTED"

interface FullServeStatusTextProps {
  status?: FullServeStatus
}

const FullServeStatusText: React.FC<FullServeStatusTextProps> = ({
  status,
}) => {
  const { t } = useTranslation()

  const readyStatus = status === "READY_FOR_PICKUP" || status === "COLLECTED"

  return (
    <div data-cy="fullserve-status-text" className="flex mt-4">
      <p
        className={classNames(`rounded py-1 text-base font-bold px-3`, {
          "text-blue-brand bg-blue-brand-opacity/[.08]":
            status !== "READY_FOR_PICKUP",
          "text-semantic-positive bg-semantic-positive-opacity/[.08] pl-2":
            readyStatus,
        })}
      >
        {readyStatus && <SSRIcon paths={checkmark} className="mr-1" />}
        {getOrderStatusText(t, status)}
      </p>
    </div>
  )
}

export default FullServeStatusText

function getOrderStatusText(
  t: TFunction<"translation", undefined>,
  status?: FullServeStatus,
) {
  switch (status) {
    case "WAITING_FOR_PAYMENT":
      return t("Interstitial-full-serve-waiting-for-payment-loading")
    case "PREPARING_ORDER":
      return t("Interstitial-OrderStatus-preparing")
    case "READY_FOR_PICKUP":
      return t("mobile.ready-to-collect")
    case "COLLECTED":
      return t("common.collected")
    default:
      return t("mobile.reserved")
  }
}
