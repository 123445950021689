import { Trans, useTranslation } from "react-i18next"
import { PipProduct } from "@/types/product/pip"
import { getThumbnails } from "@/utils/getThumbnails"
import SSRIcon from "@ingka/ssr-icon"
import creditCard from "@ingka/ssr-icon/paths/credit-card"
import boxExpress from "@ingka/ssr-icon/paths/box-express"
import { SVGProps } from "react"
import Image from "@ingka/image"
import { isFullServeArticle } from "@/utils/isFullServe"
import { isNotProductError, isUArticle } from "@/types/product/products"

const MAX_THUMBNAILS = 4

interface Props {
  products: PipProduct[]
}

const FullServeOnboardingListItem = ({
  phraseKey,
  icon,
}: {
  phraseKey: string
  icon: (prefix?: string | undefined) => SVGProps<SVGElement>[]
}) => (
  <div className="z-5 relative flex w-full items-center gap-4 pb-8 align-top text-gray-900">
    <div style={{ minWidth: "1.25rem" }}>
      <SSRIcon className="text-gray-900" paths={icon} />
    </div>

    <p className="text-sm">
      <Trans className="text-base" i18nKey={phraseKey} />
    </p>
  </div>
)

export function FullServeOnboarding({ products }: Props) {
  const { t } = useTranslation()

  const images: string[] = products
    .map((product) => {
      if (!isUArticle(product)) {
        const fullServeChildItems = product.info.childItems
          ?.filter(isNotProductError)
          .filter(isFullServeArticle)

        const isSplit =
          ("isSplit" in product.info && product.info.isSplit) ||
          fullServeChildItems?.length !== product.info.childItems?.length

        if (fullServeChildItems && isSplit) {
          return fullServeChildItems.map(
            (childItem) => childItem.media.mainImage?.href ?? "",
          )
        }
      }

      return product?.media.mainImage?.href ?? ""
    })
    .flat()
    .sort((_, img) => (img === "" ? -1 : 0))

  const thumbnails = getThumbnails(images, MAX_THUMBNAILS)
  const multipleProducts = images.length > 1

  return (
    <div className="flex flex-col space-y-10">
      <div
        className={`grid ${
          multipleProducts ? "grid-cols-2" : "grid-cols-1"
        } gap-6 self-center`}
      >
        {thumbnails.images.map((image) => (
          <Image
            data-cy="fs-onboarding-product-image"
            key={image}
            className="overflow-hidden"
            alt="Product image"
            src={image}
            width={multipleProducts ? "120" : "180"}
            height={multipleProducts ? "120" : "180"}
          />
        ))}
        {thumbnails.hiddenCount > 0 && (
          <p className="p-11 text-2xl text-center text-gray-250">
            +{thumbnails.hiddenCount}
          </p>
        )}
      </div>

      <p className="text-2xl font-bold m-0 text-gray-600">
        {t(
          images.length === 1
            ? "buy.pay-and-collect-products"
            : "buy.pay-and-collect-products_plural",
        )}
      </p>

      <div>
        <FullServeOnboardingListItem
          icon={creditCard}
          phraseKey={t("mobile.pay-at-closest-payment-point")}
        />
        <FullServeOnboardingListItem
          icon={boxExpress}
          phraseKey={t("mobile.collect-order-at-pickup-point")}
        />
      </div>
    </div>
  )
}
