import { sendKepsEvent } from "../keps"

export function sendOrderOptionsClickEvent(orderNo: string) {
  sendKepsEvent({
    event_location: "order_details",
    event_name: "more_options",
    event_detail: "click_options_button",
    event_payload: JSON.stringify({ order_number: orderNo }),
  })
}
