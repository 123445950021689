import Modal, {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Sheets,
} from "@ingka/modal"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { AddToExistingOrder } from "../full-serve/AddToExistingOrder"
import { sendExistingOrderModalEvent } from "@/analytics/events/sendExistingOrderModalEvent"
import { PipProduct } from "@/types/product/pip"

type AddToExistingOrderModalProps = {
  products: PipProduct[]
  onClose: () => void
  onContinue: () => void
  showing: boolean
  orderNo: string
  entryPoint?: "handover" | "list"
}

export const AddToExistingOrderModal: React.FC<
  AddToExistingOrderModalProps
> = ({
  products,
  onClose,
  onContinue,
  showing,
  orderNo,
  entryPoint = "list",
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      visible={showing}
      handleCloseBtn={() => {
        sendExistingOrderModalEvent("cross_clicked")
        onClose()
      }}
    >
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={
          <ModalFooter className="px-6 pt-0">
            {entryPoint === "handover" && (
              <Button
                type="secondary"
                text={t("mobile.maybe-later")}
                onClick={() => {
                  sendExistingOrderModalEvent("maybe_later", orderNo)
                  onClose()
                }}
                data-cy="maybe-later-button"
              />
            )}
            <Button
              type="emphasised"
              text={t("mobile.add-to-order-button")}
              onClick={() => {
                sendExistingOrderModalEvent("add_to_order", orderNo)
                onContinue()
              }}
              data-cy="add-to-order-button"
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <AddToExistingOrder
            products={products}
            orderNo={orderNo}
            entryPoint={entryPoint}
          />
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
