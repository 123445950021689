import { sendKepsEvent } from "../keps"

export function sendFullServeOnboardingEvent(detail: string) {
  sendKepsEvent({
    event_location: "onboarding",
    event_name: "fullserve_onboarding",
    event_detail: detail,
  })
}

export function sendOpenFullserveOnboardingEvent() {
  sendKepsEvent({
    event_location: "list",
    event_name: "fullserve_onboarding",
    event_detail: "opened_question_mark",
  })
}
