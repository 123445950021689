import {
  PREPARING_ORDER_STATUSES,
  READY_FOR_PICKUP_STATUSES,
} from "@/config/constants"
import { CcOrderPickingStatus } from "@/types/responses/buy"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import HorizontalProgress from "./HorizontalProgress"

interface Props {
  pickingStatus?: CcOrderPickingStatus
  isWaitingForPayment?: boolean
  className?: string
  showStatus?: boolean
}

const OrderStatus: React.FC<Props> = ({
  pickingStatus,
  isWaitingForPayment,
  className,
  showStatus = true,
}) => {
  const { t } = useTranslation()
  const [isPreparingOrder, setIsPreparingOrder] = useState(
    !!pickingStatus && PREPARING_ORDER_STATUSES.includes(pickingStatus),
  )
  const [isReadyForPickup, setIsReadyForPickup] = useState(
    !!pickingStatus && READY_FOR_PICKUP_STATUSES.includes(pickingStatus),
  )

  useEffect(() => {
    if (pickingStatus) {
      setIsPreparingOrder(
        (!isWaitingForPayment &&
          !READY_FOR_PICKUP_STATUSES.includes(pickingStatus)) ||
          PREPARING_ORDER_STATUSES.includes(pickingStatus),
      )
      setIsReadyForPickup(READY_FOR_PICKUP_STATUSES.includes(pickingStatus))
    }
  }, [pickingStatus, isWaitingForPayment])

  const getStatuses = useCallback(
    () => [
      {
        label: t("common.order"),
        active: showStatus && !pickingStatus,
      },
      {
        label: t("common.pay"),
        active: !!(
          showStatus &&
          pickingStatus &&
          !isPreparingOrder &&
          !isReadyForPickup
        ),
      },
      {
        label: t("common.collect"),
        active: !!(
          showStatus &&
          pickingStatus &&
          (isPreparingOrder || isReadyForPickup)
        ),
      },
    ],
    [isPreparingOrder, isReadyForPickup, pickingStatus, showStatus, t],
  )

  const statuses = getStatuses()

  return (
    <HorizontalProgress
      className={className}
      statuses={statuses}
      isReadyForPickup={isReadyForPickup}
    />
  )
}

export default OrderStatus
