import Button from "@ingka/button"

interface FullServeButtonProps {
  disabled?: boolean
  text: string
  loading?: boolean
  onClick: () => void
}

export const FullServeButton: React.FC<FullServeButtonProps> = (props) => {
  return (
    <div className="py-8 px-6 flex flex-col">
      <Button
        className="flex-1"
        type="emphasised"
        text={props.text}
        onClick={props.onClick}
        disabled={props.disabled}
        loading={props.loading}
        data-cy="create-order-button"
      />
    </div>
  )
}
