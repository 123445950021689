import { useLoadingSteps } from "@/hooks/useLoadingSteps"
import { useOptimizely } from "@/hooks/useOptimizely"
import { useListig } from "@/hooks/useListig"
import * as SentryService from "@/utils/sentry"
import LoadingView from "@/views/LoadingView"
import { OptimizelyProvider } from "@optimizely/react-sdk"
import { ReactElement, useEffect } from "react"
import { useMergeLists } from "@/hooks/useMergeLists"
import { useLocale } from "@/hooks/useLocale"
import { getLocale } from "@/utils/locale"

/**
 * Load application and display loading steps.
 */
export const LoadingWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const optimizely = useOptimizely()
  const { market, language } = useLocale()
  const { session } = useListig()
  const { steps, completed } = useLoadingSteps()
  const { mergeLists } = useMergeLists()

  useEffect(() => {
    if (!completed) return
    mergeLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed])

  // update sentry tags when settings change
  useEffect(() => {
    const tags = {
      businessUnitCode: session.businessUnitCode,
      "source.type": session.source?.type,
      locale: getLocale(market, language),
    }
    SentryService.setTags(tags)
  }, [session, market, language])

  // set sentry user to listig id
  useEffect(() => {
    if (session.listId) {
      SentryService.setUser({ id: session.listId })
    }
  }, [session.listId])

  if (completed) {
    return optimizely.optimizely ? (
      <OptimizelyProvider optimizely={optimizely.optimizely}>
        {children}
      </OptimizelyProvider>
    ) : (
      children
    )
  } else {
    return <LoadingView type="linear" steps={steps} />
  }
}
