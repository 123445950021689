import { sendKepsEvent } from "../keps"

export function sendItemQuantityChangedEvent(
  productId: string,
  quantity: number,
  eventLocation: string
) {
  sendKepsEvent({
    event_location: eventLocation,
    event_name: "list_interactions",
    event_detail: "item_quantity_changed",
    event_items: [{ item_no: productId, item_quantity: quantity }],
  })
}
