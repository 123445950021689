import { LanguageSwitcher } from "./LanguageSwitcher"
import Button from "@ingka/button"
import cross from "@ingka/ssr-icon/paths/cross"

interface AppHeaderProps {
  title: string
  onListClick?: () => void
  location?: string
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
  return (
    <div className="sticky top-0 bg-white z-10">
      <div className={`flex w-full justify-between p-6 px-7`}>
        {props.location === "pip" ? (
          <Button
            ssrIcon={cross}
            iconOnly={true}
            type="tertiary"
            onClick={props.onListClick}
            className="h-7 items-center"
            size="small"
          />
        ) : (
          <div className="flex flex-row gap-2 items-center">
            <img alt="IKEA-logo" className="h-7" src="/assets/ikealogo.svg" />
            <h4 className="text-base leading-normal">{props.title}</h4>
          </div>
        )}
        <LanguageSwitcher />
      </div>
      {props.location !== "pip" && <hr className="border-gray-200 m-0" />}
    </div>
  )
}

export default AppHeader
