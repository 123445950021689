import { useAddProducts } from "@/hooks/useAddProducts"
import { useAuth } from "@/hooks/useAuth"
import { useListId } from "@/hooks/useHandoverQuery"
import { useListigQuery } from "@/hooks/useListigQuery"
import { ListigList } from "@/types/listig"
import { ListProduct } from "@/types/product/listProduct"
import { ExpiredListError } from "@/utils/errors"
import { getActiveList } from "@/utils/getActiveList"
import { getActiveList as getActiveListFromLocalStorage } from "@/utils/localStorage"
import { useMemo } from "react"
import { useDeleteProduct } from "./useDeleteProduct"
import { useProducts } from "./useProducts"
import { useUpdateProduct } from "./useUpdateProduct"
import { useListigOrdersProducts } from "./useListigOrderProducts"
import { PriceTotals, calculatePriceTotals } from "@/utils/calculatePriceTotals"

export type InstoreList = Omit<ListigList, "items"> & {
  items: ListProduct[]
  listigOrdersItems?: ListProduct[]
}

export type SessionState = {
  businessUnitCode?: string
  source?:
    | { type: "kiosk"; ukid: string }
    | { type: "external"; source: string }
  listId?: string
}

interface ListQuery {
  list: InstoreList | undefined
  totalPrice: 0 | PriceTotals
  session: SessionState
  error: ExpiredListError | Error | null
  /** true if listig or products are loading */
  isLoading: boolean
  addProducts: ReturnType<typeof useAddProducts>
  updateProduct: ReturnType<typeof useUpdateProduct>
  deleteProduct: ReturnType<typeof useDeleteProduct>
  /** trigger refetch of list */
  refetch: () => void
}

const useListigList = () => {
  const { oAuthToken } = useAuth()

  /** List from the url */
  const listIdFromUrl = useListId()?.listId
  const listFromUrl = useListigQuery(listIdFromUrl, oAuthToken)

  /** List from local storage */
  const listIdFromLocalStorage = getActiveListFromLocalStorage()
  const listFromLocalStorage = useListigQuery(
    listIdFromLocalStorage,
    oAuthToken,
  )

  return getActiveList(
    { id: listIdFromUrl, listQuery: listFromUrl },
    { id: listIdFromLocalStorage, listQuery: listFromLocalStorage },
  )
}

export const useSession = () => {
  const listigQuery = useListigList()

  const session: SessionState = useMemo(
    () => ({
      listId: listigQuery.list?.id,
      businessUnitCode: listigQuery.list?.businessUnit.code,
      source:
        listigQuery.list &&
        (listigQuery.list.metadata.createdBy.system === "upptacka"
          ? { type: "kiosk", ukid: listigQuery.list.metadata.createdBy.user }
          : {
              type: "external",
              source: listigQuery.list.metadata.createdBy.user,
            }),
    }),
    [listigQuery],
  )

  return { session }
}

/**
 * @returns List of products for the list
 * @throws NoServiceError if the wrapper isn't used
 */
export const useListig = (): ListQuery => {
  const listigQuery = useListigList()
  const listId = listigQuery.list?.id

  const productsQuery = useProducts(
    listigQuery.list?.businessUnit.code,
    listigQuery.list?.items,
  )

  const addProducts = useAddProducts(listId ?? null)

  const updateProduct = useUpdateProduct(listId ?? null)

  const deleteProduct = useDeleteProduct(listId ?? null)

  const { session } = useSession()

  const listigOrdersItems = useListigOrdersProducts(listigQuery.list)

  const list = useMemo(() => {
    return listigQuery.list && productsQuery.products
      ? {
          ...listigQuery.list,
          items: productsQuery.products,
          listigOrdersItems,
        }
      : undefined
  }, [listigOrdersItems, listigQuery.list, productsQuery.products])

  const allItems = [...(list?.items ?? []), ...listigOrdersItems]
  const totalPrice = allItems.length && calculatePriceTotals(allItems)

  return {
    list,
    totalPrice,
    session,
    error: listigQuery.error ?? productsQuery.error,
    isLoading:
      listigQuery.isLoading || (!!listigQuery.list && productsQuery.isLoading),
    addProducts,
    updateProduct,
    deleteProduct,
    refetch: listigQuery.refetch,
  }
}
