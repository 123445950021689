import { BaseImage } from "@/types/responses/find"
import SSRIcon from "@ingka/ssr-icon"
import strikethroughImg from "@ingka/ssr-icon/paths/image-strikethrough"

export function ProductCardImage(props: {
  image?: BaseImage
  size?: "normal" | "small"
}) {
  return (
    <div className="product-list-card-image flex-shrink-0">
      {props.image?.href ? (
        <img
          aria-hidden="true"
          className="object-contain"
          style={
            props.size === "small"
              ? { height: "3rem", width: "3rem" }
              : { height: "5rem", width: "5rem" }
          }
          src={props.image?.href}
          alt={props.image?.alt}
        />
      ) : (
        <SSRIcon
          style={
            props.size === "small"
              ? { height: "3rem", width: "3rem" }
              : { height: "5rem", width: "5rem" }
          }
          className="text-gray-700 p-6"
          fill="black"
          paths={strikethroughImg}
        />
      )}
    </div>
  )
}
