import { sendAppPromotionClickedEvent } from "@/analytics/events/sendAppPromotionClickedEvent"
import { sendAppPromotionCloseEvent } from "@/analytics/events/sendAppPromotionCloseEvent"
import useVisit from "@/hooks/useVisit"
import Banner from "@ingka/banner"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export const AppPromotion: React.FC = () => {
  const { t } = useTranslation()

  const { setVisitedPage } = useVisit()

  const appPromotionInfo = getAppPromotionInfo(t)

  if (appPromotionInfo.isMobile) {
    return (
      <Banner
        dismissable={true}
        dismissHandler={() => {
          sendAppPromotionCloseEvent()
          setVisitedPage("appPromotion")
        }}
        ssrIcon={informationCircle}
        body={
          <div>
            {appPromotionInfo.infoText + " "}
            <span
              onClick={() => {
                sendAppPromotionClickedEvent()
                setVisitedPage("appPromotion")
                window.open(appPromotionInfo.linkUrl, "_blank")
              }}
              className="underline text-white"
            >
              {appPromotionInfo.linkText}
            </span>
          </div>
        }
        className="bg-black text-white text-xs"
        ariaCloseTxt="Close banner"
      />
    )
  }
}

type AppPromotionInfo = {
  isMobile: boolean
  infoText: string
  linkText: string
  linkUrl: string
}

function getAppPromotionInfo(
  t: TFunction<"translation", undefined>,
): AppPromotionInfo {
  const userAgent = navigator.userAgent
  const iPhone = userAgent.toLowerCase().includes("iphone")
  const android = userAgent.toLowerCase().includes("android")

  const linkText = iPhone
    ? t("mobile.app-promotion-iphone-link")
    : t("mobile.app-promotion-android-link")

  const linkToAppStore = "https://apps.apple.com/se/app/ikea/id1452164827"
  const linkToPlayStore =
    "https://play.google.com/store/apps/details?id=com.ingka.ikea.app&hl=en"

  const linkUrl = iPhone ? linkToAppStore : linkToPlayStore

  return {
    isMobile: iPhone || android,
    infoText: t("mobile.app-promotion-text"),
    linkText,
    linkUrl,
  }
}
