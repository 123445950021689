import { PipProduct } from "@/types/product/pip"
import { StockProbability } from "@/types/responses/find"
import { getProductSalesMethods } from "@/utils/getSalesLocation"
import {
  ItemSalesMethod1_0_0,
  ItemStockStatus1_0_0,
} from "@upptacka/keps-schema/types/keps"

export function getSalesMethod(
  product?: PipProduct,
): ItemSalesMethod1_0_0 | undefined {
  if (!product) return undefined

  const { salesMethods } = getProductSalesMethods(product)

  if (salesMethods["full-serve"].length > 0) {
    return "full_serve"
  } else if (salesMethods["self-serve"].length > 0) {
    return "self_serve"
  } else if (salesMethods["market-hall"].length > 0) {
    return "markethall"
  } else if (salesMethods["showroom"].length > 0) {
    return "showroom"
  } else if (salesMethods["online"].length > 0) {
    return "online"
  } else {
    return undefined
  }
}

export const typedLowerCaseAvailability = (
  availability: StockProbability | undefined,
): ItemStockStatus1_0_0 | undefined => {
  if (availability === "HIGH_IN_STOCK") return "high_in_stock"

  if (availability === "LOW_IN_STOCK") return "low_in_stock"

  if (availability === "OUT_OF_STOCK") return "out_of_stock"

  return undefined
}
