import { Prices } from "@/types/responses/find"
import { sendKepsEvent } from "../keps"
import { ItemSalesMethod1_0_0 } from "@upptacka/keps-schema/types/keps"

export function sendItemCheckedEvent(
  productId: string,
  quantity: number,
  price: Prices,
  salesMethod: ItemSalesMethod1_0_0,
) {
  sendKepsEvent({
    event_location: "list",
    event_name: "list_interactions",
    event_detail: "item_checked",
    event_items: [
      {
        item_no: productId,
        item_sales_method: salesMethod,
        item_price: price.family?.inclTax ?? price.regular.inclTax,
        item_currency: price.currency,
        item_quantity: quantity,
      },
    ],
  })
}
