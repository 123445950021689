import { ApiError } from "@/types/apiError"
import { useAuth } from "./useAuth"
import { useLocale } from "./useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getVariants } from "@/api/getVariants"

export const useVariants = (productNo?: string, gprNo?: string) => {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()

  const variantsQuery = useQueryWithErrorHandling(
    [productNo, market, language],
    () =>
      oAuthToken && productNo && gprNo
        ? getVariants(market, language, oAuthToken, productNo, gprNo)
        : Promise.reject("Missing data"),
    {
      enabled: !!oAuthToken && !!productNo && !!gprNo,
      gcTime: Infinity,
      staleTime: Infinity,
    },
    "error",
    (error: Error) => error instanceof ApiError && error.statusCode === 404,
  )

  return { data: variantsQuery.data }
}
