/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UError } from "@/utils/errors"
import { captureException } from "@/utils/sentry"
import { HIDE_LANGUAGE_IN_STORE } from "./constants"

export type LanguageMap = typeof languageMap
export type CountryCode = keyof LanguageMap

/**
 * Get the currency symbol, primarily from hard coded map, secondarily from browser.
 */
export function getCurrencySymbol(currency: string, locale: string) {
  return (
    currencySymbols[currency?.toUpperCase().trim()] ||
    (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim()
  )
}

interface CurrencyFormatProps {
  currency: string
  locale: string
  price: number
}

interface CurrencyFormat {
  currencyPosition: "leading" | "trailing"
  decimalSign: string
  integerValue: string
  decimalValue: string
  currencyLabel: string
}

export function getCurrencyFormat({
  locale,
  currency,
  price,
}: CurrencyFormatProps): CurrencyFormat | undefined {
  let currencyPosition: "leading" | "trailing"
  let decimalSign: string,
    integerValue: string,
    decimalValue: string,
    currencyLabel: string

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "code",
  })

  const priceParts = formatter.formatToParts(price)
  const firstPart = priceParts[0]

  if (firstPart) {
    currencyPosition = firstPart.type === "currency" ? "leading" : "trailing"

    decimalSign =
      priceParts.find((pricePart) => pricePart.type === "decimal")?.value ?? ""

    decimalValue =
      priceParts.find((pricePart) => pricePart.type === "fraction")?.value ?? ""

    integerValue = priceParts
      .filter((pricePart) => ["integer", "group"].includes(pricePart.type))
      .map((pricePart) => pricePart.value)
      .join("")
  } else {
    const formattedPrice = formatter.format(price)
    /**
     * assumptions on regex:
     *  - currency code consists of letters a-z of any length,
     *  - currency code and value are optionally separated by a white space character
     *  - decimal sign can be any character except a digit
     *  - group separator can be any character except a digit
     */
    // regex to match format with leading currency
    const leadingCurrencyRegex =
      /^(?:[a-zA-Z]+\s?)(?<integer_leading>(?:\d+[^\d])*\d+)*(?<decimal_leading>[^\d])(?<fraction_leading>\d+)$/
    // regex to match format with trailing currency
    const trailingCurrencyRegex =
      /^(?<integer_trailing>(?:\d+[^\d])*\d+)*(?<decimal_trailing>[^\d])(?<fraction_trailing>\d+)(?:\s[a-zA-Z]+)$/

    const leadingMatches = formattedPrice.match(leadingCurrencyRegex)
    const trailingMatches = formattedPrice.match(trailingCurrencyRegex)
    if (
      leadingMatches &&
      leadingMatches.groups?.integer_leading &&
      leadingMatches.groups?.decimal_leading &&
      leadingMatches.groups?.fraction_leading
    ) {
      currencyPosition = "leading"
      integerValue = leadingMatches.groups.integer_leading
      decimalSign = leadingMatches.groups.decimal_leading
      decimalValue = leadingMatches.groups.fraction_leading
    } else if (
      trailingMatches &&
      trailingMatches.groups?.integer_trailing &&
      trailingMatches.groups?.decimal_trailing &&
      trailingMatches.groups?.fraction_trailing
    ) {
      currencyPosition = "trailing"
      integerValue = trailingMatches.groups.integer_trailing
      decimalSign = trailingMatches.groups.decimal_trailing
      decimalValue = trailingMatches.groups.fraction_trailing
    } else {
      captureException(
        new UError(
          "Unrecognized price format",
          `Unable to match ${formattedPrice}`,
        ),
      )
      return
    }
  }

  // Intl.NumberFormat sets en-CZ to a leading currencyPosition, but IKEA CZ wants it trailing
  if (locale === "en-CZ") {
    currencyPosition = "trailing"
  }

  if (locale === "fi-FI") {
    // display currency code for finland as on web
    currencyLabel = decimalValue === "00" ? ",-" : ""
  } else {
    currencyLabel = getCurrencySymbol(currency, locale)
  }

  const showDecimalValue = parseInt(decimalValue) !== 0

  return {
    currencyPosition,
    decimalSign: showDecimalValue ? decimalSign : "",
    integerValue,
    decimalValue: showDecimalValue ? decimalValue : "",
    currencyLabel,
  }
}

export function capitalize(sentence: string) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

export function isValidMarket(market: CountryCode) {
  return !!languageMap[market.toLowerCase()]
}

export function isValidLanguage(market: CountryCode, language: string) {
  return !!languageMap[market.toLowerCase()]?.languages.includes(language)
}
export interface Market {
  countryCode: CountryCode
  name: string
}
export function getAllMarkets(): Market[] {
  return Object.entries(languageMap)
    .map(([k, v]) => ({
      countryCode: k.toUpperCase(),
      name: v.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

export function getPrimaryLanguage(market: CountryCode) {
  return languageMap[market.toLowerCase()]!.primaryLanguage
}

export function getAvailableLanguages(
  market: CountryCode,
  storeNo: string | undefined,
) {
  const availableLanguages = languageMap[market.toLowerCase()]?.languages ?? [
    getPrimaryLanguage(market.toLowerCase()),
  ]

  if (
    storeNo &&
    HIDE_LANGUAGE_IN_STORE.some((index) => index.storeNo === storeNo)
  ) {
    const hiddenlanguageObject = HIDE_LANGUAGE_IN_STORE.find(
      (index) => index.storeNo === storeNo,
    )

    return availableLanguages.filter(
      (language) => !hiddenlanguageObject?.hiddenLangue.includes(language),
    )
  } else {
    return availableLanguages
  }
}

// There settings are extracted from the web
const languageMap: Record<
  string,
  { name: string; primaryLanguage: string; languages: string[] }
> = {
  ae: {
    name: "United Arab Emirates",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  at: {
    name: "Austria",
    primaryLanguage: "de",
    languages: ["de", "en"],
  },
  au: {
    name: "Australia",
    primaryLanguage: "en",
    languages: ["en"],
  },
  be: {
    name: "Belgium",
    primaryLanguage: "fr",
    languages: ["fr", "nl", "en"],
  },
  bh: {
    name: "Bahrain",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  ca: {
    name: "Canada",
    primaryLanguage: "en",
    languages: ["en", "fr"],
  },
  ch: {
    name: "Switzerland",
    primaryLanguage: "de",
    languages: ["de", "en", "fr", "it"],
  },
  cn: {
    name: "China",
    primaryLanguage: "en",
    languages: ["en", "zh"],
  },
  cz: {
    name: "Czech Republic",
    primaryLanguage: "cs",
    languages: ["cs", "en"],
  },
  de: {
    name: "Germany",
    primaryLanguage: "de",
    languages: ["de", "en"],
  },
  dk: {
    name: "Denmark",
    primaryLanguage: "da",
    languages: ["da"],
  },
  eg: {
    name: "Egypt",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  es: {
    name: "Spain",
    primaryLanguage: "es",
    languages: ["ca", "en", "es", "eu", "gl"],
  },
  fi: {
    name: "Finland",
    primaryLanguage: "fi",
    languages: ["fi", "en"],
  },
  fr: {
    name: "France",
    primaryLanguage: "fr",
    languages: ["fr"],
  },
  gb: {
    name: "United Kingdom",
    primaryLanguage: "en",
    languages: ["en"],
  },
  hr: {
    name: "Croatia",
    primaryLanguage: "hr",
    languages: ["hr"],
  },
  hu: {
    name: "Hungary",
    primaryLanguage: "hu",
    languages: ["hu"],
  },
  ie: {
    name: "Ireland",
    primaryLanguage: "en",
    languages: ["en"],
  },
  in: {
    name: "India",
    primaryLanguage: "en",
    languages: ["en"],
  },
  it: {
    name: "Italy",
    primaryLanguage: "it",
    languages: ["it"],
  },
  jo: {
    name: "Jordan",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  jp: {
    name: "Japan",
    primaryLanguage: "ja",
    languages: ["en", "ja"],
  },
  kr: {
    name: "Korea",
    primaryLanguage: "ko",
    languages: ["en", "ko"],
  },
  kw: {
    name: "Kuwait",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  ma: {
    name: "Morocco",
    primaryLanguage: "fr",
    languages: ["ar", "en", "fr"],
  },
  mx: {
    name: "Mexico",
    primaryLanguage: "es",
    languages: ["en", "es"],
  },
  my: {
    name: "Malaysia",
    primaryLanguage: "en",
    languages: ["en", "ms"],
  },
  nl: {
    name: "Netherlands",
    primaryLanguage: "nl",
    languages: ["en", "nl"],
  },
  no: {
    name: "Norway",
    primaryLanguage: "no",
    languages: ["no"],
  },
  pl: {
    name: "Poland",
    primaryLanguage: "pl",
    languages: ["pl"],
  },
  pt: {
    name: "Portugal",
    primaryLanguage: "pt",
    languages: ["en", "pt"],
  },
  qa: {
    name: "Qatar",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  ro: {
    name: "Romania",
    primaryLanguage: "ro",
    languages: ["ro"],
  },
  rs: {
    name: "Serbia",
    primaryLanguage: "sr",
    languages: ["sr"],
  },
  ru: {
    name: "Russia",
    primaryLanguage: "ru",
    languages: ["ru"],
  },
  sa: {
    name: "Saudi Arabia",
    primaryLanguage: "en",
    languages: ["ar", "en"],
  },
  se: {
    name: "Sweden",
    primaryLanguage: "sv",
    languages: ["sv", "en"],
  },
  sg: {
    name: "Singapore",
    primaryLanguage: "en",
    languages: ["en"],
  },
  si: {
    name: "Slovenia",
    primaryLanguage: "sl",
    languages: ["sl"],
  },
  sk: {
    name: "Slovakia",
    primaryLanguage: "sk",
    languages: ["sk"],
  },
  th: {
    name: "Thailand",
    primaryLanguage: "th",
    languages: ["en", "th"],
  },
  ua: {
    name: "Ukraine",
    primaryLanguage: "uk",
    languages: ["uk"],
  },
  us: {
    name: "United States",
    primaryLanguage: "en",
    languages: ["en", "es"],
  },
}

const currencySymbols: Record<string, string> = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  COU: "COU",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: ",-",
  DJF: "Fdj",
  DKK: ".-",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "Rs.",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "RSD",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: ":-",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "лв",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$",
}

export const measureMap: Record<string, { unit: "metric" | "imperial" }> = {
  ae: {
    unit: "metric",
  },
  at: {
    unit: "metric",
  },
  au: {
    unit: "metric",
  },
  be: {
    unit: "metric",
  },
  bh: {
    unit: "metric",
  },
  ca: {
    unit: "metric",
  },
  ch: {
    unit: "metric",
  },
  cn: {
    unit: "metric",
  },
  cz: {
    unit: "metric",
  },
  de: {
    unit: "metric",
  },
  dk: {
    unit: "metric",
  },
  eg: {
    unit: "metric",
  },
  es: {
    unit: "metric",
  },
  fi: {
    unit: "metric",
  },
  fr: {
    unit: "metric",
  },
  gb: {
    unit: "metric",
  },
  hr: {
    unit: "metric",
  },
  hu: {
    unit: "metric",
  },
  ie: {
    unit: "metric",
  },
  in: {
    unit: "metric",
  },
  it: {
    unit: "metric",
  },
  jo: {
    unit: "metric",
  },
  jp: {
    unit: "metric",
  },
  kr: {
    unit: "metric",
  },
  kw: {
    unit: "metric",
  },
  ma: {
    unit: "metric",
  },
  mx: {
    unit: "metric",
  },
  my: {
    unit: "metric",
  },
  nl: {
    unit: "metric",
  },
  no: {
    unit: "metric",
  },
  pl: {
    unit: "metric",
  },
  pt: {
    unit: "metric",
  },
  qa: {
    unit: "metric",
  },
  ro: {
    unit: "metric",
  },
  rs: {
    unit: "metric",
  },
  ru: {
    unit: "metric",
  },
  sa: {
    unit: "metric",
  },
  se: {
    unit: "metric",
  },
  sg: {
    unit: "metric",
  },
  si: {
    unit: "metric",
  },
  sk: {
    unit: "metric",
  },
  th: {
    unit: "metric",
  },
  ua: {
    unit: "metric",
  },
  us: {
    unit: "imperial",
  },
}
