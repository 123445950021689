import { PipProduct } from "@/types/product/pip"
import {
  Quantified,
  isNotProductError,
  isUArticle,
} from "@/types/product/products"
import {
  SalesLocation,
  StockInformation,
  StockProbability,
  UArticle,
  UChildProduct,
} from "@/types/responses/find"

type SalesMethodsType = Record<Classification, (UChildProduct | UArticle)[]>

/**
 * Returns sales methods of product and childItems.
 */
export const getProductSalesMethods = (
  product: PipProduct,
): {
  /** Sales method of the children */
  salesMethods: SalesMethodsType
  /** If the product consists of multiple items */
  isMultipleItems: boolean
  /** If the product is only available in the given sales method */
  isOnly: (salesMethod: Classification) => boolean
} => {
  const salesMethods: Record<Classification, (UChildProduct | UArticle)[]> = {
    dds: [],
    "food-market": [],
    "full-serve": [],
    "market-hall": [],
    "missing-sales-location": [],
    online: [],
    "partly-available": [],
    "self-serve": [],
    showroom: [],
  }

  const isOnly = (salesMethod: Classification) =>
    Object.values(salesMethods).every(
      (l) => l.length === 0 || l === salesMethods[salesMethod],
    )

  if (isUArticle(product)) {
    salesMethods[classifyProduct(product)] = [product]
    return { salesMethods, isMultipleItems: false, isOnly }
  }

  product.info.childItems?.forEach((ci) => {
    if (isNotProductError(ci)) {
      const classified = classifyProduct(ci)
      salesMethods[classified].push(ci)
    }
  })

  // If the sum of all sales method is greater than 1, then the product is mixed
  const isMultiple =
    Object.values(salesMethods).reduce((acc, curr) => acc + curr.length, 0) > 1

  return { salesMethods, isMultipleItems: isMultiple, isOnly }
}

function classifyProduct(product: UArticle | UChildProduct): Classification {
  const salesLocation = getPrimarySalesLocation(product)
  const salesMethod = salesLocation?.salesMethod

  // Food item products
  if (salesLocation?.salesMethod === "SWEDISH_FOOD_MARKET") {
    return "food-market"
  }

  // Cashline product don't have a saleslocation, but the id is either OTCLxx or CLxxxx
  if (salesLocation?.salesLocationId.toLowerCase().includes("cl")) {
    return "market-hall"
  }

  // Fallback to quantity 1 if no quantity is present
  if (isEnoughInStock({ quantity: 1, ...product })) {
    switch (salesMethod) {
      case "SELF_SERVE":
        return "self-serve"
      case "FULL_SERVE":
        return "full-serve"
      case "MARKET_HALL":
        return "market-hall"
      case "SHOWROOM":
        return "showroom"
      default:
        /**
      Fallback to in-stock if no sales method is present due
      to it having type Contact staff which results to no salesLocation
      */
        return "missing-sales-location"
    }
  }

  return "online"
}

/**
 * Decide if the product is in range and with enough quantity in stock.
 */
export const isEnoughInStock = (
  product: Quantified<{ stock: StockInformation }>,
) =>
  !!(
    product.stock.cashCarry.inRange &&
    product.stock.cashCarry.quantity &&
    product.stock.cashCarry.quantity >= product.quantity
  )

/**
 * Get primary sales location of product.
 */
const getPrimarySalesLocation = (product: Pick<UArticle, "locations">) =>
  product.locations?.[0]

type Classification =
  | "full-serve"
  | "missing-sales-location"
  | "self-serve"
  | "market-hall"
  | "showroom"
  | "dds"
  | "online"
  | "food-market"
  | "partly-available"

export function getStockAndSalesLocationFromChildItems(
  childItems: {
    locations?: SalesLocation[]
    stock: StockInformation
    quantity: number
  }[],
): { salesLocations: SalesLocation[]; stock: StockInformation } {
  const stock: StockInformation = {
    cashCarry: {
      inRange: false,
      probability: { thisDay: "LOW_IN_STOCK" },
      quantity: 0,
    },
    clickCollect: { inRange: false, available: false },
    homeDelivery: { inRange: false },
  }

  const salesLocations: SalesLocation[] = []

  const inRange = childItems
    .map((ci) => ci.stock.cashCarry.inRange)
    .some((ci) => ci === true)

  const stockProbabilityMap = childItems.map<StockProbability>(
    (ci) => ci.stock.cashCarry.probability?.thisDay || "UNKNOWN",
  )

  const stockProbability: StockProbability = stockProbabilityMap.includes(
    "UNKNOWN",
  )
    ? "UNKNOWN"
    : stockProbabilityMap.includes("OUT_OF_STOCK")
      ? "OUT_OF_STOCK"
      : stockProbabilityMap.includes("LOW_IN_STOCK")
        ? "LOW_IN_STOCK"
        : stockProbabilityMap.includes("MEDIUM_IN_STOCK")
          ? "MEDIUM_IN_STOCK"
          : "HIGH_IN_STOCK"

  const stockQuantity = Math.floor(
    Math.min(
      ...childItems.map(
        (ci) => (ci.stock.cashCarry.quantity ?? 0) / ci.quantity,
      ),
    ),
  )

  stock.cashCarry.inRange = inRange
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  stock.cashCarry.probability!.thisDay = stockProbability
  stock.cashCarry.quantity = stockQuantity

  return {
    salesLocations,
    stock,
  }
}
