import { Image } from "@/types/responses/find"

interface Props {
  repairabilityDocument: Image
}

export function RepairabilityLabel({ repairabilityDocument }: Props) {
  return (
    <div className="flex-col overflow-y-scroll" data-cy="repairability-modal">
      <h3 className="w-full text-lg" data-cy="repairability-header">
        {repairabilityDocument?.alt}
      </h3>
      <img
        data-cy="repairability-document"
        className="repairability-document my-6 w-90 rounded border border-solid border-gray-300"
        alt="Repairability label"
        src={repairabilityDocument?.href}
      />
    </div>
  )
}
