import { i18n } from "@/utils/i18n"
import { Fee } from "@/types/responses/find"

export function getFeeText(fee: Fee, locale: string, abbr: boolean) {
  const feeMarkup = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: fee.currencyCode,
  }).format(fee.amount)

  return fee.type.toLowerCase() === "eco"
    ? i18n.t(`ProductFeeModal-${abbr ? "abbreviated-" : ""}ECO-title`) +
        ` ${feeMarkup}`
    : i18n.t(`ProductFeeModal-${abbr ? "abbreviated-" : ""}WEEE-title`) +
        ` ${feeMarkup}`
}
