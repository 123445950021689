import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"

type Props = {
  articleNo: string
  location: string
  title?: string
}

export const MissingSalesLocation: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      className="missing-sales-location flex w-full flex-row justify-between"
      data-component="full-serve-location"
    >
      <ProductIdentifier
        label={props.title ?? t("common.article-number")}
        value={props.articleNo}
      />

      <div className="ml-6 mt-auto flex w-full justify-end">
        <ProductIdentifier
          label={t("common.location")}
          value={props.location}
        />
      </div>
    </div>
  )
}
