import { sendKepsEvent } from "../keps"

export function sendHandoverToSaljaEvent() {
  sendKepsEvent({
    event_location: "list",
    event_name: "handover",
    event_detail: "salja",
    event_referrer: { referrer_id: "mobile" },
  })
}
