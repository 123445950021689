import { sendKepsEvent } from "../keps"

export function sendPipScrollLocationEvent(productId: string | undefined) {
  sendKepsEvent({
    event_location: "pip",
    event_name: "scroll_button_location",
    event_items: [{ item_no: productId }],
    event_payload: JSON.stringify({ event_trigger_type: "scroll" }),
  })
}
