import { usePrivacyPolicy } from "@/hooks/usePrivacyPolicy"
import { LoadingBall } from "@ingka/loading"
import { Interweave } from "interweave"
import { useTranslation } from "react-i18next"
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from "@ingka/modal"
import Button from "@ingka/button"

type Props = {
  onClose: () => void
  showPolicy: boolean
  policyType?: "consent-box" | "full"
  market: string
  language: string
}

export const PrivacyPolicyModal = ({
  onClose,
  showPolicy,
  market,
  language,
}: Props) => {
  const { t } = useTranslation()
  const {
    privacyPolicy,
    isLoading: privacyPolicyLoading,
    isError: privacyPolicyFailed,
  } = usePrivacyPolicy(market, language)

  return (
    <Modal visible={showPolicy} handleCloseBtn={() => onClose()}>
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={
          <ModalFooter className="px-6 pt-0">
            <Button type="primary" text="Done" onClick={() => onClose()} />
          </ModalFooter>
        }
      >
        <ModalBody>
          <div className="h-full" onClick={(e) => e.preventDefault()}>
            {privacyPolicyFailed ? (
              <div className="flex h-full flex-col items-start justify-start">
                <h3 className="mb-8">
                  {t("common.privacy-policy-anchor-text")}
                </h3>
                <p>{t("OrderNotificationModal-email-privacy-read-more")}</p>
              </div>
            ) : privacyPolicyLoading || !privacyPolicy ? (
              <div className="flex h-full w-full items-center justify-center">
                <LoadingBall />
              </div>
            ) : (
              <Interweave
                attributes={{ id: "privacy-policy" }}
                content={privacyPolicy}
              />
            )}
          </div>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
