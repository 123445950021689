import { sendKepsEvent } from "../keps"

export function sendCoworkerHelpEvent(reply: string) {
  sendKepsEvent({
    event_location: "list",
    event_name: "fullserve_survey",
    event_detail: "fullserve_coworker_help",
    event_payload: JSON.stringify({ did_coworker_help: reply }),
  })
}
