import { getPrivacyPolicy } from "@/api/getPrivacyPolicy"
import { useTranslation } from "react-i18next"
import { useAuth } from "./useAuth"
import { useQueryWithErrorHandling } from "./useReactQuery"

export function usePrivacyPolicy(market: string, language: string) {
  const { t } = useTranslation()
  const { oAuthToken } = useAuth()

  const { data, isLoading, isError } = useQueryWithErrorHandling(
    ["getPrivacyPolicy", language, market],
    () =>
      getPrivacyPolicy(
        t("links.privacy-policy", {
          defaultValue:
            "https://www.ikea.com/gb/en/customer-service/privacy-policy/",
        }),
        oAuthToken,
      ),
    {
      gcTime: 1000 * 60 * 60 * 24,
      enabled: !!market && !!language && !!oAuthToken,
      retry: (attempt, error) =>
        attempt < 3 && error.name !== "PRIVACY_POLICY_SERVER_ERROR",
    },
  )
  return { privacyPolicy: data, isLoading, isError }
}
