import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"

export const SelfServeLocation: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="full-serve-location flex w-full flex-row justify-between">
      <ProductIdentifier
        label={props.title ?? t("common.article-number")}
        value={props.articleNo}
      />
      <div className="flex flex-row gap-2 transition-all duration-150">
        <ProductIdentifier
          value={props.aisle}
          label={t("SelfServeLocation-Aisle")}
        />
        <ProductIdentifier
          value={props.bin}
          label={t("SelfServeLocation-Shelf")}
        />
      </div>
    </div>
  )
}

type Props = {
  articleNo: string
  aisle: string
  bin: string
  title?: string
}
