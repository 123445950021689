import { UpptackaApiErrorName } from "./errors"

/**
 * Custom error representing a failed HTTP request.
 */
export class ApiError extends Error {
  /**
   * Decoded json object of the response
   */
  payload: unknown
  /**
   * HTTP response code
   */
  statusCode: number
  /**
   * Custom error representing a failed HTTP request.
   * @param errorName The name fo the failed endpoint
   * @param statusCode HTTP response code
   * @param payload Decoded JSON payload
   */
  constructor(
    errorName: UpptackaApiErrorName,
    statusCode: number,
    payload: unknown,
  ) {
    super(`API ${errorName} responded with ${statusCode}`)
    this.name = errorName
    this.payload = payload
    this.statusCode = statusCode
  }
}
