import { useTranslation } from "react-i18next"
import { getThumbnails } from "@/utils/getThumbnails"
import { PipProduct } from "@/types/product/pip"

const MAX_THUMBNAILS = 4

interface Props {
  products: PipProduct[]
  orderNo: string
  entryPoint: "handover" | "list"
}

export function AddToExistingOrder({ products, orderNo, entryPoint }: Props) {
  const { t } = useTranslation()
  const images: string[] = products.map(
    (product) => product.media.mainImage?.href ?? "",
  )
  const thumbnails = getThumbnails(images, MAX_THUMBNAILS)

  const hasMultipleProducts = images.length > 1

  return (
    <div className="flex flex-col">
      <div
        className={`grid ${
          hasMultipleProducts ? "grid-cols-2" : "grid-cols-1"
        } gap-6 mb-10 self-center`}
      >
        {thumbnails.images.map((image) => (
          <img
            key={image}
            className="overflow-hidden"
            src={image}
            alt="product"
            width={hasMultipleProducts ? "120" : "180"}
            height={hasMultipleProducts ? "120" : "180"}
          />
        ))}
        {thumbnails.hiddenCount > 0 && (
          <p className="p-11 text-2xl text-center text-gray-250">
            +{thumbnails.hiddenCount}
          </p>
        )}
      </div>

      <p className="text-2xl font-bold m-0 text-gray-600">
        {t(
          entryPoint === "handover"
            ? "mobile.add-to-order"
            : "mobile.add-to-order-confirmation",
          {
            productname: hasMultipleProducts
              ? ""
              : ` ${products[0]?.info.name}`,
          },
        )}
      </p>
      <p className="text-base m-0 mt-3">
        {t(
          entryPoint === "handover"
            ? "mobile.add-to-existing-order"
            : "mobile.add-to-existing-order-confirmation",
          {
            count: hasMultipleProducts ? 2 : 1,
            ordernumber: orderNo,
          },
        )}
      </p>
    </div>
  )
}
