import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router"
import { useListId } from "./useHandoverQuery"
import { useLocale } from "./useLocale"
import { useSearchParams } from "react-router-dom"
import { allowedFeatureFlags } from "@/config/constants"
import { ProductType } from "@/types/product/products"

/**
 * Abstraction for navigating in app.
 * To be used instead of reat-router/useNavigate
 * @returns a function that triggers navigation to another page in the app
 */
export const useLocalNavigation = () => {
  const { market, language } = useLocale()
  const localPath = `/${market.toLowerCase()}/${language}`

  const [params] = useSearchParams()
  const shortId = params.get("shortId")
  const featureFlags = Object.entries(Object.fromEntries(params)).reduce(
    (obj, [key, value]) => ({
      ...obj,
      ...(allowedFeatureFlags.find((f) => f === key) && {
        [key]: value,
      }),
    }),
    {},
  )

  const oldListId = useListId()

  const nav = useNavigate()

  const navigate = useCallback(
    (
      to:
        | { path: "add-product" }
        | { path: "checkout" }
        | { path: "list"; listId?: string }
        | {
            path: "product"
            productNo: string
            productType: ProductType
            listId?: string
          }
        | { path: "scanner-instructions" }
        | { path: "welcome"; listId?: string }
        | { path: "enter-product" },
      options?: NavigateOptions,
      searchParams?: URLSearchParams,
    ) => {
      const listId = ("listId" in to && to.listId) || oldListId?.listId
      const listPath = listId && getListPath(localPath, listId)
      if (!listPath) throw new Error("Missing path")
      let path: string
      switch (to.path) {
        case "add-product": {
          path = listPath + "/add"
          break
        }
        case "checkout": {
          path = listPath + "/checkout"
          break
        }
        case "list": {
          if (to.listId) path = localPath + `/list/${to.listId}`
          else path = listPath
          break
        }
        case "product": {
          path = listPath + `/product/${to.productNo}`
          break
        }
        case "scanner-instructions": {
          path = listPath + "/scanner-instructions"
          break
        }
        case "welcome": {
          path = listPath + "/welcome"
          break
        }
        case "enter-product": {
          path = listPath + "/add/article-number"
        }
      }

      const queryParams = new URLSearchParams({
        ...(searchParams && Object.fromEntries(searchParams)),
        ...(to.path === "product" && { type: to.productType }),
        ...(shortId && { shortId }),
        ...featureFlags,
      })

      nav(path + (queryParams ? "?" + queryParams.toString() : ""), options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localPath, nav, oldListId?.listId],
  )

  return { navigate }
}

function getListPath(localePath: string, listId: string) {
  return localePath + "/list/" + listId
}
