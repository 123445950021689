import { VariantCategory } from "@/types/responses/find"

/** The variant of the current product should be first.
 * If the variant value of the current product is already first, there's no need to sort them,
 * but if it's not, we need to move it to the top while also keeping the order of the other variants.
 */
export function getSortedVariants(
  variantCategory: VariantCategory | undefined,
  currentProductNo: string,
) {
  const indexOfCurrent =
    variantCategory?.variants.findIndex(
      (variant) => variant.productNo === currentProductNo,
    ) ?? 0

  if (indexOfCurrent < 1) return variantCategory?.variants

  const currentVariant = variantCategory?.variants[indexOfCurrent]

  const part1 = variantCategory?.variants.slice(0, indexOfCurrent)
  const part2 = variantCategory?.variants.slice(indexOfCurrent + 1)

  const variantValuesOrdered =
    part1 && part2
      ? [currentVariant, ...part2, ...part1]
      : part1
        ? [currentVariant, ...part1]
        : part2
          ? [currentVariant, ...part2]
          : [currentVariant]

  return variantValuesOrdered
}
