import { useLocale } from "@/hooks/useLocale"
import { getOneTrustConsents } from "@/utils/getOneTrustConsents"
import { useCallback, useEffect, useState } from "react"

/** ms between retries on load */
const INIT_INTERVAL = 50

export const useCookieConsentVisible = () => {
  const [, setState] = useState(0)
  const [cookieConsentVisible, setCookieConsentVisible] = useState(
    isOptanonBannerOpen(),
  )
  /** trigger a rerender */
  const forceRerender = useCallback(() => setState((v) => v + 1), [])

  const addCallback = useCallback(() => {
    if (window.Optanon) {
      // force a rerender when cookie consent is changed
      window.Optanon.OnConsentChanged(forceRerender)
    } else {
      // if cookie script hasn't been attached, try againg later
      setTimeout(addCallback, INIT_INTERVAL)
    }
  }, [forceRerender])

  useEffect(() => {
    addCallback()
  }, [addCallback])

  useEffect(() => {
    const onOptanonChange = () => {
      setCookieConsentVisible(isOptanonBannerOpen())
    }

    window.Optanon?.OnConsentChanged(onOptanonChange)
  }, [])

  return {
    isVisible: cookieConsentVisible,
  }
}

export const useCookieConsent = () => {
  const { market } = useLocale()
  const cookieGroups = getOneTrustConsents(market)

  return {
    isNecessaryEnabled: cookieGroups[1].hasConsent,
    isPerformanceEnabled: cookieGroups[2].hasConsent,
    isFunctionalEnabled: cookieGroups[3].hasConsent,
    isTargetingEnabled: cookieGroups[4].hasConsent,
  }
}

/**
 * Check if Cookie Consent popup is open. Built in Optanon function is using cookies,
 * which are disabled in incognito mode.
 *
 * @returns true if the cookie consent popup is open
 */
function isOptanonBannerOpen(): boolean {
  const banner = document.getElementById("onetrust-banner-sdk")

  if (!banner) return false

  return banner?.style.visibility !== "hidden"
}
