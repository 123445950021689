import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useLocale } from "@/hooks/useLocale"
import { OnlineProduct } from "@/types/product/categorizedProduct"
import { ButtonTooltip } from "@/components/ButtonTooltip"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import shoppingBagAdd from "@ingka/ssr-icon/paths/shopping-bag-add"
import arrowRight from "@ingka/ssr-icon/paths/arrow-right"
import { useTranslation } from "react-i18next"
import { useIsOnline } from "@/hooks/useIsOnline"
import { onlineHandover } from "@/utils/webHandover"
import { useListig } from "@/hooks/useListig"
import useVisit from "@/hooks/useVisit"
import { sendScannerButtonClickEvent } from "@/analytics/events/sendScannerButtonClickEvent"

interface AddProductButtonProps {
  listIsEmpty: boolean
  products: OnlineProduct[]
}

export const AddProductButton: React.FC<AddProductButtonProps> = ({
  listIsEmpty,
  products,
}) => {
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { session } = useListig()
  const { isOnline } = useIsOnline(session.businessUnitCode ?? null)
  const { market, language } = useLocale()
  const { visited, setVisitedPage } = useVisit()

  const openAddProduct = () =>
    navigate({
      path: visited.add ? "add-product" : "scanner-instructions",
    })

  return (
    <div
      className="sticky bottom-0 w-full h-auto bg-white z-50"
      style={{ boxShadow: "0px -4px 16px rgba(0,0,0,0.1)" }}
    >
      <div className="p-4 flex">
        {!isOnline || listIsEmpty ? (
          <ButtonTooltip
            showToolTip={!visited.list}
            closeToolTip={() => setVisitedPage("list")}
            title={t("mobile.onboarding-list-title")}
            body={t("mobile.onboarding-list-body")}
            confirmText={t("mobile.understood")}
          >
            <Button
              type="primary"
              fluid
              onClick={() => {
                setVisitedPage("list")
                openAddProduct()
                sendScannerButtonClickEvent()
              }}
            >
              <SSRIcon paths={shoppingBagAdd} />
              <span className="w-2" />
              <span>{t("common.add-product")}</span>
            </Button>
          </ButtonTooltip>
        ) : (
          <>
            <ButtonTooltip
              showToolTip={!visited.list}
              closeToolTip={() => setVisitedPage("list")}
              title={t("mobile.onboarding-list-title")}
              body={t("mobile.onboarding-list-body")}
              confirmText={t("mobile.understood")}
              tailPosition={5}
            >
              <Button
                type="primary"
                onClick={() => {
                  openAddProduct()
                  setVisitedPage("list")
                }}
                iconOnly
                ssrIcon={shoppingBagAdd}
              />
            </ButtonTooltip>
            <span className="w-8" />
            <ButtonTooltip
              showToolTip={visited.list && !visited.checkout}
              closeToolTip={() => setVisitedPage("checkout")}
              title={t("mobile.onboarding-checkout-title")}
              body={t("mobile.onboarding-checkout-body")}
              confirmText={t("mobile.understood")}
              tailPosition={80}
            >
              <Button
                type="emphasised"
                fluid
                onClick={() => {
                  setVisitedPage("checkout")
                  onlineHandover(
                    market,
                    language,
                    session.businessUnitCode,
                    session.source?.type === "kiosk"
                      ? session.source.ukid
                      : undefined,
                    session.listId,
                    products,
                  )
                }}
              >
                <span>{t("shopping-list.checkout")}</span>
                <span className="w-2" />
                <SSRIcon paths={arrowRight} />
              </Button>
            </ButtonTooltip>
          </>
        )}
      </div>
    </div>
  )
}
