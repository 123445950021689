import { useCallback, useEffect, useState } from "react"
import { useKortisInfoQuery } from "@/hooks/useKortis"
import { QrScanner } from "@/components/QrScanner"
import { ScanErrorMessage } from "@/components/scanner/ScanErrorMessage"
import { ToastMessage } from "@/components/scanner/ToastMessage"
import { useAuth } from "@/hooks/useAuth"
import { useListig } from "@/hooks/useListig"
import { useListigQuery } from "@/hooks/useListigQuery"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { verifyDomain } from "@/utils/verifyDomain"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import cross from "@ingka/ssr-icon/paths/cross"
import { useTranslation } from "react-i18next"
import questionMark from "@ingka/ssr-icon/paths/question-mark-circle"
import { sendSuccessfulScanEvent } from "@/analytics/events/sendSuccessfulScanEvent"
import { sendUnsuccessfulScanEvent } from "@/analytics/events/sendUnsuccessfulScanEvent"
import { sendButtonArticleNumberEvent } from "@/analytics/events/sendButtonArticleNumberEvent"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import { parseSingleProductHandover } from "@/utils/parseHandover"

export const Scanner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { oAuthToken } = useAuth()
  const [kortisId, setKortisId] = useState<string>()
  const { addProducts: addListigProducts } = useListig()
  const { navigate } = useLocalNavigation()
  const [scanError, setScanError] = useState(false)
  const { t } = useTranslation()
  const [toastData, setToastData] = useState<string>()
  const [paused, setPaused] = useState<boolean>(false)

  function onError() {
    setScanError(true)
    sendUnsuccessfulScanEvent()
  }

  /** identify a kortis link and use the id  */
  const findAndSetLink = useCallback(
    (data: string) => {
      setPaused(true)

      const parsedKortisData = parseKortisLink(data)
      if (parsedKortisData) {
        setKortisId(parsedKortisData)
        return
      }
      const handoverData = parseProductLink(data)
      if (handoverData) {
        sendSuccessfulScanEvent()
        sendOpenPipEvent(handoverData.id, "scanner")
        navigate(
          {
            path: "product",
            productNo: handoverData.id,
            productType: handoverData.type,
          },
          undefined,
          handoverData.quantity !== undefined
            ? new URLSearchParams({ quantity: handoverData.quantity })
            : undefined,
        )
        return
      }
      const toastMessage = parseToastMessage(data)
      if (toastMessage) {
        setToastData(toastMessage)
        return
      }
      onError()
    },
    [navigate],
  )

  const kortisInfo = useKortisInfoQuery(kortisId)

  const listId =
    kortisInfo &&
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.exec(
      kortisInfo.redirectUrl,
    )?.[0]

  const listigQuery = useListigQuery(listId, oAuthToken, false)

  const addProducts = addListigProducts.add
  // if we have a list, add products and close scanner
  useEffect(() => {
    if (kortisId && listigQuery.list) {
      setKortisId(undefined)
      addProducts(listigQuery.list?.items, {
        onSuccess: () => {
          onClose()
        },
      })
    }

    if (listigQuery.error) {
      setKortisId(undefined)
      onError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kortisId, listigQuery.list, listigQuery.error])

  return (
    <div
      className="absolute inset-x-0 bottom-0 overflow-hidden"
      style={{ width: "100dvw", height: "100dvh" }}
    >
      <QrScanner onData={findAndSetLink} paused={paused} />
      <div className="absolute flex flex-col justify-center items-center h-full w-full top-0">
        <div
          className="flex border-white border-solid rounded-3xl border"
          style={{
            boxShadow: "0 0 0 9999px rgba(0,0,0, 0.6)",
            height: "16rem",
            maxHeight: "50%",
            aspectRatio: "1 / 1",
          }}
        />
      </div>
      <div className="flex items-center justify-between w-full h-full absolute top-0 flex-col">
        <div className="flex p-6 text-white justify-between w-full flex-row">
          <div
            onClick={() => {
              setPaused(true)
              onClose()
            }}
            className="flex-1"
          >
            <SSRIcon paths={cross} />
          </div>
          <h5 className="text-inherit text-center flex-1 whitespace-nowrap">
            {t("common.add-product")}
          </h5>
          <div
            className="flex-1 text-right"
            onClick={() => {
              navigate({ path: "scanner-instructions" })
            }}
          >
            <SSRIcon paths={questionMark} />
          </div>
        </div>
        <div
          style={{
            paddingBottom: "10vh",
          }}
        >
          <Button
            type="image-overlay"
            size="small"
            onClick={() => {
              navigate({ path: "enter-product" })
              sendButtonArticleNumberEvent()
            }}
            text={t("scanner.enter-article-number")}
          />
        </div>
      </div>
      <ScanErrorMessage
        close={() => {
          setPaused(false)
          setScanError(false)
        }}
        show={scanError}
      />
      <ToastMessage close={() => setToastData(undefined)} message={toastData} />
    </div>
  )
}

/**
 * Extract the five letter kortis id from the link.
 * @param link kortis link (to the ikea domain)
 * @returns five digit kortis id or null if parsing failed
 */
function parseKortisLink(link: string) {
  try {
    const url = new URL(link)
    const kortisId = url.pathname.substring(1)
    if (verifyDomain(url) && /^\w{5}$/.test(kortisId)) return kortisId
    return null
  } catch {
    return null
  }
}

/**
 * Validate the link and return the handover params for product handover
 * @returns handover details or null if parsing failed
 */
function parseProductLink(link: string) {
  try {
    const url = new URL(link)
    const handover = parseSingleProductHandover(url.searchParams)
    if (verifyDomain(url) && handover) return handover
    return null
  } catch {
    return null
  }
}

function parseToastMessage(data: string): string | null {
  return /^toast:(.*)$/.exec(data)?.at(1) ?? null
}
