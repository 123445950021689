import Keps from "@/analytics/keps"
import { useCookieConsent } from "@/hooks/useCookieConsent"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { ReactElement, useEffect } from "react"

/**
 * Initialize analytics session.
 */
export const AnalyticsWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { session } = useListig()
  const { source, businessUnitCode, listId } = session
  const { market, language } = useLocale()
  const { isPerformanceEnabled } = useCookieConsent()

  useEffect(() => {
    if (
      market &&
      language &&
      source &&
      businessUnitCode &&
      listId &&
      (isPerformanceEnabled || market.toUpperCase() === "CN")
    ) {
      Keps.initializeAnalytics({
        market,
        language,
        storeNo: businessUnitCode,
        listId,
      })
    } else if (!isPerformanceEnabled) {
      Keps.disableAnalytics()
    }
  }, [market, source, language, businessUnitCode, listId, isPerformanceEnabled])

  return children
}
