export const QuantityBadge: React.FC<{
  quantity: number
}> = ({ quantity }) => {
  const badge = quantity < 100 ? quantity : "99+"

  return quantity > 1 ? (
    <div
      data-cy="quantity-badge"
      className="flex justify-center items-center bg-white border-[1px] border-gray-250 rounded-[12px] py-[4px] px-[6px]"
    >
      <div className="text-xs leading-3 font-bold">x {badge}</div>
    </div>
  ) : null
}
