import { sendKepsEvent } from "../keps"

export function sendOpenPipEvent(
  productId: string | undefined,
  referrerId: string,
) {
  sendKepsEvent({
    event_location: "pip",
    event_name: "open_pip",
    event_referrer: {
      referrer_id: referrerId.toLocaleLowerCase().replaceAll("-", "_"),
    },
    event_items: [
      {
        item_no: productId,
      },
    ],
  })
}
