import { OAuthToken } from "@/api/getOAuthToken"
import secureStorage from "localstorage-slim"

secureStorage.config.encrypt = true
secureStorage.config.ttl = 60 * 60 * 24

const CACHED_OAUTHTOKEN_KEY = "oauthcache"

export function setCachedOAuthToken(
  token: OAuthToken,
  expiresIn: number
): void {
  secureStorage.set(CACHED_OAUTHTOKEN_KEY, token, {
    ttl: expiresIn,
    encrypt: true,
  })
}

export function getCachedOAuthToken(): OAuthToken | null {
  return secureStorage.get(CACHED_OAUTHTOKEN_KEY)
}
