/**
 * Appends a dot after every third character
 */
export function dottedIdentifier(itemNo: string): string {
  return itemNo
    .split("")
    .reduce(
      (identifier, char, index) =>
        identifier + char + (index % 3 === 2 ? "." : ""),
      ""
    )
}
