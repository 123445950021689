import { sendKepsEvent } from "../keps"

export function sendFullServeSurveyEvent(rating: number) {
  sendKepsEvent({
    event_location: "list",
    event_name: "list_interactions",
    event_detail: "fullserve_rating",
    event_payload: JSON.stringify({ rating: rating.toString() }),
  })
}
