import { InstoreList } from "@/hooks/useListig"
import {
  DepartmentArticle,
  DepartmentChildItem,
  DepartmentProduct,
  FullServeChildItem,
  FullServeProduct,
  DDSKitchenArticleWithLocations,
  isDepartmentArticle,
  isFullServeArticle,
  isSelfServeArticle,
  OnlineChildItem,
  OnlineProduct,
  SelfServeChildItem,
  SelfServeProduct,
  SplitInfo,
} from "@/types/product/categorizedProduct"
import {
  isArticle,
  isDDSKitchenArticle,
  ListProduct,
  isInStockArticle,
} from "@/types/product/listProduct"
import {
  sortDepartmentProducts,
  sortSelfServeProducts,
} from "@/utils/sortProductList"

export interface CategorizedArticleState {
  FULL_SERVE: FullServeProduct[]
  SHOWROOM: DepartmentProduct[]
  MARKET_HALL: DepartmentProduct[]
  SELF_SERVE: SelfServeProduct[]
  ONLINE: OnlineProduct[]
  FLOORS: (DepartmentArticle | DepartmentChildItem)[][]
}

// only show item from order in list if it's partially full serve
const showInList = (item: ListProduct) =>
  "isSplit" in item.info && item.info.isSplit

export function categorizeProducts(list?: InstoreList) {
  const items = categorize(list?.items ?? [])
  const listigOrdersItems = categorize(list?.listigOrdersItems ?? [])

  return {
    FULL_SERVE: [...items.FULL_SERVE], // skip full serve items from order since they are already ordered
    SHOWROOM: [
      ...items.SHOWROOM,
      ...listigOrdersItems.SHOWROOM.filter(showInList),
    ],
    MARKET_HALL: [
      ...items.MARKET_HALL,
      ...listigOrdersItems.MARKET_HALL.filter(showInList),
    ],
    SELF_SERVE: [
      ...items.SELF_SERVE,
      ...listigOrdersItems.SELF_SERVE.filter(showInList),
    ],
    ONLINE: [...items.ONLINE, ...listigOrdersItems.ONLINE.filter(showInList)],
    FLOORS: [...items.FLOORS, ...listigOrdersItems.FLOORS],
  }
}

export function categorize(products: ListProduct[]) {
  const categorizedProducts: CategorizedArticleState = {
    FULL_SERVE: [],
    SHOWROOM: [],
    MARKET_HALL: [],
    SELF_SERVE: [],
    ONLINE: [],
    FLOORS: [],
  }

  products.forEach((product) => {
    if (isArticle(product)) {
      if (!isInStockArticle(product)) {
        categorizedProducts["ONLINE"].push(product)
        return
      }
      if (isDDSKitchenArticle(product)) {
        const productWithLocations = DDSKitchenArticleWithLocations(product)

        categorizedProducts[
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          productWithLocations.locations[0]!.salesMethod
        ].push(productWithLocations)
      } else if (
        isDepartmentArticle(product) &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        product.locations[0]!.salesLocationId.startsWith("F0")
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const floor = parseInt(product.locations[0]!.salesLocationId.charAt(2))
        const thisFloor = categorizedProducts["FLOORS"][floor]
        if (thisFloor) {
          const itemExist = thisFloor.find(
            (item: DepartmentArticle | DepartmentChildItem) =>
              item.info.no === product.info.no,
          )
          if (!itemExist) {
            thisFloor.push(product)
          } else if (product.quantity) {
            itemExist.quantity = (itemExist.quantity || 0) + product.quantity
          }
        } else {
          categorizedProducts["FLOORS"][floor] = [product]
        }
      } else if (isDepartmentArticle(product)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        categorizedProducts[product.locations[0]!.salesMethod].push(product)
      } else if (isSelfServeArticle(product)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        categorizedProducts[product.locations[0]!.salesMethod].push(product)
      } else if (isFullServeArticle(product)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        categorizedProducts[product.locations[0]!.salesMethod].push(product)
      }
    } else {
      const categorizedSalesProductChildren: CategorizedSalesProductChildren = {
        FULL_SERVE: [],
        SHOWROOM: [],
        MARKET_HALL: [],
        SELF_SERVE: [],
        ONLINE: [],
        FLOORS: [],
      }
      product.info.childItems.forEach((childItem) => {
        if ("error" in childItem) {
          return
        }
        if (!isInStockArticle(childItem)) {
          categorizedSalesProductChildren["ONLINE"].push(childItem)
          return
        }

        if (
          isDepartmentArticle(childItem) &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          childItem.locations[0]!.salesLocationId.startsWith("F0")
        ) {
          const floor = parseInt(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            childItem.locations[0]!.salesLocationId.charAt(2),
          )
          const thisFloor = categorizedProducts["FLOORS"][floor]
          if (thisFloor) {
            const itemExist = thisFloor.find(
              (item: DepartmentArticle | DepartmentChildItem) =>
                item.info.no === childItem.info.no,
            )
            if (!itemExist) {
              thisFloor.push(childItem)
            } else if (childItem.quantity) {
              itemExist.quantity =
                (itemExist.quantity || 0) + childItem.quantity
            }
          } else {
            categorizedProducts["FLOORS"][floor] = [childItem]
          }
        } else if (isDepartmentArticle(childItem)) {
          categorizedSalesProductChildren[
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            childItem.locations[0]!.salesMethod
          ].push(childItem)
        } else if (isSelfServeArticle(childItem)) {
          categorizedSalesProductChildren["SELF_SERVE"].push(childItem)
        } else if (isFullServeArticle(childItem) && childItem.locations) {
          categorizedSalesProductChildren["FULL_SERVE"].push(childItem)
        }
      })

      const isSplit = !!Object.keys(categorizedSalesProductChildren).find(
        (key) =>
          categorizedSalesProductChildren[
            key as keyof CategorizedSalesProductChildren
          ].length > 0 &&
          categorizedSalesProductChildren[
            key as keyof CategorizedSalesProductChildren
          ].length !== product.info.childItems.length,
      )

      const splitInfo: SplitInfo = isSplit
        ? {
            isSplit: true,
            hasFullServePart:
              categorizedSalesProductChildren.FULL_SERVE.length > 0,
            totalChildItems: product.info.childItems.length,
            totalChildItemsQuantity: product.info.childItems.reduce(
              (acc, n) =>
                "quantity" in n && n.quantity ? acc + n.quantity : acc,
              0,
            ),
          }
        : { isSplit: false }

      if (categorizedSalesProductChildren.FULL_SERVE.length > 0) {
        categorizedProducts.FULL_SERVE.push({
          ...product,
          info: {
            ...product.info,
            ...splitInfo,
            childItems: categorizedSalesProductChildren.FULL_SERVE,
          },
        } as FullServeProduct)
      }
      if (categorizedSalesProductChildren.MARKET_HALL.length > 0) {
        categorizedProducts.MARKET_HALL.push({
          ...product,
          info: {
            ...product.info,
            ...splitInfo,
            childItems: sortDepartmentProducts(
              categorizedSalesProductChildren.MARKET_HALL,
            ),
          },
        } as DepartmentProduct)
      }
      if (categorizedSalesProductChildren.SHOWROOM.length > 0) {
        categorizedProducts.SHOWROOM.push({
          ...product,
          info: {
            ...product.info,
            ...splitInfo,
            childItems: sortDepartmentProducts(
              categorizedSalesProductChildren.SHOWROOM,
            ),
          },
        } as DepartmentProduct)
      }
      if (categorizedSalesProductChildren.SELF_SERVE.length > 0) {
        categorizedProducts.SELF_SERVE.push({
          ...product,
          info: {
            ...product.info,
            ...splitInfo,
            childItems: sortSelfServeProducts(
              categorizedSalesProductChildren.SELF_SERVE,
            ),
          },
        } as SelfServeProduct)
      }
      if (categorizedSalesProductChildren.ONLINE.length > 0) {
        categorizedProducts.ONLINE.push({
          ...product,
          info: {
            ...product.info,
            ...splitInfo,
            childItems: categorizedSalesProductChildren.ONLINE,
          },
        } as OnlineProduct)
      }
    }
  })

  categorizedProducts.SELF_SERVE = sortSelfServeProducts(
    categorizedProducts.SELF_SERVE,
  )
  categorizedProducts.MARKET_HALL = sortDepartmentProducts(
    categorizedProducts.MARKET_HALL,
  )
  categorizedProducts.SHOWROOM = sortDepartmentProducts(
    categorizedProducts.SHOWROOM,
  )

  return categorizedProducts
}

interface CategorizedSalesProductChildren extends CategorizedArticleState {
  FULL_SERVE: FullServeChildItem[]
  SHOWROOM: DepartmentChildItem[]
  MARKET_HALL: DepartmentChildItem[]
  SELF_SERVE: SelfServeChildItem[]
  ONLINE: OnlineChildItem[]
}
