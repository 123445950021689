import { useTranslation } from "react-i18next"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { FeedbackFormEmoji } from "./FeedbackFormEmoji"
import { FeedbackFormText } from "./FeedbackFormText"
import { FeedbackFormCompleted } from "./FeedbackCompleted"
import { sendFeedback } from "@/api/sendFeedback"
import { useAuth } from "@/hooks/useAuth"
import { sendFeedbackEvent } from "@/analytics/events/sendFeedbackEvent"
import useVisit from "@/hooks/useVisit"
import { feedbackModalState } from "@/stores/feedbackModal"

export type FeedbackEmojiOption =
  | "very-dissatisfied"
  | "dissatisfied"
  | "ok"
  | "satisfied"
  | "very-satisfied"
  | ""

export interface FeedbackViewProps {
  handleOnCloseClick: () => void
}

export type FeedbackData = {
  id: string
  feedbackType: "mobile" | ""
  feedbackOption: FeedbackEmojiOption
  feedbackText: string
}

export const FeedbackView: React.FC<FeedbackViewProps> = ({
  handleOnCloseClick,
}) => {
  const { oAuthToken } = useAuth()
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const { setVisitedPage } = useVisit()
  const [feedbackData, setFeedbackData] = useState<FeedbackData>({
    id: uuidv4(),
    feedbackType: "mobile",
    feedbackOption: "",
    feedbackText: "",
  })
  const nmbrOfQuestions = 2

  const handleNextClick = () => {
    setStep(step + 1)
    if (step === nmbrOfQuestions && oAuthToken) {
      sendFeedback(feedbackData, oAuthToken)
      sendFeedbackEvent(feedbackData.id)
      setVisitedPage("mobileFeedback")
      feedbackModalState.hasShownFeedbackModalState = true
    }
  }

  return (
    <div className="flex flex-col">
      {step <= nmbrOfQuestions && (
        <p className="text-sm mb-4">
          {t("mobile.feedback-question", {
            count: step,
            totalCount: nmbrOfQuestions,
          })}
        </p>
      )}

      {step === 1 && (
        <FeedbackFormEmoji
          feedback={feedbackData.feedbackOption}
          handleOptionClick={(option: FeedbackEmojiOption) =>
            setFeedbackData({ ...feedbackData, feedbackOption: option })
          }
          handleNextClick={handleNextClick}
        />
      )}

      {step === 2 && (
        <FeedbackFormText
          handleOptionClick={(option) =>
            setFeedbackData({ ...feedbackData, feedbackText: option })
          }
          handleNextClick={handleNextClick}
        />
      )}

      {step === 3 && (
        <FeedbackFormCompleted handleOnCloseClick={handleOnCloseClick} />
      )}
    </div>
  )
}
