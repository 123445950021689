import { fetchWithErrorHandling } from "@/utils/fetch"
import { captureWarning } from "@/utils/sentry"
import type { AnalyticsEvent as KepsAnalyticsEvent } from "@upptacka/keps-schema"

export const postKepsEvent = async (event: KepsAnalyticsEvent) =>
  fetchWithErrorHandling("KEPS", process.env.KEPS_ANALYTICS_URL + "/event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  }).catch((error) => {
    captureWarning(error)
  })
