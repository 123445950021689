export async function getPrivacyPolicy(
  url: string,
  kongToken: string | undefined,
) {
  // eslint-disable-next-line no-restricted-syntax
  const response = await fetch(
    `${process.env.BUY_API_URL}/privacy-policy?url=${url}`,
    {
      headers: {
        Authorization: "Bearer " + kongToken,
      },
    },
  )

  if (!response.ok)
    throw new Error(
      response.status === 500
        ? "PRIVACY_POLICY_SERVER_ERROR"
        : "PRIVACY_POLICY_FAILED",
    )

  return response.text()
}
