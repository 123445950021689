import classNames from "classnames"
import { useEffect, useState } from "react"
import HorizontalProgressBullet from "./HorizontalProgressBullet"

interface ProgressStatus {
  label: string
  active: boolean
}
type Variant = "primary" | "active" | "history" | "last" | "future"

function HorizontalProgress({
  statuses,
  className,
  isReadyForPickup,
}: {
  statuses: ProgressStatus[]
  className?: string
  isReadyForPickup: boolean
}) {
  const [activeIndex, setActiveIndex] = useState(
    statuses.findIndex((status) => status.active),
  )

  useEffect(() => {
    setActiveIndex(statuses.findIndex((status) => status.active))
  }, [statuses])

  return (
    <div
      className={classNames(
        "order-status flex flex-row text-black justify-center items-center overflow-hidden",
        {
          [className || ""]: className,
        },
      )}
    >
      {statuses.map((status, idx) => {
        let variant: Variant = status.active
          ? "active"
          : idx > activeIndex
            ? "future"
            : "history"

        if (variant === "active" && idx === 0) {
          variant = "primary"
        }

        if (variant === "active" && idx === statuses.length - 1) {
          variant = "last"
        }

        return (
          <HorizontalProgressBullet
            key={idx}
            label={status.label}
            variant={variant}
            isLast={idx === statuses.length - 1}
            isReadyForPickup={isReadyForPickup}
          />
        )
      })}
    </div>
  )
}
export default HorizontalProgress
