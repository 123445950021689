import {
  APP_PROMOTION_ENABLED,
  APP_PROMOTION_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"
//import { useAdminSettings } from "./useAdminSettings"

/**
 * @returns true if app promotion has been enabled for market/store/kiosk
 */
export function useAppPromotion() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  //const { settings } = useAdminSettings() //TODO: Will be implemented in story 7738

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: APP_PROMOTION_ENABLED,
    ENABLED_LOCATIONS: APP_PROMOTION_LOCATIONS,
  }) //|| settings?.appPromotionEnabled
}
