import { getAvailableLanguages } from "@/config/locales"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "@/hooks/useLocale"
import { Option } from "@ingka/select"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

export const LanguageSwitcher = () => {
  const { session } = useListig()
  const { market } = useLocale()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const currentPath = location.pathname
  const currentLang = currentPath.split("/")[2]

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    currentLang,
  )

  useEffect(() => {
    if (
      !market ||
      !currentLang ||
      !selectedOption ||
      currentLang === selectedOption
    )
      return

    const newPath = currentPath.replace(
      `${market.toLowerCase()}/${currentLang}`,
      `${market.toLowerCase()}/${selectedOption}`,
    )
    navigate(newPath + (params ? "?" + params.toString() : ""))
    window.location.reload()
  }, [
    market,
    selectedOption,
    currentLang,
    setSelectedOption,
    currentPath,
    navigate,
    params,
  ])

  const availableLanguages = getAvailableLanguages(
    market,
    session.businessUnitCode,
  )

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value)
  }

  return (
    <>
      {availableLanguages.length > 1 && (
        <div className="flex justify-center item-center">
          <select
            data-cy="language-switcher"
            id="language-switcher"
            onChange={handleChange}
            value={selectedOption}
            className="text-sm font-bold text-gray-500 bg-white"
          >
            {availableLanguages.map((lang) => {
              return (
                <Option name={lang.toUpperCase()} value={lang} key={lang} />
              )
            })}
          </select>
        </div>
      )}
    </>
  )
}
