import classNames from "classnames"
interface HorizontalProgressBulletProps {
  isLast?: boolean
  variant: Variant
  label?: string
  isReadyForPickup: boolean
}

type Variant = "primary" | "active" | "history" | "last" | "future"

function HorizontalProgressBullet({
  variant = "history",
  isLast = true,
  label,
  isReadyForPickup,
}: HorizontalProgressBulletProps) {
  const smallBullet = " h-[5px] w-[5px] "
  const largeBullet = " h-[13px] w-[13px] "
  const green = " bg-semantic-positive border-semantic-positive "
  const blue = " bg-blue-brand border-blue-brand "
  const gray = " border-gray-300 "

  return (
    <div className="flex flex-col relative items-start w-full ">
      <div className="flex flex-row justify-start items-center absolute w-full h-[13px] left-[50%] ">
        <div
          className={classNames("flex justify-center bg-white", {
            "ml-[-10.5px] w-[21px]":
              variant === "primary" ||
              variant === "active" ||
              variant === "last",
            "ml-[-6.5px] w-[13px]":
              variant !== "primary" &&
              variant !== "active" &&
              variant !== "last",
          })}
        >
          {/* This is the bullet */}
          <div
            className={classNames(
              ` flex shrink-0 rounded-full border-[1px] border-solid `,
              {
                [smallBullet + green]: isReadyForPickup && variant !== "last",
                [largeBullet + green]: isReadyForPickup && variant === "last",
                [smallBullet + blue]: variant === "history",
                [largeBullet + blue]:
                  variant === "active" ||
                  variant === "primary" ||
                  (!isReadyForPickup && variant === "last"),
                [smallBullet + gray]: variant === "future",
              },
            )}
          />
        </div>

        {/* This is the connecting line */}
        {!isLast && (
          <span
            className={classNames(` w-full h-[1px]`, {
              "bg-semantic-positive ": isReadyForPickup,
              "bg-blue-brand ": variant === "history",
              "bg-gray-300 ":
                variant === "active" ||
                variant === "primary" ||
                variant === "future",
            })}
          />
        )}
      </div>

      {/* This is the label */}
      <div
        className={classNames(
          `w-full h-[48px] justify-center flex mt-5 text-xs `,
          {
            "text-black ": variant === "history",
            "text-base font-bold ": variant === "primary",
            "text-black text-base font-bold": variant === "last",
            "text-black rounded text-base font-bold": variant === "active",
            "text-gray-400 rounded text-base ": variant === "future",
          },
        )}
      >
        {label}
      </div>
    </div>
  )
}

export default HorizontalProgressBullet
