const emailRegex = new RegExp(
  /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
)
const maskRegex = new RegExp(/(.{1}).+(.{2}@.+)/)
const digitRegex = new RegExp(/(\d{2,}\s*)+/g)

export const maskSensitiveData = (input: string) => {
  // Mask emails

  let maskedInput = input.replace(emailRegex, (match) => {
    return match.replace(
      maskRegex,
      (result, firstChar, lastPart) =>
        firstChar + "*".repeat(result.length - lastPart.length - 1) + lastPart,
    )
  })

  // Mask sequences of 2 or more digits

  maskedInput = maskedInput.replace(digitRegex, (match) =>
    "*".repeat(match.length),
  )

  return maskedInput
}
