import * as i18n from "i18next"
import httpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { captureWarning } from "./sentry"
import { UError } from "./errors"

i18n
  .use(initReactI18next)
  .use(httpBackend) // passes i18n down to react-i18next
  .init({
    backend: { loadPath: "/locales/{{lng}}.json" },
    fallbackLng: ["en-EN"],
    load: "currentOnly",
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    missingInterpolationHandler: (text) => {
      captureWarning(
        new UError(
          "Missing translation interpolation",
          `Translation key: ${text}`,
          undefined,
        ),
      )
      return undefined
    },
  })

export { i18n }
