import { BelowMessage } from "@/components/common/BelowMessage"
import Button from "@ingka/button"

export const ToastMessage: React.FC<{
  close: () => void
  message: string | undefined
}> = ({ close, message }) => {
  return (
    <BelowMessage show={!!message}>
      <div className="flex flex-col items-center p-10 space-y-4">
        🦒
        <h6>{message}</h6>
        <Button text="Return to scanner" onClick={close} type="primary" fluid />
      </div>
    </BelowMessage>
  )
}
