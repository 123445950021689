import { proxy, useSnapshot } from "valtio"

type CcOrderState = {
  orderCreatedNotificationVisible: boolean
  // place in global state since it's not shared between mutation instances
  isOrderCreationLoading: boolean
}

const defaultSettings: CcOrderState = {
  orderCreatedNotificationVisible: false,
  isOrderCreationLoading: false,
}

const state = proxy(defaultSettings)

export const useCcOrderSnapshot = () => useSnapshot(state)

/**
 * Sets orderCreatedNotificationVisible to true for a set time
 *
 * @param duration time in millis that notificaton should be visible, must be
 * lower than 15 seconds
 */
export function showOrderCreatedNotification(duration = 5000): void {
  if (duration > 15000) return

  state.orderCreatedNotificationVisible = true
  setTimeout(() => {
    state.orderCreatedNotificationVisible = false
  }, duration)
}

/**
 * Set  orderCreatedNotificationVisible to false
 */
export function dismissOrderCreatedNotification(): void {
  state.orderCreatedNotificationVisible = false
}

export function setIsOrderCreationLoading(isLoading: boolean) {
  state.isOrderCreationLoading = isLoading
}
