import { sendKepsEvent } from "../keps"

export function sendScannerButtonClickEvent() {
  sendKepsEvent(
    {
      event_location: "list",
      event_name: "scanner",
      event_detail: "button_add_product",
    },
    "2024-02-29"
  )
}
