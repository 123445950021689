import { getProductInformation } from "@/api/getProductInformation"
import { createProductList } from "@/utils/createProductList"
import { useQueries } from "@tanstack/react-query"
import { useAuth } from "./useAuth"
import { useLocale } from "./useLocale"
import { isDefined } from "@/utils/isDefined"
import { ListigList } from "@/types/listig"

export function useListigOrdersProducts(list?: ListigList) {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()

  const result = useQueries({
    queries:
      list?.orders?.map((order) => ({
        queryKey: [
          "listigOrderProducts",
          order.orderNo,
          order.items ?? "no_order_items",
        ],
        queryFn: async () => {
          const items = await getProductInformation(
            market,
            language,
            { code: list?.businessUnit.code ?? "", type: "sto" },
            order.items?.map((art) => ({
              id: art.id,
              type: art.type,
            })) ?? [],
            oAuthToken ?? "",
          )

          return createProductList(items, order.items ?? [], true)
        },
        enabled: !!list && !!oAuthToken,
        retry: true,
      })) ?? [],
  })

  return result
    .map((r) => r.data)
    .filter(isDefined)
    .flat()
}
