import { ProductPrice } from "@/components/common/ProductPrice"
import { capitalize } from "@/config/locales"
import { useTranslation } from "react-i18next"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import { OnlineChildItem } from "@/types/product/categorizedProduct"
import { ProductAvailableForDelivery } from "./ProductAvailableForDelivery"
import InlineMessage from "@ingka/inline-message"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { ProductCardImage } from "./ProductCardImage"

interface PartialChildItemProps {
  product: OnlineChildItem
}

const PartialChildItem: React.FC<PartialChildItemProps> = ({ product }) => {
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { selectedQuantity } = useSelectQuantity(
    product.info.no,
    product.info.type,
    product.quantity,
    "list",
  )

  function handleProductClick() {
    sendOpenPipEvent(product.info.no, "list")
    navigate({
      path: "product",
      productNo: product.info.no,
      productType: product.info.type,
    })
  }

  return (
    <div
      onClick={handleProductClick}
      className="flex flex-col bg-white p-6"
      data-cy="partial-child-item"
    >
      <div className="flex flex-row gap-5 mb-6">
        <ProductCardImage image={product.media.mainImage} />

        <div className="flex flex-col flex-1 gap-3">
          <ProductPrice
            productName={`${selectedQuantity ? selectedQuantity + " x " : ""}${product.info.name}`}
            size="small"
            childItem
            productDescription={capitalize(product.info.mediumDescription)}
            className="font-bold mr-0 text-gray-400"
            prices={product.price}
            offers={product.offers}
            quantity={selectedQuantity}
            list
          />

          <ProductAvailableForDelivery
            isAvailableForDelivery={product.stock.homeDelivery.inRange}
          />
        </div>
      </div>

      <InlineMessage
        className="mb-0"
        variant="informative"
        ssrIcon={informationCircle}
        body={t("Interstitial-ProductListCard-collect-full-serve-of-split-spr")}
      />
    </div>
  )
}
export default PartialChildItem
