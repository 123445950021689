import { ProductPrice } from "@/components/common/ProductPrice"
import { capitalize } from "@/config/locales"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import ProductIdentifier from "@ingka/product-identifier"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import {
  FullServeChildItem,
  OnlineChildItem,
} from "@/types/product/categorizedProduct"
import classNames from "classnames"

interface ChildItemProps {
  product: FullServeChildItem | OnlineChildItem
  isSplit: boolean
}

const OrderChildItem: React.FC<ChildItemProps> = ({ product, isSplit }) => {
  const { selectedQuantity } = useSelectQuantity(
    product.info.no,
    product.info.type,
    product.quantity,
    "list",
  )

  return (
    <div
      className="flex flex-col pb-4 px-6 bg-white"
      data-cy="order-child-item"
    >
      <ProductPrice
        productName={`${selectedQuantity ? selectedQuantity + " x " : ""}${product.info.name}`}
        size="small"
        childItem
        productDescription={capitalize(product.info.mediumDescription)}
        className={classNames("font-bold mr-0", {
          "text-black": isSplit,
          "text-gray-400": !isSplit,
        })}
        prices={product.price}
        offers={product.offers}
        quantity={selectedQuantity}
        list
      />

      <ProductIdentifier
        data-cy="product-nr"
        className="mt-0 mb-2 text-sm"
        value={dottedIdentifier(product.info.no)}
      />
    </div>
  )
}
export default OrderChildItem
