import { useEffect } from "react"
import LoadingView from "@/views/LoadingView"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { onlineHandover } from "@/utils/webHandover"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { useTranslation } from "react-i18next"
import { sendHandoverToOnlineEvent } from "@/analytics/events/sendHandoverToOnlineEvent"

/**
 * Display a loading view to inform the users that we are redirecting them to the web.
 */
export const Checkout: React.FC = () => {
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { market, language } = useLocale()
  const { session } = useListig()
  const productsQuery = useListig()
  const products = categorizeProducts(productsQuery.list)

  useEffect(() => {
    sendHandoverToOnlineEvent()
    setTimeout(() => {
      onlineHandover(
        market,
        language,
        session.businessUnitCode,
        session.source?.type === "kiosk" ? session.source.ukid : undefined,
        session.listId,
        products.ONLINE,
      )
      navigate({ path: "list" })
    }, 3000)
    // eslint-disable-next-line
  }, [])

  return (
    <LoadingView
      type="infinite"
      title={`${t("mobile.just-a-sec")}. ${t("mobile.redirecting-to-web")}`}
    />
  )
}
