import React from "react"
import Button from "@ingka/button"
import feedbackDissatisfied from "@ingka/ssr-icon/paths/feedback-dissatisfied"
import feedbackHappy from "@ingka/ssr-icon/paths/feedback-happy"
import feedbackNeutral from "@ingka/ssr-icon/paths/feedback-neutral"
import feedbackSad from "@ingka/ssr-icon/paths/feedback-sad"
import feedbackSatisfied from "@ingka/ssr-icon/paths/feedback-satisfied"
import { FeedbackIcon } from "./FeedbackIcon"
import { FeedbackEmojiOption } from "./FeedbackView"
import { useTranslation } from "react-i18next"

type FeedbackFormProps = {
  feedback: string
  handleOptionClick: (option: FeedbackEmojiOption) => void
  handleNextClick: () => void
}

export const FeedbackFormEmoji: React.FC<FeedbackFormProps> = ({
  feedback,
  handleOptionClick,
  handleNextClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <p className="text-lg text-gray-600 font-bold">
        {t("mobile.feedback-using-shoppinglist")}
      </p>

      <div className="flex flex-row justify-between mt-10 mb-20">
        <FeedbackIcon
          icon={feedbackSad}
          isSelected={feedback === "very-dissatisfied"}
          onClick={() => handleOptionClick("very-dissatisfied")}
        />
        <FeedbackIcon
          icon={feedbackDissatisfied}
          isSelected={feedback === "dissatisfied"}
          onClick={() => handleOptionClick("dissatisfied")}
        />
        <FeedbackIcon
          icon={feedbackNeutral}
          isSelected={feedback === "ok"}
          onClick={() => handleOptionClick("ok")}
        />
        <FeedbackIcon
          icon={feedbackSatisfied}
          isSelected={feedback === "satisfied"}
          onClick={() => handleOptionClick("satisfied")}
        />
        <FeedbackIcon
          icon={feedbackHappy}
          isSelected={feedback === "very-satisfied"}
          onClick={() => handleOptionClick("very-satisfied")}
        />
      </div>
      <Button
        disabled={feedback === ""}
        onClick={handleNextClick}
        type="primary"
        size="small"
      >
        {t("common.next")}
      </Button>
    </div>
  )
}
