import { sendKepsEvent } from "../keps"

export function sendExistingOrderModalEvent(detail: string, orderNo?: string) {
  sendKepsEvent({
    event_location: "list",
    event_name: "edit_order",
    event_detail: detail,
    event_payload: orderNo
      ? JSON.stringify({ order_number: orderNo })
      : undefined,
  })
}
