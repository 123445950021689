import { useState } from "react"
import Button from "@ingka/button"
import TextArea from "@ingka/text-area"
import FormField from "@ingka/form-field"
import { PrivacyPolicyModal } from "../../modals/PrivacyPolicyModal"
import { useTranslation } from "react-i18next"
import { useLocale } from "@/hooks/useLocale"
import { maskSensitiveData } from "@/utils/maskSensitiveData"

type Props = {
  handleOptionClick: (option: string) => void
  handleNextClick: () => void
}

export const FeedbackFormText: React.FC<Props> = ({
  handleOptionClick,
  handleNextClick,
}: Props) => {
  const [value, setValue] = useState("")
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false)
  const { t } = useTranslation()
  const { market, language } = useLocale()

  return (
    <div className="flex flex-col">
      <p className="text-lg text-gray-600 font-bold">
        {t("mobile.feedback-your-experience")}
      </p>
      <p className="text-sm mb-5">
        {t("mobile.feedback-tell-us-about-experience")}
      </p>

      <FormField
        className="w-full m-0"
        characterLimit={0}
        valid="error"
        validation={{
          id: "error-msg-id",
          msg: "Validation msg text",
          type: "error",
        }}
      >
        <TextArea
          onChange={(e) => {
            setValue(e.target.value)
            handleOptionClick(maskSensitiveData(e.target.value))
          }}
          label=""
          className="text-sm"
        />
      </FormField>

      <p className="text-sm mt-3 mb-10">
        {t("Feedback-personal-data")}
        <span onClick={() => setPrivacyModalOpen(true)} className="underline">
          {t("common.privacy-policy-anchor-text")}
        </span>
      </p>

      <Button
        onClick={() => {
          handleNextClick()
        }}
        disabled={value === ""}
        type="primary"
        size="small"
      >
        {t("Feedback-send-feedback")}
      </Button>
      <PrivacyPolicyModal
        onClose={() => setPrivacyModalOpen(false)}
        showPolicy={privacyModalOpen}
        market={market}
        language={language}
      />
    </div>
  )
}
