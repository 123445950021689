import { Variant } from "@/types/responses/find"
import React from "react"
import { useTranslation } from "react-i18next"
import { VariantButton } from "./VariantButton"
import { useProduct } from "@/hooks/useProducts"
import { getPriceDiffString } from "@/utils/getPriceDiff"
import { VisualVariationTypes } from "@/config/constants"
import { PipProduct } from "@/types/product/pip"

export const ProductVariant: React.FC<{
  handleVariantOnClick: (productNo: string) => void
  variant?: Variant
  currentVariantValue: string
  currentProduct: PipProduct
  variantTypeNameGlobal: string
}> = ({
  handleVariantOnClick,
  variant,
  currentVariantValue,
  currentProduct,
  variantTypeNameGlobal,
}) => {
  const { t } = useTranslation()
  const { productInfo: variantProduct } = useProduct(
    variant?.productNo,
    currentProduct.info.type,
  )

  if (!variant) return null

  return (
    <VariantButton
      key={variant.value}
      selected={variant.value === currentVariantValue}
      title={
        variant.value === "-"
          ? t("ProductVariantsModal-without-variant", {
              variation: variant.value,
            })
          : variant.value
      }
      onClick={() => {
        if (variant?.productNo) {
          handleVariantOnClick(variant.productNo)
        }
      }}
      priceDiff={getPriceDiffString(
        variantProduct?.price,
        currentProduct.price,
      )}
      image={
        VisualVariationTypes.includes(variantTypeNameGlobal)
          ? variantProduct?.media.mainImage?.href
          : ""
      }
    />
  )
}
