import { measureMap } from "@/config/locales"

/**
 * Converts market and language into locale format xx_ZZ where xx is language code and ZZ country code
 * @param market country code
 * @param language language code
 */
export function getLocale(market: string, language: string) {
  return `${language.toLowerCase()}-${market.toUpperCase()}`
}

export const getSuffix = (
  market: string,
  suffix?: { metric?: string; imperial?: string },
) => suffix?.[measureMap[market.toLowerCase()]?.unit ?? "metric"]

export const isMetric = (market: string) =>
  measureMap[market.toLowerCase()]?.unit === "metric"
