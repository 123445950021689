import React, { useState } from "react"
import Button from "@ingka/button"
import { isComponent } from "@/utils/isComponent"

/**
 * Highlight button with white border and display tooltip above
 */
export const ButtonTooltip: React.FC<{
  showToolTip: boolean
  closeToolTip: () => void
  title: string
  body: string
  confirmText: string
  tailPosition?: number
  children?: React.ReactNode
}> = ({
  showToolTip,
  closeToolTip,
  title,
  body,
  confirmText,
  tailPosition = 10,
  children,
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLDivElement>()

  return showToolTip ? (
    <>
      <div className="absolute bottom-0 left-0 w-screen h-screen bg-black opacity-40 z-10" />

      <div
        className="fixed left-0 m-6 z-20"
        style={{
          bottom: `calc(100% - ${
            buttonRef?.getBoundingClientRect().top ?? 0
          }px)`,
        }}
      >
        <div className="bg-white w-full p-7 flex flex-col rounded-md">
          <h2 className="text-base text-black">{title}</h2>
          <div className="h-3" />
          <p className="text-sm text-black">{body}</p>
          <div className="h-6" />
          <Button text={confirmText} onClick={closeToolTip} />
        </div>

        <div
          className="absolute"
          style={{
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderTop: "14px solid white",
            bottom: "-14px",
            left: `${tailPosition}%`,
          }}
        />
      </div>

      {children &&
        React.Children.map(
          children,
          (child) =>
            isComponent(child) &&
            React.cloneElement(child, {
              ref: (ref: HTMLDivElement) => setButtonRef(ref),
              style: { border: "4px solid white", zIndex: 20 },
            })
        )}
    </>
  ) : (
    <>{children}</>
  )
}
