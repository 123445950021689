import {
  DepartmentArticle,
  DepartmentChildItem,
  DepartmentProduct,
  isDepartmentArticle,
  isSelfServeArticle,
  SelfServeArticle,
  SelfServeChildItem,
  SelfServeProduct,
} from "@/types/product/categorizedProduct"
import { isNotUndefined } from "@/types/typeGuards"

export enum SelfServeSortingMethod {
  AisleAndBin,
}
export enum DepartmentSortingMethod {
  HFB,
}

export function sortSelfServeProducts<
  T extends SelfServeProduct | SelfServeArticle,
>(
  selfServeProducts: T[],
  sortingMethod: SelfServeSortingMethod = SelfServeSortingMethod.AisleAndBin,
): T[] {
  switch (sortingMethod) {
    case SelfServeSortingMethod.AisleAndBin: {
      const sortFunction = sortWithUndefined((a: number, b: number) => a - b)
      return selfServeProducts.sort((a, b) => {
        const aLowestAisle = getLowestAisleOrBinNumber(a, "aisle")
        const bLowestAisle = getLowestAisleOrBinNumber(b, "aisle")
        const aisleDiff = sortFunction(aLowestAisle, bLowestAisle)

        if (aisleDiff) {
          return aisleDiff
        }

        const aLowestBin = getLowestAisleOrBinNumber(a, "bin")
        const bLowestBin = getLowestAisleOrBinNumber(b, "bin")
        return sortFunction(aLowestBin, bLowestBin)
      })
    }
    default:
      return selfServeProducts
  }
}

export function sortDepartmentProducts<
  T extends DepartmentProduct | DepartmentArticle | DepartmentChildItem,
>(
  departmentProducts: T[],
  sortingMethod: DepartmentSortingMethod = DepartmentSortingMethod.HFB,
): T[] {
  switch (sortingMethod) {
    case DepartmentSortingMethod.HFB: {
      const sortFunction = sortWithUndefined((a: string, b: string) =>
        a.localeCompare(b),
      )
      return departmentProducts.sort((a, b) => {
        const aLowestSLID = getExtremeSLID(a)
        const bLowestSLID = getExtremeSLID(b)
        return sortFunction(aLowestSLID, bLowestSLID)
      })
    }
    default:
      return departmentProducts
  }
}

function getLowestAisleOrBinNumber(
  product: SelfServeProduct,
  type: "aisle" | "bin",
) {
  return isSelfServeArticle(product)
    ? getAisleOrBinNumber(product, type)
    : product.info.childItems
        .map((item) => getAisleOrBinNumber(item, type))
        .filter(isNotUndefined)
        .reduce<number | undefined>(
          (acc, v) => (acc !== undefined ? Math.min(acc, v) : v),
          undefined,
        )
}

function getExtremeSLID(
  product: DepartmentProduct,
  method: "lowest" | "highest" = "lowest",
): string | null {
  return isDepartmentArticle(product)
    ? product.locations[0]?.departmentId ?? null
    : product.info.childItems
        .map((item) =>
          !("error" in item) ? getExtremeSLID(item, method) : "000000",
        )
        .filter((v): v is Exclude<typeof v, null> => v !== null)
        .reduce<string | null>(
          (first, value) =>
            first !== null && first.localeCompare(value) <= 0 ? first : value,
          null,
        )
}

function getAisleOrBinNumber(
  product: SelfServeArticle | SelfServeChildItem,
  type: "aisle" | "bin",
) {
  const aisleOrBin = product.locations[0]?.[type]
  return aisleOrBin ? parseInt(aisleOrBin) : undefined
}

/**
 * Returns a sorting function that automatically puts all undefined and null in the end.
 * @param sortFunction sorting function to be applied to elements after undefined is filtered out
 * @returns a function to be input to Array.prototype.sort
 */
export function sortWithUndefined<T>(
  sortFunction: (a: T, b: T) => number,
): (a: T | undefined | null, b: T | undefined | null) => number {
  return (a: T | undefined | null, b: T | undefined | null) => {
    if ((a === undefined || a === null) && (b === undefined || b === null))
      return 0
    else if (a === undefined || a === null) return 1
    else if (b === undefined || b === null) return -1
    else return sortFunction(a, b)
  }
}
