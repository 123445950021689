import { proxy, useSnapshot } from "valtio"

interface State {
  hasShownFeedbackModalState: boolean
}

export const feedbackModalState = proxy<State>({
  hasShownFeedbackModalState: false,
})

export const useFeedbackModalSnapshot = () => useSnapshot(feedbackModalState)
