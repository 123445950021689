import { i18n } from "@/utils/i18n"
import { verifyLocale } from "@/utils/verifyLocale"
import { ReactElement, useEffect } from "react"
import { useLocale } from "@/hooks/useLocale"

/**
 * Save locale to state
 */
export const LocalizationWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { market, language } = useLocale()

  useEffect(() => {
    const formattedMarket = market.toUpperCase()
    const formattedLanguage = language.toLowerCase()

    const isCypress = window.navigator.userAgent.includes("cypress")

    if (isCypress) {
      i18n.setDefaultNamespace("")
    } else if (verifyLocale(formattedMarket, formattedLanguage)) {
      i18n.changeLanguage(language + "-" + market.toUpperCase())
      document.documentElement.lang = language + "-" + market.toUpperCase()
    }
  }, [market, language])

  return children
}
