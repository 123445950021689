import { useTranslation } from "react-i18next"
import feedbackSad from "@ingka/ssr-icon/paths/feedback-sad"
import feedbackDissatisfied from "@ingka/ssr-icon/paths/feedback-dissatisfied"
import feedbackNeutral from "@ingka/ssr-icon/paths/feedback-neutral"
import feedbackSatisfied from "@ingka/ssr-icon/paths/feedback-satisfied"
import feedbackHappy from "@ingka/ssr-icon/paths/feedback-happy"
import Button from "@ingka/button"
import { useState } from "react"
import classNames from "classnames"

interface FullServeSurveyProps {
  onComplete: (rating: number) => void
}

export const FullServeSurvey: React.FC<FullServeSurveyProps> = (props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<number | undefined>(undefined)

  const handleRating = (rating: number) => {
    setSelected(rating)
    props.onComplete(rating)
  }

  const surveyOptions = [
    { ssrIcon: feedbackSad, ariaLabel: "feedback sad" },
    { ssrIcon: feedbackDissatisfied, ariaLabel: "feedback dissatisfied" },
    { ssrIcon: feedbackNeutral, ariaLabel: "feedback neutral" },
    { ssrIcon: feedbackSatisfied, ariaLabel: "feedback satisfied" },
    { ssrIcon: feedbackHappy, ariaLabel: "feedback happy" },
  ]

  return (
    <>
      <p className="text-base">{t("mobile.survey-order-and-collect")}</p>
      <p className="text-sm ">{t("mobile.feedback-help-us-improve")}</p>

      <div className="flex justify-center items-center my-10 gap-4">
        {surveyOptions.map(({ ssrIcon, ariaLabel }, index) => (
          <Button
            key={ariaLabel}
            className={classNames("bg-gray-secondary", {
              "border-neutral-900 border border-solid": selected === index + 1,
            })}
            onClick={() => handleRating(index + 1)}
            iconOnly={true}
            ssrIcon={ssrIcon}
            size="small"
            type="tertiary"
            aria-label={ariaLabel}
          />
        ))}
      </div>
    </>
  )
}
