import { getSettings } from "@/api/getSettings"
import { useAuth } from "@/hooks/useAuth"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "./useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"

export const useAdminSettings = () => {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode } = session
  const { oAuthToken } = useAuth()

  const { data, isPending } = useQueryWithErrorHandling(
    ["admin", market, businessUnitCode],
    () =>
      market && businessUnitCode && oAuthToken
        ? getSettings(market, businessUnitCode, oAuthToken)
        : Promise.reject(new Error("Missing fields")),
    {
      enabled: !!market && !!businessUnitCode && !!oAuthToken,
      gcTime: Infinity,
      staleTime: 86400000, // 24 hours
    },
  )

  return { settings: data, adminIsLoading: isPending }
}
