import React, { createContext, useCallback, useContext, useState } from "react"
import { Notification } from "@/components/Notification"

interface NotificationContextProps {
  show: (message: string, action?: () => void) => void
  hide: () => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined,
)
export const useNotification = () => useContext(NotificationContext)

interface ProviderProps {
  children: React.ReactNode
}
export const NotificationContextProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [isNotificationShowing, setIsNotificationShowing] = useState(false)
  const [notificationMessage, setNotificationMessageg] = useState("")
  const [undoAction, setUndoAction] = useState<() => void>()

  function show(message: string, action?: () => void) {
    setNotificationMessageg(message)
    setIsNotificationShowing(true)
    setUndoAction(action)
  }

  const hide = useCallback(() => {
    setIsNotificationShowing(false)
  }, [])

  return (
    <NotificationContext.Provider
      value={{
        show: show,
        hide: hide,
      }}
    >
      <Notification
        isShowing={isNotificationShowing}
        message={notificationMessage}
        hide={hide}
        undo={undoAction}
      />
      {children}
    </NotificationContext.Provider>
  )
}
