import { App } from "@/App"
import { useCanAddProducts } from "@/hooks/useCanAddProducts"
import { Add } from "@/views/Add"
import { Product } from "@/views/Product"
import { Welcome } from "@/views/Welcome"
import { Checkout } from "@/components/Checkout"
import { Route, Routes } from "react-router-dom"
import { ScannerInstructions } from "@/views/ScannerInstructions"

/**
 * Router for the apps main flow.
 */
export const AppRouter: React.FC = () => {
  const canAddProducts = useCanAddProducts()

  return (
    <Routes>
      {canAddProducts && <Route path="add/*" element={<Add />} />}
      <Route path="product/:productNo" element={<Product />} />
      {canAddProducts && (
        <Route path="scanner-instructions" element={<ScannerInstructions />} />
      )}
      <Route path="welcome" element={<Welcome />} />
      <Route path="checkout" element={<Checkout />} />
      <Route path="*" element={<App />} />
    </Routes>
  )
}
