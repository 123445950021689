import {
  useMutationWithErrorHandling,
  useQueryWithErrorHandling,
} from "@/hooks/useReactQuery"
import { fetchWithErrorHandling } from "@/utils/fetch"
import { useCallback } from "react"
import { useUpptackaToken } from "./useAuth"
import { ApiError } from "@/types/apiError"

export const useKortisInfoQuery = (kortisId: string | undefined) =>
  useQueryWithErrorHandling<{ redirectUrl: string }>(
    ["kortis info", kortisId],
    () =>
      kortisId
        ? fetchWithErrorHandling(
            "KORTIS",
            `${process.env.KORTIS_API_URL}/info/${kortisId}`,
          )
        : Promise.reject(new Error("Missing id")),
    { enabled: !!kortisId },
  ).data

interface CreateShortIdRequest {
  type: string
  market: string
  language: string
  listId: string
}

interface CreateShortIdResponse {
  shortId: string
  shortUrl: string
  redirectUrl: string
  created: number
  visits: number
  lastVisit: null
}

export const useKortisId = () => {
  const upptackaToken = useUpptackaToken()
  const mutation = useMutationWithErrorHandling<
    CreateShortIdResponse,
    ApiError,
    CreateShortIdRequest
  >((input: CreateShortIdRequest) =>
    fetchWithErrorHandling<CreateShortIdResponse>(
      "KORTIS",
      process.env.KORTIS_API_URL + `/v2/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + upptackaToken,
        },
        body: JSON.stringify({
          type: input.type,
          market: input.market,
          language: input.language,
          listId: input.listId,
          inclShortId: true,
        }),
      },
    ),
  )

  const mutate = mutation.mutateAsync
  const createKortisId = useCallback(
    (market: string, language: string, listId: string) =>
      mutate({ type: "instore", market, language, listId }).catch(
        () => undefined,
      ),
    [mutate],
  )

  return { createKortisId }
}
