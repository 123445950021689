import { useTranslation } from "react-i18next"
import pencil from "@ingka/ssr-icon/paths/pencil"
import cross from "@ingka/ssr-icon/paths/cross"
import { SlideUpModal } from "../modals/SlideUpModal"
import ListView, { ListViewItem } from "@ingka/list-view"
import { sendOrderEditedEvent } from "@/analytics/events/sendOrderEditedEvent"

type Props = {
  orderNo: string
  visible: boolean
  onClose: () => void
  onCancelOrder: () => void
}

export const EditOrderModal = ({
  orderNo,
  visible,
  onClose,
  onCancelOrder,
}: Props) => {
  const { t } = useTranslation()

  const editOrderClick = () => {
    sendOrderEditedEvent(orderNo, "edit_order")
    onCancelOrder()
  }

  const cancelOrderClick = () => {
    sendOrderEditedEvent(orderNo, "cancel_order")
    onCancelOrder()
  }

  return (
    <SlideUpModal showing={visible} onClose={onClose}>
      <div>
        <p className="text-base text-black font-bold">
          {t("mobile.update-order")}{" "}
          <span className="font-normal">#{orderNo}</span>
        </p>
        <p className="font-normal text-xs leading-[18px]">
          {t("mobile.edit-order-description")}
        </p>
      </div>

      <ListView id="updateOrder" className="mt-6" showDivider>
        <ListViewItem
          data-cy="edit-order"
          title={t("common.edit")}
          control="navigational"
          leadingIcon={pencil}
          onClick={editOrderClick}
          controlIcon={null}
        />
        <ListViewItem
          data-cy="cancel-order"
          title={t("mobile.cancel-order")}
          control="navigational"
          leadingIcon={cross}
          onClick={cancelOrderClick}
          controlIcon={null}
        />
      </ListView>
    </SlideUpModal>
  )
}
