import { EnterProductNumber } from "@/components/scanner/EnterProductNumber"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { Scanner } from "@/views/Scanner"
import { Route, Routes } from "react-router"

export const Add: React.FC = () => {
  const { navigate } = useLocalNavigation()

  return (
    <Routes>
      <Route path="article-number" element={<EnterProductNumber />} />
      <Route
        path="*"
        element={<Scanner onClose={() => navigate({ path: "list" })} />}
      />
    </Routes>
  )
}
