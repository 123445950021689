import { NoServiceError } from "@/utils/errors"
import { useMemo } from "react"
import { useParams } from "react-router"

export type Locale = { market: string; language: string }

export const useLocale = (): Locale => {
  const { market, language } = useParams<{ market: string; language: string }>()

  if (!market) {
    throw new NoServiceError("INVALID_MARKET")
  } else if (!language) {
    throw new NoServiceError("INVALID_LANGUAGE")
  }

  return useMemo(
    () => ({ language, market: market.toUpperCase() }),
    [market, language]
  )
}
