import { sendKepsEvent } from "../keps"

export function sendOrderEditedEvent(
  orderNo: string,
  type: "edit_order" | "cancel_order",
) {
  sendKepsEvent({
    event_location: "order_details",
    event_name: "more_options",
    event_detail: type,
    event_payload: JSON.stringify({ order_number: orderNo }),
  })
}
