import { isValidLanguage, isValidMarket } from "@/config/locales"

export function verifyLocale(market: string, language: string): boolean {
  if (!isValidMarket(market)) {
    throw new Error("INVALID_MARKET")
  }
  if (!isValidLanguage(market, language)) {
    throw new Error("INVALID_LANGUAGE")
  }
  return true
}
