import { getListigList } from "@/api/listig"
import { ListigList } from "@/types/listig"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { ExpiredListError } from "@/utils/errors"

/**
 * Returns list data from server, if listId is undefined, the query is disabled.
 */
export const useListigQuery = (
  listId?: string,
  oAuthToken?: string,
  retry = true,
): {
  list: ListigList | undefined
  error: ExpiredListError | Error | null
  isLoading: boolean
  refetch: () => void
} => {
  const listig = useQueryWithErrorHandling<
    ListigList,
    ExpiredListError | Error
  >(
    ["listig", listId?.toString()],
    () =>
      listId && oAuthToken
        ? getListigList(listId, oAuthToken)
        : Promise.reject(new Error("ListigFailed")),
    {
      refetchOnWindowFocus: true,
      enabled: !!listId && !!oAuthToken,
      retry: (failureCount, error) =>
        retry && !(error instanceof ExpiredListError) && failureCount < 3,
      staleTime: 30000,
      gcTime: Infinity,
    },
    "error",
    (error) => error instanceof ExpiredListError,
  )

  return {
    list: listig.data,
    error: listig.error,
    isLoading: listig.isPending,
    refetch: listig.refetch,
  }
}
