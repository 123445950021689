import { Prices } from "@/types/responses/find"

export const getPriceDiffString = (
  firstPriceToCompare?: Prices,
  secondPriceToCompare?: Prices,
) => {
  if (!firstPriceToCompare || !secondPriceToCompare) return

  const firstPrice =
    firstPriceToCompare.family?.inclTax ?? firstPriceToCompare.regular.inclTax
  const secondPrice =
    secondPriceToCompare.family?.inclTax ?? secondPriceToCompare.regular.inclTax
  const diff = firstPrice - secondPrice
  if (diff === 0) return

  const diffWithoutSign = Math.abs(diff)
  const sign = Math.sign(diff) > 0 ? "+  " : "- "
  const priceDiff = sign + diffWithoutSign

  return priceDiff
}
