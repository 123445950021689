import { VariantCategory } from "@/types/responses/find"
import { fetchWithErrorHandling } from "@/utils/fetch"

export const getVariants = (
  market: string,
  language: string,
  token: string,
  productNo: string,
  gprNo: string,
): Promise<VariantCategory[]> => {
  return fetchWithErrorHandling(
    "FIND_GET_VARIANTS",
    `${process.env.FIND_API_URL}/variants/${market}/${language}/${gprNo}/${productNo}`,
    { headers: { authorization: `Bearer ${token}` } },
  )
}
