import { ApiError } from "@/types/apiError"
import { UpptackaApiErrorName } from "@/types/errors"
import { NetworkError } from "./errors"

export const fetchWithErrorHandling = async <ResponseType>(
  errorName: UpptackaApiErrorName,
  url: string,
  // eslint is not recognizing this type
  // eslint-disable-next-line no-undef
  init?: RequestInit,
  handleException?: (response: Response) => void,
): Promise<ResponseType> =>
  // eslint-disable-next-line no-restricted-syntax
  fetch(url, init)
    .catch((error) => {
      throw new NetworkError(error)
    })
    .then((response) => {
      handleException?.(response.clone())

      if (!response.ok) {
        throw new ApiError(
          errorName,
          response.status,
          response.json().catch(() => "no json"),
        )
      } else {
        return response.json().catch(() => "no json")
      }
    })
