import SSRIcon from "@ingka/ssr-icon"
import checkIcon from "@ingka/ssr-icon/paths/checkmark"

export default function CheckboxCollect(props: {
  isChecked?: boolean
  onClick?: () => void
}) {
  return (
    <button
      aria-label={
        props.isChecked
          ? "Item collected button checked"
          : "Item collected button unchecked"
      }
      onClick={props.onClick}
      role="checkbox"
      aria-checked={props.isChecked}
      className={`transition w-10 h-10  rounded-full duration-200 flex justify-center items-center ${
        props.isChecked
          ? "border border-solid border-green-700  bg-green-700 focus-green-600 rounded-full text-white"
          : "border border-solid border-gray-300 bg-white text-gray-500"
      }`}
    >
      <SSRIcon
        className={props.isChecked ? " text-white" : "text-gray-400"}
        paths={checkIcon}
      />
    </button>
  )
}
