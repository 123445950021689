import { getStore } from "@/api/getStoreInformation"
import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"

export function useStoreInformation(buCode: string | null) {
  const { market, language } = useLocale()
  const { oAuthToken } = useAuth()

  const queryResult = useQueryWithErrorHandling(
    ["storeInfo", buCode],
    () =>
      buCode && market && language && oAuthToken
        ? getStore({
            storeNo: buCode,
            market,
            language,
            kongToken: oAuthToken,
          })
        : Promise.reject(new Error("Missing fields")),
    {
      enabled: !!buCode && !!market && !!language && !!oAuthToken,
      gcTime: Infinity,
      staleTime: Infinity,
      retry: 3,
    },
  )

  return {
    storeInfo: queryResult.data,
    isLoading: queryResult.isPending,
    error: queryResult.error,
  }
}
