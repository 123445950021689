import Button from "@ingka/button"
import { useTranslation } from "react-i18next"

type Props = {
  handleOnCloseClick: () => void
}

export const FeedbackFormCompleted: React.FC<Props> = ({
  handleOnCloseClick,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <p className="text-sm">{t("mobile.feedback-sent")}</p>
      <p className="text-lg text-gray-600 font-bold">
        {t("mobile.feedback-thank-you")}
      </p>
      <p className="text-sm pb-40">{t("mobile.feedback-create-better-ikea")}</p>

      <Button onClick={handleOnCloseClick} type="primary" size="small">
        {t("Close")}
      </Button>
    </div>
  )
}
