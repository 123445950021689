import { useLocale } from "@/hooks/useLocale"
import { TimeDiscountedPrice } from "@/types/responses/find"
import { getLocale } from "@/utils/locale"
import { useTranslation } from "react-i18next"

type Props = {
  price: TimeDiscountedPrice
  showToDate: boolean
}

export function PriceValidUntil({ price, showToDate }: Props) {
  const { market, language } = useLocale()
  const locale = getLocale(market, language)
  const { t } = useTranslation()

  const fromDate = new Date(price.validFrom)
  const toDate = new Date(price.validTo)
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  }

  return (
    <p key="PriceValidUntil" className="text-xs text-gray-700 mb-2">
      {showToDate
        ? t("common.price-valid-from-to", {
            from: fromDate.toLocaleDateString(locale, options),
            to: toDate.toLocaleDateString(locale, {
              ...options,
              year: "numeric",
            }),
          })
        : t("common.price-valid-from", {
            from: fromDate.toLocaleDateString(locale, {
              ...options,
              year: "numeric",
            }),
          })}
    </p>
  )
}
