import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import ProductCard from "../list/product-card/ProductCard"
import OrderChildItem from "../list/product-card/OrderChildItem"
import { TFunction } from "i18next"
import { isArticle } from "@/types/product/listProduct"
import { useTranslation } from "react-i18next"

interface OrderProductsProps {
  products: (FullServeProduct | OnlineProduct)[]
}

const OrderProducts: React.FC<OrderProductsProps> = ({ products }) => {
  const { t } = useTranslation()

  return (
    <>
      {products.map((product) => (
        <div key={product.info.no} className="my-0.5">
          <div id={"FULL_SERVE_" + product.info.no}>
            <ProductCard
              availability={product.stock.cashCarry}
              type={
                product.info.type === "ART"
                  ? "FULL_SERVE"
                  : `${product.info.type}_PARENT`
              }
              salesMethod="FULL_SERVE"
              product={{ ...product, isOrdered: true }}
              partiallyAvailableWarning={getPartiallyAvailableText(product, t)}
            />
            {!isArticle(product) &&
              product.info.childItems.map((childItem) => (
                <OrderChildItem
                  key={childItem.info.no}
                  product={childItem}
                  isSplit={product.info.isSplit}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  )
}

function getPartiallyAvailableText(
  product: FullServeProduct | OnlineProduct,
  t: TFunction<"translation", undefined>,
) {
  return "isSplit" in product.info && product.info.isSplit
    ? t("Interstitial-ProductListCard-collect-full-serve-of-split-spr")
    : undefined
}

export default OrderProducts
