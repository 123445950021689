import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import { PriceTotals } from "./calculatePriceTotals"
import { InstoreOrder } from "@/types/order"
import {
  CashCarryOrderResponse,
  CashCarryOrderCreatedPartialResponse,
} from "@/types/responses/buy"

export const parseCcOrder = (
  order: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse,
  items?: (FullServeProduct | OnlineProduct)[],
  totalPrice?: PriceTotals,
) => {
  const instoreOrder: InstoreOrder =
    "orderStatus" in order
      ? {
          orderNo: order.orderNo,
          orderNoSource: order.orderNoSource,
          pickingStatus: order.pickingService?.status,
          isPaid: order.isPaid,
          orderPaidDate: order.orderPaidDate,
          estimatedReadyTime: order.estimatedReadyTime,
          orderStatus: order.orderStatus,
          totalPrice: {
            exclTax: order.amounts.total.netAmount,
            inclTax: order.amounts.total.grossAmount,
            tax: order.amounts.total.vatAmount || 0,
          },
          currency: order.amounts.total.currencyCode,
          orderCreationDate: order.orderCreationDate,
          items,
          isPartial: false,
        }
      : {
          orderNo: order.orderNo,
          orderNoSource: order.orderNoSource,
          pickingStatus: undefined,
          isPaid: false,
          orderPaidDate: undefined,
          estimatedReadyTime: undefined,
          orderStatus: "CREATED",
          totalPrice: totalPrice && {
            exclTax: totalPrice.family?.exclTax || totalPrice.regular.exclTax,
            inclTax: totalPrice.family?.inclTax || totalPrice.regular.inclTax,
            tax: totalPrice.family?.tax || totalPrice.regular.tax || 0,
          },
          currency: totalPrice?.currency,
          isPartial: true,
        }
  return instoreOrder
}
