import React from "react"
import { motion } from "framer-motion"
import SSRIcon, { SSRIconProps } from "@ingka/ssr-icon"

interface FeedbackIconProps {
  icon: SSRIconProps["paths"]
  isSelected: boolean
  onClick?: () => void
}

export const FeedbackIcon: React.FC<FeedbackIconProps> = ({
  icon,
  isSelected,
  onClick,
}) => {
  return (
    <div className={`flex flex-col gap-4 items-center`} onClick={onClick}>
      <motion.div
        className={`rounded-full flex items-center justify-center`}
        style={{
          width: "64px",
          height: "64px",
          borderColor: "black",
        }}
        animate={{
          borderWidth: isSelected ? "2px" : "0",
        }}
      >
        <SSRIcon
          style={{ width: "48px", height: "48px", color: "black" }}
          paths={icon}
        />
      </motion.div>
    </div>
  )
}
