import { ProductType } from "@/types/product/products"
import { ProductsV2Response } from "@/types/responses/find"
import { fetchWithErrorHandling } from "@/utils/fetch"
import qs from "qs"

/** Must be higher than 0 */
const API_MAX_PRODUCT_NOS = 48

export async function getProductInformation(
  market: string,
  language: string,
  classUnit: { type: "ru" | "sto"; code: string },
  products: { id: string; type: ProductType | "COMBO" }[],
  kongToken: string,
): Promise<ProductsV2Response> {
  const queryProducts: string[] = products.map(
    (product) => `${product.id}:${product.type}`,
  )

  const queryPages: string[][] = []

  for (let i = 0; i < queryProducts.length; i += API_MAX_PRODUCT_NOS) {
    queryPages.push(queryProducts.slice(i, i + API_MAX_PRODUCT_NOS))
  }

  const querystrings = queryPages.map((queryPage) =>
    qs.stringify(
      {
        productNos: queryPage,
        childItemPrices: true,
      },
      { arrayFormat: "comma" },
    ),
  )

  const promises: Promise<ProductsV2Response>[] = querystrings.map(
    (querystring) =>
      fetchWithErrorHandling(
        "FIND",
        `${process.env.FIND_API_URL}/v2/products/${market}/${language}/${classUnit.type}/${classUnit.code}?${querystring}`,
        {
          headers: { Authorization: "Bearer " + kongToken },
        },
      ),
  )

  const results = await Promise.all(promises)

  return results.flat()
}
