import { sendKepsEvent } from "../keps"

export function sendPipButtonTapEvent(
  productId: string | undefined,
  eventDetail:
    | "product_details"
    | "measurements"
    | "security"
    | "good_to_know"
    | "material"
    | "package_info"
    | "variants"
    | "technical",
) {
  sendKepsEvent({
    event_location: "pip",
    event_name: "button_tap",
    event_detail: eventDetail,
    event_items: [{ item_no: productId }],
  })
}
