import { sendKepsEvent } from "../keps"

export function sendOrderButtonEditClickedEvent(orderNo: string) {
  sendKepsEvent({
    event_location: "list",
    event_name: "edit_order",
    event_detail: "order_now",
    event_payload: JSON.stringify({ order_number: orderNo }),
  })
}
