import { PackageMeasurements } from "@/types/responses/find"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import ProductIdentifier from "@ingka/product-identifier"
import { useLocale } from "@/hooks/useLocale"
import { capitalize } from "@/config/locales"
import { PackageDimensionList } from "./DimensionList"

type Props = {
  itemNo: string
  packagesDimensions: PackageMeasurements[]
  productName: string
  productDescription: string
}

export function ProductChildItem({
  itemNo,
  packagesDimensions,
  productName,
  productDescription,
}: Props) {
  const { market } = useLocale()

  return (
    <div className="product-child-item">
      <h4 className="text-sm">{productName}</h4>
      <p className="text-sm text-gray-900">{capitalize(productDescription)}</p>
      <ProductIdentifier value={dottedIdentifier(itemNo)} subtle={true} />
      <ul className="my-8 text-sm text-gray-900">
        <PackageDimensionList dimensions={packagesDimensions} market={market} />
      </ul>
    </div>
  )
}
