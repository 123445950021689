import { useState } from "react"

import classNames from "classnames"

interface VariantButtonProps {
  children?: JSX.Element
  disabled?: boolean
  onClick?: (id?: string) => void
  option?: string
  selected?: boolean
  subcategories?: {
    id: string
    title: string
    selected: boolean
  }[]
  title: string
  image?: string
  showSubcategories?: boolean
  className?: string
  priceDiff?: string
}

export function VariantButton({
  children,
  disabled,
  onClick,
  option = "",
  selected,
  subcategories,
  title,
  image,
  showSubcategories = false,
  className = "",
  priceDiff,
}: VariantButtonProps) {
  const [isOpen, setIsOpen] = useState(showSubcategories)

  return (
    <>
      <div
        className={`${className} mt-4 flex w-full items-center justify-between rounded border-2 px-6 py-6 text-left h-20 ${
          selected
            ? "border-black text-gray-900"
            : disabled
              ? "border-gray-200 bg-gray-100 text-gray-500"
              : "border-gray-200 text-gray-700"
        } `}
        onClick={subcategories ? () => setIsOpen(!isOpen) : () => onClick?.()}
      >
        <div className="flex flex-row items-center">
          {image && (
            <img
              className={classNames("mr-8 h-12 w-12", {
                "opacity-50": disabled,
              })}
              src={`${image}?f=xxxs`}
              alt={title}
            />
          )}
          <p className="capitalize-first max-w-xs overflow-x-hidden break-words pr-6 text-sm">
            {title}
          </p>
        </div>

        <div className="pl-6 text-right text-gray-600">
          {children ?? option}
        </div>

        {!selected && priceDiff && <div>{priceDiff}</div>}
      </div>

      {subcategories && isOpen && (
        <div className="flex justify-end w-11/12">
          {subcategories.map((sc) => (
            <VariantButton
              selected={sc.selected}
              key={sc.id}
              title={sc.title}
              onClick={() => onClick?.(sc.id)}
            />
          ))}
        </div>
      )}
    </>
  )
}
