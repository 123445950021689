import SectionHeader from "./SectionHeader"
import chevronDown from "@ingka/ssr-icon/paths/chevron-down"
import AnimateHeight from "react-animate-height"
import { ReactNode, useState } from "react"
import Button from "@ingka/button"

interface ListSectionProps {
  title: string
  subTitle?: ReactNode
  children?: ReactNode[]
}

const ListSection: React.FC<ListSectionProps> = (props) => {
  const [expanded, setExpanded] = useState(true)

  return (
    <div>
      <SectionHeader
        title={props.title}
        subTitle={props.subTitle}
        icon={<RotatingChevron down={!expanded} />}
        onIconClick={() => setExpanded((e) => !e)}
      />
      <AnimateHeight
        duration={300}
        height={expanded ? "auto" : 0}
        disableDisplayNone
      >
        <div className="px-2">{props.children}</div>
      </AnimateHeight>
      {!expanded && <hr className="border-gray-200 m-0" />}
    </div>
  )
}

export default ListSection

interface RotatingChevronProps {
  down: boolean
}
function RotatingChevron({ down }: RotatingChevronProps) {
  return (
    <div
      className="transition-all duration-200"
      style={
        down ? { transform: "rotate(0deg)" } : { transform: "rotate(180deg)" }
      }
    >
      <Button
        size="xsmall"
        type="tertiary"
        iconOnly
        tabIndex={0}
        ssrIcon={chevronDown}
      />
    </div>
  )
}
