import { ReactNode } from "react"
import { AnimatePresence, motion } from "framer-motion"

export const BelowMessage: React.FC<{ children: ReactNode; show: boolean }> = ({
  children,
  show,
}) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="bg-black w-full h-full absolute top-0"
          animate={{ opacity: 0.4 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        ></motion.div>
      )}
      {show && (
        <motion.div
          initial={{ translateY: "100%" }}
          animate={{ translateY: "0%" }}
          exit={{ translateY: "100%" }}
          className="absolute w-full bottom-0"
        >
          <div className="w-full bg-white h-auto rounded-t-lg">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
