import { deleteListigProduct } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { ListigList } from "@/types/listig"
import { useCallback } from "react"
import { sendItemDeletedEvent } from "@/analytics/events/sendItemDeletedEvent"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { ProductType } from "@/types/product/products"

/**
 * Provides a function to delete a product from the list,
 * will automatically refetch the list afterwards.
 * @returns a function to delete product from listig list
 */
export function useDeleteProduct(listId: string | null) {
  const { oAuthToken } = useAuth()
  const queryClient = useQueryClient()

  const mutation = useMutationWithErrorHandling(
    (input: { productId: string; productType: ProductType }) => {
      return listId && oAuthToken
        ? deleteListigProduct(
            listId,
            input.productId,
            input.productType,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing fields"))
    },
    {
      onSuccess: (
        _,
        input: { productId: string; productType: ProductType },
      ) => {
        sendItemDeletedEvent(input.productId)
        queryClient.invalidateQueries({
          queryKey: ["listig", listId?.toString()],
        })
      },
    },
  )

  const mutate = mutation.mutate
  const deleteProduct = useCallback(
    (
      product: { productId: string; productType: ProductType },
      options?: {
        onSuccess?: (data: ListigList) => void
        onError?: (e: unknown) => void
      },
    ) => mutate(product, options),
    [mutate],
  )

  return {
    /** delete product from list and automatically reload list */
    delete: deleteProduct,
    /** error when deleting product */
    error: mutation.error,
  }
}
