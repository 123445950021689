import { SeverityLevel } from "@sentry/react"
import {
  NO_SERVICE_ERRORS,
  UpptackaErrorId,
  upptackaErrorIds,
} from "../types/errors"

export class UError extends Error {
  constructor(name: string, message?: string, stack?: string) {
    super(isUpptackaErrorId(name) ? getErrorMessage(name) : message)
    this.name = name
    if (stack) {
      this.stack = stack
    }
  }
}

export class NoServiceError extends UError {
  constructor(name: UpptackaErrorId, stack?: string) {
    super(name, stack)
  }
}

export class NetworkError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args)
    super.name = "NetworkError"
  }
}

export class OrderOutdatedError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args)
    super.name = "Order outdated error"
  }
}

export class ExpiredListError extends Error {
  constructor() {
    super()
    this.name = "ExpiredListError"
    this.message = "The list has expired"
  }
}

export class OrderForbiddenError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args)
    super.name = "OrderForbiddenError"
  }
}

export class OrderConflictError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args)
    super.name = "FULL_SERVE_ORDER_CREATION_CONFLICT"
  }
}

export function getErrorCode(error: string) {
  return isUpptackaErrorId(error) ? NO_SERVICE_ERRORS[error].code : "UNKWN"
}

export function getErrorSeverity(error: UpptackaErrorId): SeverityLevel {
  return NO_SERVICE_ERRORS[error]?.logSeverity || "error"
}

export function getCustomerFriendlyErrorMessageKey(error: UpptackaErrorId) {
  return NO_SERVICE_ERRORS[error].friendlyMessageKey
}

export function getErrorMessage(error: UpptackaErrorId) {
  return NO_SERVICE_ERRORS[error].message
}

export function isUpptackaErrorId(error: string): error is UpptackaErrorId {
  const ids = upptackaErrorIds as readonly string[]
  return ids.includes(error)
}
