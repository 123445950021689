import { useAdminSettings } from "@/hooks/useAdminSettings"
import { captureException } from "@/utils/sentry"
import { useEffect } from "react"

const fallbackLength = 4

export function usePickUpNumber(orderNumber?: string) {
  const { settings, adminIsLoading } = useAdminSettings()

  const numberOfOrderDigits = settings?.fullServe.numberOfOrderDigits

  let pickupNumber: string | undefined
  let isFallback = false
  if (!orderNumber || adminIsLoading) pickupNumber = undefined
  else if (numberOfOrderDigits)
    pickupNumber = orderNumber.slice(-numberOfOrderDigits)
  else {
    isFallback = true
    pickupNumber = orderNumber.slice(-fallbackLength)
  }

  useEffect(() => {
    if (isFallback) {
      const e = new Error("Using fallback order number length")
      e.name = "Admin Error"
      captureException(e, "warning")
    }
  }, [isFallback])

  return { pickupNumber }
}
