import {
  CAPTURE_PROFILE_ENABLED,
  CAPTURE_PROFILE_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"

/**
 * @returns true if capture customer profile is enabled in kiosk/store/market or from params
 */
export function useCaptureProfile() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: CAPTURE_PROFILE_ENABLED,
    ENABLED_LOCATIONS: CAPTURE_PROFILE_LOCATIONS,
  })
}
