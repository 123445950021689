import { useEffect } from "react"
import Toast from "@ingka/toast"
import { useTranslation } from "react-i18next"

export const Notification: React.FC<{
  isShowing: boolean
  message: string
  hide: () => void
  undo: (() => void) | undefined
}> = ({ isShowing, message, undo, hide }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (isShowing) {
      const timeout = setTimeout(hide, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [hide, isShowing])

  return (
    <Toast
      data-cy="notification-toast"
      text={
        <>
          <strong>{message}</strong>
        </>
      }
      isOpen={isShowing}
      actionButtonText={undo && t("common.undo")}
      actionClick={undo}
      onCloseRequest={hide}
    />
  )
}
