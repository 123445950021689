import { BelowMessage } from "@/components/common/BelowMessage"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import warningTriangle from "@ingka/ssr-icon/paths/warning-triangle"
import { useTranslation } from "react-i18next"

export const ScanErrorMessage: React.FC<{
  close: () => void
  show: boolean
}> = ({ close, show }) => {
  const { t } = useTranslation()

  return (
    <BelowMessage show={show}>
      <div className="flex flex-col items-center p-10 space-y-4">
        <SSRIcon paths={warningTriangle} />
        <div className="pl-2 pr-2 flex flex-col space-y-4 text-center">
          <h6>{t("scanner.qr-error-title")}</h6>
          <p className="text-sm">{t("scanner.qr-error-message")}</p>
        </div>
        <Button
          text={t("scanner.return")}
          onClick={close}
          type="primary"
          fluid
        />
      </div>
    </BelowMessage>
  )
}
