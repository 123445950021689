import { ListigList } from "@/types/listig"
import { isToday } from "./isToday"
import { InstoreList } from "@/hooks/useListig"
import { ListProduct } from "@/types/product/listProduct"

/**
 * Get the vpc data from this list.
 */
export function getListVpcCodes(items?: ListProduct[]) {
  const vpcCodes = items
    ?.filter((item) => item.info.type === "VPC")
    .map((item) => item.info.no)

  return vpcCodes?.length ? vpcCodes : undefined
}

/**
 * The list has an order
 */
export const hasOrder = (list?: ListigList | InstoreList): boolean =>
  !!(list && list.orders && list.orders.length > 0)

/**
 * The list is active if the list exists and is not expired
 */
export const isActive = (list?: ListigList | InstoreList): list is ListigList =>
  !!(list && list.id && isToday(list.metadata.createdAt))
