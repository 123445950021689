import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"

export const FoodItemLocation: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="full-serve-location flex w-full flex-row justify-between">
      <ProductIdentifier
        label={props.title ?? t("common.article-number")}
        value={props.articleNo}
      />
    </div>
  )
}

type Props = {
  articleNo: string
  title?: string
}
