import type {
  DepartmentSalesLocation,
  FoodItemSalesLocation,
  FullServeSalesLocation,
  ProductError,
  ProductInfo,
  SalesLocation,
  SelfServeSalesLocation,
  SprProductInfo,
  UArticle,
  UChildProduct,
  UPlanner,
  USalesProduct,
  UScoProduct,
} from "@/types/responses/find"
import { PipProduct } from "./pip"
import { UniqueKey } from "../uniqueKey"

export type ProductType = "ART" | "SPR" | "SCO" | "VPC"

export const isValidProductType = (type?: string): type is ProductType =>
  type === "ART" || type === "SPR" || type === "SCO" || type === "VPC"

export const getValidProductType = (type?: string): ProductType =>
  isValidProductType(type) ? type : "ART"

export const isUPlanner = (product: PipProduct): product is UPlanner =>
  product.info.type === "VPC"

export const isUScoProduct = (product: PipProduct): product is UScoProduct =>
  product.info.type === "SCO"

export const isUArticle = (product: PipProduct): product is UArticle =>
  product.info.type === "ART"

export const isUSalesProduct = (
  product: PipProduct | UChildProduct,
): product is USalesProduct => isSprProductInformation(product.info)

const isSprProductInformation = (
  productInfo: ProductInfo,
): productInfo is SprProductInfo => productInfo.type === "SPR"

/**
 * Type guard to remove ProductError from any object type.
 * @param product
 * @returns product
 */
export const isNotProductError = <
  T extends object,
  S extends T | ProductError | undefined,
>(
  product: S,
): product is Exclude<S, ProductError> => {
  const property: keyof ProductError = "error"

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Waiting for fix in https://github.com/microsoft/TypeScript/issues/51501
  return !(product && property in product && product.error)
}

/** Type with quantity property */
export type Quantified<T> = T & { quantity: number }

export const getAisleAndBin = (locations: SalesLocation[] | undefined) => {
  if (!locations) return

  const location = locations.find(isSelfServeSalesLocation)

  return location && { aisle: location.aisle, bin: location.bin }
}

export const isFullServeSalesLocation = (
  location: SalesLocation | undefined,
): location is FullServeSalesLocation => location?.salesMethod === "FULL_SERVE"

export const isSelfServeSalesLocation = (
  location: SalesLocation | undefined,
): location is SelfServeSalesLocation => location?.salesMethod === "SELF_SERVE"

export const isFoodItemSalesLocation = (
  location: SalesLocation | undefined,
): location is FoodItemSalesLocation =>
  location?.salesMethod === "SWEDISH_FOOD_MARKET"

export const isDepartmentSalesLocation = (
  location: SalesLocation | undefined,
): location is DepartmentSalesLocation => {
  const property: UniqueKey<DepartmentSalesLocation, SalesLocation> =
    "departmentName"

  return !!location && property in location
}

export const isOnlineArticle = (product: PipProduct) => {
  return product.stock.homeDelivery.inRange && !product.stock.cashCarry.inRange
}
