import InlineMessage from "@ingka/inline-message"
import InfoCircle from "@ingka/ssr-icon/paths/information-circle"

interface InfoBoxProps {
  header: string
  text: string
}

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  return (
    <InlineMessage
      title={props.header}
      body={props.text}
      ssrIcon={InfoCircle}
      className="mb-8"
    />
  )
}

export default InfoBox
