import { Image } from "@/types/responses/find"
import classNames from "classnames"

export const GridPipImages: React.FC<Props> = ({ images }) => {
  return (
    <div className="grid-pip-images h-full w-full overflow-y-hidden">
      <div
        className={classNames(
          "grid h-full w-fit grid-flow-col gap-x-4 gap-y-4 overflow-y-hidden overflow-x-scroll px-4 pb-4",
          {
            "grid-rows-2": images.length > 2,
            "items-center": images.length <= 2,
          },
        )}
      >
        {images.map((image) => (
          <img
            className="h-[120px] max-h-full w-[120px] min-w-[120px] object-contain"
            key={image.id}
            src={image.href ?? ""}
          />
        ))}
      </div>
    </div>
  )
}

interface Props {
  images: Image[]
}
