import { NetworkError, OrderOutdatedError } from "@/utils/errors"
import { captureException } from "@/utils/sentry"
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  // eslint-disable-next-line no-restricted-syntax
  useMutation,
  // eslint-disable-next-line no-restricted-syntax
  useQuery,
} from "@tanstack/react-query"
import { SeverityLevel } from "@sentry/react"

/** useQuery wrapper that sends errors to sentry */
export const useQueryWithErrorHandling = <
  TQueryFnData = unknown,
  TError extends Error = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >,
  severity?: SeverityLevel,
  dontCapture?: (error: Error) => boolean,
): UseQueryResult<TData, TError> =>
  // eslint-disable-next-line no-restricted-syntax
  useQuery({
    queryKey,
    queryFn,
    retryDelay: (attempt) => attempt * 500,
    refetchOnWindowFocus: false,
    ...options,
    retry: (failureCount, error) => {
      const retry = options?.retry
      const shouldRetry =
        retry === true ||
        (typeof retry === "number" && failureCount < retry) ||
        (typeof retry === "function" && retry(failureCount, error))

      // we don't want to log errors for outdated orders since it's an expected error
      if (
        !shouldRetry &&
        !dontCapture?.(error) &&
        !(error instanceof OrderOutdatedError)
      ) {
        onError(error, severity)
      }

      return shouldRetry
    },
  })

/** useMutation wrapper that sends errors to sentry */
export const useMutationWithErrorHandling = <
  TData = unknown,
  TError extends Error = Error,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >,
  getSeverity?: (error: Error) => SeverityLevel,
): UseMutationResult<TData, TError, TVariables, TContext> =>
  // eslint-disable-next-line no-restricted-syntax
  useMutation({
    mutationFn,
    ...options,
    onError: (error) => onError(error, getSeverity?.(error)),
  })

const onError = (error: Error, severity: SeverityLevel = "error") =>
  captureException(error, error instanceof NetworkError ? "warning" : severity)
