import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import jsQR from "jsqr"

/**
 * Opens a camera and shows the video stream.
 * Calls onData when a QR code is found.
 */
export const QrScanner: React.FC<{
  /**
   * Called when data is read from qr code.
   * Called every time new data is found from a QR code.
   * Might be called extra times if no stable reference.
   */
  onData: (data: string) => void
  /** State for whether the scanner is paused */
  paused: boolean
}> = ({ onData, paused }) => {
  const [qrData, setQrData] = useState<string>()

  const videoRef = useRef<HTMLVideoElement>(null)

  const canvas = useMemo(() => document.createElement("canvas"), [])
  const ctx = useMemo(() => canvas.getContext("2d"), [canvas])

  let requestId = 0

  useEffect(() => {
    qrData && onData(qrData)
    setQrData(undefined)
  }, [qrData, onData])

  const tick = useCallback(() => {
    if (
      !paused &&
      videoRef.current &&
      videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA &&
      ctx
    ) {
      canvas.height = videoRef.current.videoHeight
      canvas.width = videoRef.current.videoWidth

      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const code = jsQR(imageData.data, imageData.width, imageData.height)
      if (code) {
        setQrData(code.data)
      }
    }

    cancelAnimationFrame(requestId)
    // eslint-disable-next-line
    requestId = requestAnimationFrame(tick)
  }, [ctx, canvas, paused])

  useEffect(() => {
    const mediaConstraints = {
      video: {
        facingMode: "environment",
      },
      audio: false,
    }

    let stream: MediaStream | undefined
    async function observe() {
      stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)

      const video = videoRef.current
      if (video) {
        video.srcObject = stream
        // eslint-disable-next-line
        requestId = requestAnimationFrame(tick)
      }
    }
    observe()

    return () => {
      cancelAnimationFrame(requestId)
      stream?.getTracks().forEach((track) => track.stop())
    }
  }, [tick])

  return (
    <video
      className="flex-grow h-full w-full"
      style={{ objectFit: "cover" }}
      ref={videoRef}
      autoPlay
      muted
      playsInline
    />
  )
}
