import { getOneTrustMarketId } from "@/config/onetrust"
import { useLocale } from "@/hooks/useLocale"
import React, { ReactElement, useEffect } from "react"

export const OneTrustWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { market } = useLocale()
  const isCypress = window.navigator.userAgent.includes("cypress")

  useEffect(() => {
    if (market && !isCypress) {
      addOneTrustScript(market)
    }
  }, [market, isCypress])

  return children
}

function addOneTrustScript(market: string) {
  const marketScriptId = getOneTrustMarketId(market)
  if (!!marketScriptId && !document.getElementById("onetrust-script")) {
    const head = document.getElementsByTagName("head")[0]
    if (!head) throw new Error("No head element")
    const scriptEl = document.createElement("script")
    scriptEl.id = "onetrust-script"
    scriptEl.type = "text/javascript"
    scriptEl.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    scriptEl.crossOrigin = "anonymous"
    scriptEl.setAttribute("data-document-language", "true")
    scriptEl.charset = "UTF-8"
    scriptEl.setAttribute("data-domain-script", marketScriptId)

    const scriptOptanon = document.createElement("script")
    scriptOptanon.type = "text/javascript"
    scriptOptanon.innerText = `function OptanonWrapper() {
              window.dispatchEvent(new Event('oneTrustCategoriesChanged'))
            }`

    head.appendChild(scriptEl)
    head.appendChild(scriptOptanon)
  }
}
