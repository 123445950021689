import { FullServeProduct } from "@/types/product/categorizedProduct"
import { ListProduct } from "@/types/product/listProduct"
import {
  AddItemsToOrderBodySchema,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"

export function formatCcOrderListigItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["listigOrderItems"] {
  return products.map((product) => ({
    itemId: product.info.no,
    type: product.info.type,
    quantity: product.quantity ?? 0,
  }))
}

export function formatCcOrderItems(
  products: FullServeProduct[],
): CreateCashCarryOrderBody["items"] {
  let formattedItems: CreateCashCarryOrderBody["items"] = []

  products.forEach((product) => {
    if (product.info.type === "ART") {
      formattedItems.push({
        itemId: product.info.no,
        type: product.info.type,
        quantity: product.quantity || 0,
        measurement: "PIECES",
      })
    } else {
      formattedItems = formattedItems.concat(
        formatCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    }
  })
  return formattedItems
}

export function formatEditCcOrderItems(
  products: ListProduct[],
): AddItemsToOrderBodySchema["items"] {
  let formattedItems: AddItemsToOrderBodySchema["items"] = []

  products.forEach((product) => {
    if (product.info.type === "ART") {
      formattedItems.push({
        itemId: product.info.no,
        type: product.info.type,
        quantity: product.quantity || 0,
        measurement: "PIECES",
      })
    } else {
      formattedItems = formattedItems.concat(
        formatEditCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    }
  })
  return formattedItems
}
