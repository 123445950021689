import { sendKepsEvent } from "../keps"

export function sendItemDeletedUndoEvent(productId: string) {
  sendKepsEvent({
    event_location: "list",
    event_name: "list_interactions",
    event_detail: "item_deleted_undo",
    event_items: [{ item_no: productId }],
  })
}
