import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal"
import { ProductVariants } from "../pip/ProductVariants"
import { VariantCategory } from "@/types/responses/find"
import { PipProduct } from "@/types/product/pip"

type ProductVariantsModalProps = {
  productInfo: PipProduct
  variantCategory: VariantCategory | undefined
  onClose: () => void
  showing: boolean
  handleVariantOnClick: (productNo: string) => void
}

export const ProductVariantsModal: React.FC<ProductVariantsModalProps> = ({
  productInfo,
  variantCategory,
  onClose,
  showing,
  handleVariantOnClick,
}) => {
  return productInfo ? (
    <Modal visible={showing} handleCloseBtn={onClose}>
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={<div></div>}
      >
        <ModalBody>
          <ProductVariants
            variantCategory={variantCategory}
            currentProduct={productInfo}
            handleVariantOnClick={handleVariantOnClick}
            variantTypeNameGlobal={variantCategory?.typeNameGlobal ?? ""}
          />
        </ModalBody>
      </Sheets>
    </Modal>
  ) : (
    <></>
  )
}
