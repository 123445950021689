import {
  AddonStore,
  Store,
  StoreInformationResponse,
} from "@/types/responses/explore"
import { NoServiceError } from "../utils/errors"
import { fetchWithErrorHandling } from "@/utils/fetch"

const getStores = (market: string, language: string, kongToken: string) =>
  fetchWithErrorHandling<StoreInformationResponse>(
    "EXPLORE",
    `${
      process.env.EXPLORE_API_URL
    }/stores/${market.toLowerCase()}/${language.toLowerCase()}`,
    {
      headers: { Authorization: "Bearer " + kongToken },
    }
  )

interface GetStoreProps {
  storeNo: string
  market: string
  language: string
  kongToken: string
}

export async function getStore({
  storeNo: storeNo,
  market,
  language,
  kongToken,
}: GetStoreProps): Promise<Store | AddonStore | undefined> {
  const stores = await getStores(market, language, kongToken)

  if (!stores || stores.length < 1) {
    throw new NoServiceError("MISSING_STORE_INFORMATION")
  }

  const store = stores
    .flatMap((store) => [store, ...(store.addons || [])])
    .find((store) => store.id === storeNo)

  if (store) {
    return store
  } else {
    throw new NoServiceError("MISSING_STORE_INFORMATION")
  }
}
