import { sendItemCheckedEvent } from "@/analytics/events/sendItemCheckedEvent"
import { useListig } from "@/hooks/useListig"
import { Prices } from "@/types/responses/find"
import { ItemSalesMethod1_0_0 } from "@upptacka/keps-schema/types/keps"
import { useEffect, useState } from "react"

export interface CheckList {
  [key: string]: boolean
}

export default function useChecklist() {
  const { session } = useListig()
  const { listId } = session

  const [checklist, setChecklist] = useState<CheckList>(
    JSON.parse(localStorage.getItem(listId + "-checklist") || "null") || {},
  )

  function toggleProductChecked(
    productId: string,
    quantity: number,
    price: Prices,
    salesMethod: ItemSalesMethod1_0_0,
  ) {
    if (!checklist[productId]) {
      sendItemCheckedEvent(productId, quantity, price, salesMethod)
    }

    setChecklist((checklist) => ({
      ...checklist,
      [productId]: !checklist[productId],
    }))
  }

  function isAllChildrenChecked(parentId: string, childrenCount: number) {
    const children = Object.entries(checklist).filter(([key, value]) => {
      return key.startsWith(parentId) && value
    })

    return children.length === childrenCount
  }

  function unCheckChildren(parentId: string) {
    setChecklist((checklist) => {
      const tempChecklist = { ...checklist }
      Object.keys(checklist).forEach((key) => {
        if (key.startsWith(parentId)) {
          tempChecklist[key] = false
        }
      })
      return tempChecklist
    })
  }

  useEffect(() => {
    localStorage.setItem(listId + "-checklist", JSON.stringify(checklist))
  }, [checklist, listId])

  return {
    checklist,
    toggleProductChecked,
    isAllChildrenChecked,
    unCheckChildren,
  }
}
