import { ProductPrice } from "@/components/common/ProductPrice"
import { capitalize } from "@/config/locales"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import { useTranslation } from "react-i18next"
import CheckboxCollect from "../CheckBoxCollect"
import ProductIdentifier from "@ingka/product-identifier"
import { SalesLocation } from "./SalesLocation"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import { ProductLocation } from "@/components/pip/ProductLocation"
import { ChecklistProps } from "./ProductCard"
import {
  DepartmentChildItem,
  SelfServeChildItem,
} from "@/types/product/categorizedProduct"
import classNames from "classnames"

interface ChildItemProps {
  product: DepartmentChildItem | SelfServeChildItem
  isSplit: boolean
  checklist: ChecklistProps
}

const ChildItem: React.FC<ChildItemProps> = ({
  product,
  isSplit,
  checklist,
}) => {
  const { t } = useTranslation()
  const { selectedQuantity } = useSelectQuantity(
    product.info.no,
    product.info.type,
    product.quantity,
    "list",
  )

  return (
    <div
      className="flex flex-col pb-4 px-6 bg-white last:rounded-b-md"
      data-cy="child-item"
    >
      <ProductPrice
        productName={`${selectedQuantity ? selectedQuantity + " x " : ""}${product.info.name}`}
        size="small"
        childItem
        productDescription={capitalize(product.info.mediumDescription)}
        className={classNames("font-bold mr-0", {
          "text-black": isSplit,
          "text-gray-400": !isSplit,
        })}
        prices={product.price}
        offers={product.offers}
        quantity={selectedQuantity}
        list
      />

      <div className="flex flex-row gap-4 mb-2 w-full h-full">
        <ProductIdentifier
          label={t("common.article-number")}
          data-cy="product-nr"
          className="mt-0 text-sm"
          value={dottedIdentifier(product.info.no)}
        />

        <SalesLocation location={product.locations[0]} />

        <div
          className="flex flex-col justify-end ml-auto"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          <CheckboxCollect
            isChecked={checklist.isChecked}
            onClick={checklist.onCheck}
          />
        </div>
      </div>

      <ProductLocation product={product} listView />
    </div>
  )
}
export default ChildItem
