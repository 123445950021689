import IngkaLoading, { LoadingBall, LoadingLinear } from "@ingka/loading"
import { useMemo } from "react"

const Loading: React.FC<LoadingProps> = (props) => {
  const currentStepIndex =
    "steps" in props ? props.steps.findIndex((e) => !e.completed) : 0

  const label =
    props.type === "infinite"
      ? props.title
      : props.steps[currentStepIndex]?.title

  const animation = useMemo(() => {
    switch (props.type) {
      case "infinite":
      case "step":
        return <LoadingBall />
      case "linear":
        return (
          <LoadingLinear
            loadingMax={props.steps.length + 1}
            loadingValue={currentStepIndex + 1}
          />
        )
    }
  }, [props, currentStepIndex])

  return (
    <IngkaLoading text={label} className={props.className}>
      {animation}
    </IngkaLoading>
  )
}
export default Loading

interface InfiniteBounceBallLoadingProps {
  type: "infinite"
  title: string
}

interface BounceBallStepLoadingProps {
  type: "step"
  steps: LoadingStep[]
}

interface LinearStepLoadingProps {
  type: "linear"
  steps: LoadingStep[]
}

interface LoadingStep {
  title: string
  completed: boolean
}

export type LoadingProps = { className?: string } & (
  | InfiniteBounceBallLoadingProps
  | BounceBallStepLoadingProps
  | LinearStepLoadingProps
)
