import { useTranslation } from "react-i18next"
import { useLocale } from "@/hooks/useLocale"
import { getLocale } from "@/utils/locale"

type Props = {
  price: number
  currency: string
  fee: { type: "WEEE" | "ECO"; amount: number }
}

export function ProductEnergyFee({ price, currency, fee }: Props) {
  const { t } = useTranslation()
  const { market, language } = useLocale()
  const locale = getLocale(market, language)

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  })

  return (
    <div
      data-component="product-fee-sheet"
      className="overflow-y-scroll pb-4 text-gray-900"
    >
      <h2 className="mb-8 ">{t(`ProductFeeModal-${fee.type}-fee`)}</h2>
      <p className="text-sm">{t(`ProductFeeModal-${fee.type}-p1`)}</p>
      <p className="text-sm">{t(`ProductFeeModal-${fee.type}-p2`)}</p>
      <hr className="border-gray-300" />
      <div className="mt-8 flex justify-between py-2">
        <p className="text-sm">
          {t(`ProductFeeModal-${fee.type}-without-fee`)}
        </p>
        <p className="text-sm">
          {currencyFormatter.format(price - fee.amount)}
        </p>
      </div>
      <div className="flex justify-between py-2">
        <p className="text-sm">{t(`ProductFeeModal-${fee.type}-fee`)}</p>
        <p className="text-sm">{currencyFormatter.format(fee.amount)}</p>
      </div>
      <hr className="border-gray-900" />
      <div className="flex justify-between py-2">
        <p className="text-sm">{t("ProductFeeModal-total")}</p>
        <p className="text-sm">{currencyFormatter.format(price)}</p>
      </div>
    </div>
  )
}
