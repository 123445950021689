import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from "@ingka/modal"
import { useTranslation } from "react-i18next"
import { QR } from "../QR"

export const HandoverModal: React.FC<HandoverModalProps> = ({
  onClose,
  showing,
  value,
  backValue,
}) => {
  const { t } = useTranslation()

  return (
    <Modal visible={showing} handleCloseBtn={() => onClose()}>
      <Sheets
        header={
          <ModalHeader
            title={t("Interstitial-share-list")}
            closeBtnClick={() => onClose()}
          />
        }
        footer={
          <ModalFooter>
            <div></div>
          </ModalFooter>
        }
      >
        <ModalBody>
          <p className="text-sm">{t("Interstitial-qr-description")}</p>
          <div className="mt-12 flex justify-center">
            {value && backValue && <QR value={value} backValue={backValue} />}
          </div>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

type HandoverModalProps = {
  onClose: () => void
  showing: boolean
  value: string
  backValue: string
}
