import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal"
import { FeedbackView } from "../list/feedback/FeedbackView"

type FeedbackModalProps = {
  onClose: () => void
  showing: boolean
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  onClose,
  showing,
}) => {
  return (
    <Modal
      visible={showing}
      handleCloseBtn={() => {
        onClose()
      }}
    >
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={<></>}
      >
        <ModalBody>
          <FeedbackView handleOnCloseClick={onClose} />
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
