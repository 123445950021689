import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import shoppingBag from "@ingka/ssr-icon/paths/shopping-bag"
import useVisit from "@/hooks/useVisit"

export const Welcome: React.FC = () => {
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { setVisitedPage } = useVisit()

  function closeOnboarding() {
    setVisitedPage("welcome")
    navigate({ path: "list" })
  }

  return (
    <div
      className="relative flex flex-col items-center p-10 bg-blue-brand overflow-hidden"
      style={{ height: "100dvh", width: "100dvw" }}
    >
      <div className="flex flex-col flex-1 items-center justify-center">
        <h3 className="text-3xl font-semibold text-white">
          Hej!
          <br />
          {t("mobile.onboarding-welcome-title")}
        </h3>
        <span className="h-3" />
        <h3 className="text-xl font-light text-white">
          {t("mobile.onboarding-welcome-body")}
        </h3>
      </div>

      <Button
        className="w-full bg-white z-20"
        type="tertiary"
        text={t("Registration-lets-get-started")}
        onClick={() => closeOnboarding()}
      />

      <SSRIcon
        paths={shoppingBag}
        className="absolute right-[-45vw] top-[-40vh] h-[125vh] w-[125vw] text-white opacity-5"
      />
      <SSRIcon
        paths={shoppingBag}
        className="absolute bottom-[-70vw] left-[-45vh] h-[125vh] w-[125vw] text-white opacity-5"
      />
    </div>
  )
}
