import {
  CO360_ENABLED,
  DISABLED_CO360_MARKETS,
  DISABLED_CO360_STORES,
  ENABLED_CO360_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useSession } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"

/**
 * @returns true if co360 has been enabled for market/store/kiosk
 */
export function useCo360() {
  const { market } = useLocale()
  const { session } = useSession()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  const co360Enabled = isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: CO360_ENABLED,
    ENABLED_LOCATIONS: ENABLED_CO360_LOCATIONS,
  })

  return (
    !DISABLED_CO360_MARKETS.includes(market) &&
    !DISABLED_CO360_STORES.includes(businessUnitCode ?? "") &&
    co360Enabled
  )
}
