import { useEffect } from "react"
import { useCreateList } from "@/hooks/useCreateList"
import { useLoadingSteps } from "@/hooks/useLoadingSteps"
import useVisit, { EntryPoint } from "@/hooks/useVisit"
import LoadingView from "@/views/LoadingView"

/**
 * Display a loading view while setting up a listig list.
 */
export const ListCreator: React.FC<{ entryPoint: EntryPoint }> = (props) => {
  const { setEntryPoint } = useVisit()
  useCreateList(props.entryPoint)
  const { steps } = useLoadingSteps()

  useEffect(() => {
    setEntryPoint(props.entryPoint)
    //eslint-disable-next-line
  }, [])

  return <LoadingView type="linear" steps={steps} />
}
