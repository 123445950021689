import { ListigList } from "@/types/listig"
import { ListChildItem, ListProduct } from "@/types/product/listProduct"
import { isUArticle } from "@/types/product/products"
import { ProductError, ProductsV2Response } from "@/types/responses/find"
import { CcOrderArticle } from "@/types/responses/buy"

export function createProductList(
  productResponse: ProductsV2Response,
  listItems: ListigList["items"] | CcOrderArticle[],
  isOrdered = false,
): ListProduct[] {
  const productList: ListProduct[] = productResponse
    .filter((e): e is Exclude<typeof e, ProductError> => !("error" in e))
    .map((product): ListProduct => {
      const listItemQuantity =
        listItems.find(
          (e) => ("itemId" in e ? e.itemId : e.id) === product.info.no,
        )?.quantity ?? 1
      if (isUArticle(product)) {
        return { ...product, quantity: listItemQuantity, isOrdered }
      } else {
        return {
          ...product,
          quantity: listItemQuantity,
          info: {
            ...product.info,
            childItems:
              product.info.childItems
                ?.filter(
                  (e): e is ListChildItem => !("error" in e) && !!e.price,
                )
                .map((childItem) => ({
                  ...childItem,
                  quantity: listItemQuantity * childItem.quantity,
                })) ?? [],
          },
          isOrdered,
        } as ListProduct
      }
    })

  return productList
}
