/* eslint filename-rules/match: 0 */
import { useEffect, useState } from "react"
import QuestionMark from "@ingka/ssr-icon/paths/question-mark"
import Flippy, { FrontSide, BackSide } from "react-flippy"
import { QRCodeSVG } from "qrcode.react"
import SSRIcon from "@ingka/ssr-icon"

interface Props {
  value: string
  backValue?: string
}

const clicksRequired = 3
const clickSpeed = 2000

export function QR({ value, backValue }: Props) {
  const [count, setCount] = useState(0)
  const [showQRText, setShowQRText] = useState(false)

  useEffect(() => {
    if (count > 0 && (showQRText || count % clicksRequired === 0)) {
      setShowQRText(!showQRText)
      setCount(0)
    }
    const timer = setTimeout(() => setCount(0), clickSpeed)
    return () => {
      clearInterval(timer)
    }
  }, [count, showQRText])

  function increment() {
    setCount((c) => c + 1)
  }

  return (
    <Flippy
      flipDirection="horizontal"
      style={{ width: "200px", height: "200px" }}
      flipOnClick={false}
      isFlipped={backValue && showQRText}
      onClick={increment}
      className="mx-auto"
      data-cy="qr"
    >
      {value ? (
        <>
          <FrontSide className="p-0 shadow-none">
            <QRCodeSVG
              size={180}
              className="mx-auto "
              value={value}
              bgColor="#FFF"
            />
          </FrontSide>
          <BackSide className="flex items-center justify-center bg-black p-0 shadow-none">
            <h3
              className="text-white"
              data-cy-value={value}
              data-cy="flippy-back"
            >
              {backValue}
            </h3>
          </BackSide>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center p-0 shadow-none ">
          <SSRIcon className="h-106 w-106 text-black" paths={QuestionMark} />
        </div>
      )}
    </Flippy>
  )
}
