import { PLANNER_REGEX, PRODUCT_REGEX } from "@/config/constants"
import {
  ProductType,
  getValidProductType,
  isValidProductType,
} from "@/types/product/products"

interface ProductHandoverDetails {
  id: string
  type: ProductType
  storeNo: string
  quantity?: string
  source: string
  sessionId?: string
}

export function parseHandoverItems(items: string) {
  return (
    items
      .split(",")
      .map((s) => s.split(":"))
      // remove invalid items
      .reduce<{ id: string; quantity: number; type: ProductType }[]>(
        (products, [id, q, type]) => {
          const quantity = q ? parseInt(q) : undefined
          if (
            quantity &&
            // validate quantity
            !isNaN(quantity) &&
            // validate type
            isValidProductType(type) &&
            // validate productNo
            id !== undefined &&
            (PRODUCT_REGEX.exec(id) || PLANNER_REGEX.exec(id))
          )
            return [...products, { id, quantity, type }]
          else return products
        },
        [],
      )
  )
}

/**
 * Parse parameters from single product handover
 */
export function parseSingleProductHandover(
  queryParams: URLSearchParams,
): ProductHandoverDetails | null {
  const productNo = queryParams.get("no")
  const productType = queryParams.get("type")?.toUpperCase()
  const quantity = queryParams.get("q")
  const storeNo = queryParams.get("s")
  const source = queryParams.get("id")
  const sessionId = queryParams.get("sessionId")

  return productNo && storeNo && source
    ? {
        id: productNo,
        type: getValidProductType(productType ?? undefined),
        storeNo,
        quantity: quantity ?? undefined,
        source: source,
        sessionId: sessionId ?? undefined,
      }
    : null
}
