import { useQueryWithErrorHandling } from "./useReactQuery"
import { getOAuthToken, getUpptackaJWT } from "@/api/getOAuthToken"

/**
 * Sets up OAuth token which will be used for all our API calls
 */
export function useAuth() {
  const { data: oAuthToken, error: oAuthError } = useQueryWithErrorHandling(
    ["token", "oauth"],
    () => getOAuthToken(),
    {
      refetchInterval: (query) => {
        const token = query.state.data
        return token ? token.expiry - Date.now() - 10000 : 1000
      },
      staleTime: 36000000, // 10 hours
      gcTime: 36000000, // 10 hours
      retry: 3,
    },
  )

  return {
    oAuthToken:
      oAuthToken && oAuthToken.expiry > Date.now()
        ? oAuthToken.token
        : undefined,
    oAuthError,
  }
}

export const useUpptackaToken = () =>
  useQueryWithErrorHandling(["token", "upptacka"], () => getUpptackaJWT()).data
