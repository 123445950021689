import Button from "@ingka/button"
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import { sendCoworkerHelpEvent } from "@/analytics/events/sendCoworkerHelpEvent"

export const SurveyPrompt: React.FC<{
  show: boolean
  onContinue: () => void
}> = ({ show, onContinue }) => {
  const { t } = useTranslation()
  return (
    <Modal
      handleCloseBtn={() => {
        sendCoworkerHelpEvent("no reply")
        onContinue()
      }}
      visible={show}
    >
      <Prompt
        header={<ModalHeader />}
        titleId="survey-prompt"
        title={t("mobile.survey-help-from-coworker")}
        footer={
          <ModalFooter className="flex gap-4">
            <Button
              className="py-4"
              text={t("common.yes")}
              onClick={() => {
                sendCoworkerHelpEvent("yes")
                onContinue()
              }}
            />
            <Button
              className="py-4"
              text={t("common.no")}
              onClick={() => {
                sendCoworkerHelpEvent("no")
                onContinue()
              }}
            />
          </ModalFooter>
        }
      >
        <p className="text-sm">{t("mobile.feedback-help-us-improve")}</p>
      </Prompt>
    </Modal>
  )
}
